import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Theme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import { FunctionComponent, memo, useEffect, useState } from 'react';
import { ALL_ROLES, Role } from '../../../../contexts/AuthProvider/types';
import { useConfig } from '../../../../eliot-components/src/ConfigProvider/hooks';
import { OrgRole } from './types';
import RolesSelect from '../../../../components/Roles/RolesSelect';
import MedSelect from '../../../../eliot-components/src/Common/MedSelect';
import { useGetOrgsQuery } from '../../../../store/api-slice';
import { useSnackbar } from 'notistack';
import { parseErrorMessage } from '../../../../eliot-components/src/utils/helpers';

const useStyles = makeStyles((theme: Theme) => ({
  dialog: {
    width: '600px',
  },
  dialogContent: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  actions: {
    padding: theme.spacing(2),
  },
}));

interface Props {
  onAdded(role: OrgRole[]): void;
  selectedOrg?: string;
  selectedRoles?: OrgRole[];
}

const AddRoleButton: FunctionComponent<Props> = memo(
  ({ onAdded, selectedOrg, selectedRoles }) => {
    const classes = useStyles();

    const { apiBaseURL } = useConfig();
    const { data: orgs = [], error } = useGetOrgsQuery({ apiBaseURL });
    const [isOpen, setIsOpen] = useState(false);
    const [orgID, setOrgID] = useState<string>(selectedOrg || '');
    const [orgRoles, setOrgRoles] = useState<OrgRole[]>([]);
    const [roles, setRoles] = useState<Role[]>([]);
    const { enqueueSnackbar } = useSnackbar();

    if (error) {
      enqueueSnackbar(parseErrorMessage(error), { variant: 'error' });
    }

    useEffect(() => {
      if (isOpen && selectedRoles) {
        setOrgRoles(selectedRoles);
      }
    }, [isOpen, selectedRoles]);

    useEffect(() => {
      if (orgID) {
        const newSelectedRoles = orgRoles
          ? orgRoles.filter((r) => r.orgID === orgID)
          : [];
        setRoles(newSelectedRoles.map((r) => r.role));
      }
    }, [orgID, orgRoles]);

    const handleClose = () => {
      setOrgID(selectedOrg || '');
      if (!selectedOrg) {
        setRoles([]);
      }
      setIsOpen(false);
    };

    const handleSubmit = () => {
      const org = orgs.find((o) => o.id === orgID);
      if (orgID && org && roles) {
        onAdded(orgRoles);
      }

      handleClose();
    };

    const updateRoles = (roles: Role[]) => {
      const org = orgs.find((o) => o.id === orgID);
      if (orgID && org && roles) {
        setRoles(roles);
        setOrgRoles([
          ...orgRoles.filter((o) => o.orgID !== orgID),
          ...roles.map(
            (r) => ({ orgID: orgID, orgName: org.name, role: r } as OrgRole)
          ),
        ]);
      }
    };

    return (
      <>
        <Button
          variant="outlined"
          color="primary"
          onClick={() => {
            setIsOpen(true);
          }}
        >
          Roles
        </Button>
        {isOpen && (
          <Dialog
            PaperProps={{ className: classes.dialog }}
            aria-labelledby="dialog-title"
            open={true}
            onClose={() => {
              handleClose();
            }}
          >
            <DialogTitle id="dialog-title">Roles</DialogTitle>
            <DialogContent className={classes.dialogContent}>
              <MedSelect
                name="org"
                placeholder="Org"
                value={orgID}
                disabled={!!selectedOrg}
                onChange={(e) => {
                  setOrgID(e.target.value as string);
                }}
                optionTextGetter={(o) => o.name}
                options={orgs}
                valueGetter={(o) => o.id}
              />
              <br />
              <br />
              <RolesSelect
                roles={roles}
                setRoles={updateRoles}
                allAvailableRoles={ALL_ROLES}
              />

              <br />
            </DialogContent>
            <DialogActions classes={{ root: classes.actions }}>
              <Button
                onClick={() => {
                  handleClose();
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  handleSubmit();
                }}
                data-testid="submit-roles"
              >
                Submit
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </>
    );
  }
);

export default AddRoleButton;
