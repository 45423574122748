import { useState } from 'react';
import { User, UserOrg } from '../Users/types';
import Auth from '@aws-amplify/auth';
import {
  useAddUserGlobalRolesMutation,
  useAddUserRolesMutation,
  useDeleteUserGlobalRolesMutation,
  useDeleteUserRolesMutation,
} from '../../store/api-slice';

export const useUser = (apiBaseURL: string, id: string, user?: User) => {
  const [error, setError] = useState<Error | null>(null);
  const [removeUserRoles] = useDeleteUserRolesMutation();
  const [removeUserGlobalRoles] = useDeleteUserGlobalRolesMutation();
  const [addUserRoles] = useAddUserRolesMutation();
  const [addUserGlobalRole] = useAddUserGlobalRolesMutation();

  const mergeUserGlobalRoles = async (roles: string[] = []) => {
    const currentUserGlobalRoles: string[] =
      user && user.globalRoles ? user.globalRoles : [];

    const rolesToRemove = currentUserGlobalRoles.filter(
      (gr) => !roles.includes(gr)
    );

    const rolesToAdd = roles.filter(
      (gr) => !currentUserGlobalRoles.includes(gr)
    );
    setError(null);
    try {
      const userID = id;
      let add: Promise<any> = Promise.resolve();
      let remove: Promise<any> = Promise.resolve();
      if (rolesToRemove && rolesToRemove.length > 0) {
        remove = removeUserGlobalRoles({
          apiBaseURL,
          userID,
          roles: rolesToRemove,
        });
      }
      if (rolesToAdd && rolesToAdd.length > 0) {
        add = addUserGlobalRole({
          apiBaseURL,
          userID,
          roles: rolesToAdd,
        });
      }

      const resps: any[] = await Promise.all([add, remove]);
      resps.forEach((resp) => {
        if (resp && resp.error) throw resp.error;
      });
    } catch (err: any) {
      setError(err);
    }
  };

  const mergeUserRoles = async (orgID: string, roles: string[] = []) => {
    const currentUserOrg =
      user && user.orgs && user.orgs.find((o) => o.id === orgID);
    const currentOrgRoles: string[] =
      currentUserOrg && currentUserOrg.roles ? currentUserOrg.roles : [];

    const rolesToRemove = currentOrgRoles.filter((gr) => !roles.includes(gr));
    const rolesToAdd = roles.filter((gr) => !currentOrgRoles.includes(gr));

    setError(null);
    try {
      const userID = id;
      let add: Promise<any> = Promise.resolve();
      let remove: Promise<any> = Promise.resolve();

      if (rolesToRemove && rolesToRemove.length > 0) {
        remove = removeUserRoles({
          apiBaseURL,
          userID,
          orgID,
          roles: rolesToRemove,
        });
      }
      if (rolesToAdd && rolesToAdd.length > 0) {
        add = addUserRoles({
          apiBaseURL,
          userID,
          orgID,
          roles: rolesToAdd,
        });
      }

      const resps: any[] = await Promise.all([add, remove]);
      resps.forEach((resp) => {
        if (resp && resp.error) throw resp.error;
      });
    } catch (err: any) {
      setError(err);
    } finally {
      setError(null); // ugh, this is a hack to get the error to clear
    }
  };

  const removeAllRoles = async (userID: string, userOrgs: UserOrg[]) => {
    if (user) {
      setError(null);
      for (let i = 0; i < userOrgs.length; i++) {
        const item = userOrgs[i];
        if (!item.roles) {
          continue;
        }
        try {
          const roles = item.roles;
          const resp: any = await removeUserRoles({
            apiBaseURL,
            userID,
            orgID: item.id,
            roles,
          });
          if (resp.error) {
            throw resp.error;
          }
        } catch (err) {}
      }

      try {
        if (user.globalRoles) {
          const resp: any = await removeUserGlobalRoles({
            apiBaseURL,
            userID,
            roles: user.globalRoles,
          });
          if (resp.error) {
            throw resp.error;
          }
        }
      } catch (err: any) {
        setError(err);
      } finally {
        setError(null); // ugh, this is a hack to get the error to clear
      }
    }
  };

  return {
    error,
    mergeUserRoles,
    mergeUserGlobalRoles,
    removeAllRoles,
  };
};

export const useChangePassword = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const changePassword = async (oldPassword: string, newPassword: string) => {
    setIsLoading(true);
    setError(null);

    try {
      const me = await Auth.currentAuthenticatedUser();
      const data = await Auth.changePassword(me, oldPassword, newPassword);
      setIsLoading(false);
      return data === 'SUCCESS';
    } catch (err: any) {
      setIsLoading(false);
      if (err.code === 'NotAuthorizedException') {
        setError(new Error('Incorrect password'));
        return;
      }
      setError(err);
    }
  };

  return { isLoading, error, setError, changePassword };
};
