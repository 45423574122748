import { User, UsersSortBy } from './types';
import {
  Direction,
  SortState,
  sort,
  SortOptionSelectItem,
  SortOption,
} from '../../utils/sort';

export const usersSortingReducer = (
  state: SortState<User>,
  action: SortOption<UsersSortBy>
): SortState<User> => {
  const getDirectionByPrevious = (direction?: Direction) => {
    return direction === Direction.ASC ? Direction.DESC : Direction.ASC;
  };

  switch (action.sortBy) {
    case UsersSortBy.NAME:
      return {
        name: action.direction || getDirectionByPrevious(state.name),
      };
    case UsersSortBy.ORGS:
      return {
        orgs: action.direction || getDirectionByPrevious(state.orgs),
      };
    case UsersSortBy.EMAIL:
      return {
        email: action.direction || getDirectionByPrevious(state.email),
      };
    case UsersSortBy.STATUS:
      return {
        isConfirmed:
          action.direction || getDirectionByPrevious(state.isConfirmed),
      };
    case UsersSortBy.GLOBAL_ROLES:
      return {
        globalRoles:
          action.direction || getDirectionByPrevious(state.globalRoles),
      };
    default:
      return state;
  }
};

export const sortUsers = (users: User[], sortState: SortState<User>) =>
  sort(users, sortState, {
    name: getUserName,
    orgs: (u) => u.orgs.length,
    globalRoles: (u) => (u.globalRoles ? u.globalRoles.length : 0),
    isConfirmed: (u) => (!!u.emailVerified ? 1 : 0),
  });

export const getUserName = (u: User) =>
  u.givenName || u.familyName ? u.givenName + ' ' + u.familyName : u.name;

//SORT STATE TO MOBILESORTOPTIONS MAPPER

export const mobileSortOptions: SortOptionSelectItem<UsersSortBy>[] = [
  {
    value: { sortBy: UsersSortBy.NAME, direction: Direction.ASC },
    label: 'Name (ascending)',
    id: 'name-asc',
  },
  {
    value: { sortBy: UsersSortBy.NAME, direction: Direction.DESC },
    label: 'Name (descending)',
    id: 'name-desc',
  },
  {
    value: { sortBy: UsersSortBy.EMAIL, direction: Direction.ASC },
    label: 'Email (ascending)',
    id: 'email-asc',
  },
  {
    value: { sortBy: UsersSortBy.EMAIL, direction: Direction.DESC },
    label: 'Email (descending)',
    id: 'email-desc',
  },
  {
    value: { sortBy: UsersSortBy.ORGS, direction: Direction.ASC },
    label: 'Orgs (ascending)',
    id: 'orgs-asc',
  },
  {
    value: { sortBy: UsersSortBy.ORGS, direction: Direction.DESC },
    label: 'Orgs (descending)',
    id: 'orgs-desc',
  },
  {
    value: { sortBy: UsersSortBy.GLOBAL_ROLES, direction: Direction.ASC },
    label: 'Global roles (ascending)',
    id: 'global-roles-asc',
  },
  {
    value: { sortBy: UsersSortBy.GLOBAL_ROLES, direction: Direction.DESC },
    label: 'Global roles (descending)',
    id: 'global-roles-desc',
  },
  {
    value: { sortBy: UsersSortBy.STATUS, direction: Direction.ASC },
    label: 'Status (ascending)',
    id: 'status-asc',
  },
  {
    value: { sortBy: UsersSortBy.STATUS, direction: Direction.DESC },
    label: 'Status (descending)',
    id: 'status-desc',
  },
];
