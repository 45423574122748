import Button from '@material-ui/core/Button';
import React from 'react';
import { AdapterLink } from '../../../Orgs/Orgs';
import { User } from '../../types';
import VerificationStatus from '../VerificationStatus/VerificationStatus';

interface PropsDetail {
  user: User;
}

export const DetailCell: React.FC<PropsDetail> = ({ user }) => {
  return (
    <Button
      color="primary"
      component={AdapterLink}
      to={`/users/${encodeURIComponent(user.id)}`}
    >
      Details
    </Button>
  );
};

interface PropsVerification extends PropsDetail {
  readonly setSelectedUserID: Function;
  readonly setIsConfirmationDialogOpen: Function;
}

export const VerificationStatusCell: React.FC<PropsVerification> = ({
  user,
  setSelectedUserID,
  setIsConfirmationDialogOpen,
}) => {
  return (
    <VerificationStatus
      setSelectedUserID={setSelectedUserID}
      setIsConfirmationDialogOpen={setIsConfirmationDialogOpen}
      user={user}
    />
  );
};
