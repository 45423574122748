import Auth, { AuthClass } from '@aws-amplify/auth';
import Button from '@material-ui/core/Button';
import { Theme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';
import React, {
  FormEvent,
  FunctionComponent,
  memo,
  useState,
  useEffect,
} from 'react';
import MuiLink from '@material-ui/core/Link';
import { useHistory } from 'react-router-dom';
import SetNewPassword from './SetNewPassword/SetNewPassword';
import MedTextField from '../Common/MedTextField';
import {useSnackbar} from "notistack";
import {parseErrorMessage} from "../utils/helpers";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    marginTop: theme.spacing(6),
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  formBox: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(6),
    [theme.breakpoints.up('sm')]: {
      flexGrow: 0,
      marginTop: theme.spacing(16),
    },
  },
  form: {
    maxWidth: '30rem',
    margin: 'auto',
  },
  forgotPassword: {
    display: 'block',
    textAlign: 'right',
    marginBottom: theme.spacing(1),
    marginTop: '0',
  },
  login: {
    '& #root': {
      padding: theme.spacing(2),
      display: 'flex',
      flexDirection: 'column',
    },
  },
  app: {
    whiteSpace: 'nowrap',
  },
}));

interface Props {
  readonly auth?: AuthClass;
  readonly isEmbedded?: boolean;
  readonly appName?: string;
}

const Login: FunctionComponent<Props> = memo(
  ({ auth = Auth, isEmbedded = false, appName = '' }) => {
    const history = useHistory();
    const classes = useStyles();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isNewPasswordRequired, setIsNewPasswordRequired] = useState(false);
    const [user, setUser] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
      const query = new URLSearchParams(history.location.search);
      if (query.get('error') === 'noPerm') {
        enqueueSnackbar('You don\'t have permission to login', {variant: "error"})
      }
    }, [history.location.search, enqueueSnackbar]);

    useEffect(() => {
      document.documentElement.classList.add(classes.login);

      auth.currentSession().then(
        () => {
          history.push('/');
        },
        () => {
          // error mean that you are logged out
        },
      );

      return () => {
        document.documentElement.classList.remove(classes.login);
      };
    }, [auth, classes.login, history]);

    const handleCodeSubmit = async (event: React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLSpanElement>) => {
      try {
        setIsLoading(true);
        await auth.forgotPassword(email);
        window.setTimeout(() => {
          history.push(`/forgot-password?email=${email}`);
        });
      } catch (err) {
        if(err.code === 'NotAuthorizedException') {
          enqueueSnackbar("You don't have permission to reset password", {variant: "error"})
        }else {
          enqueueSnackbar(parseErrorMessage(err), {variant: "error"})
        }
      }
      setIsLoading(false);
    };

    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      try {
        setIsLoading(true);
        const user = await auth.signIn(email, password);
        setPassword('');
        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          setIsLoading(false);
          setIsNewPasswordRequired(true);
          setUser(user);
          return;
        }
        window.setTimeout(() => {
          history.push('/');
        });
        return;
      } catch (err: any) {
        console.log(err);
        if(err.code === 'NotAuthorizedException') {
          enqueueSnackbar("You don't have permission to login", {variant: "error"})
        }else {
          enqueueSnackbar(parseErrorMessage(err), {variant: "error"})
        }
      }
      setIsLoading(false);
    };

    const isEmail = (email: string) => {
      return !!(/.+@.+\.[A-Za-z]+$/.test(email));
    };

    return (
      <div className={classes.container}>
        {!isEmbedded ? (
          <Typography variant='h4' paragraph>
            Welcome to <span className={classes.app}>Medisanté {appName}!</span>
          </Typography>
        ) : null}
        {!isNewPasswordRequired && (
          <>
            {!isEmbedded ? (
              <Typography variant='subtitle1'>
                Please log in to continue
              </Typography>
            ) : (
              <Typography variant='subtitle1'>Login</Typography>
            )}
            <div className={classes.formBox}>
              <form onSubmit={handleSubmit} className={classes.form}>
                <MedTextField
                  margin='normal'
                  id='email'
                  type='email'
                  label='Email'
                  fullWidth
                  name='email'
                  value={email}
                  onChange={e => {
                    setEmail(e.target.value);
                  }}
                  variant='outlined'
                  required
                />
                <TextField
                  margin='normal'
                  id='password'
                  type='password'
                  label='Password'
                  name='password'
                  fullWidth
                  value={password}
                  onChange={e => {
                    setPassword(e.target.value);
                  }}
                  variant='outlined'
                  required
                />
                <MuiLink
                  color='textSecondary'
                  href='#'
                  aria-disabled={isEmail(email)}
                  className={classes.forgotPassword}
                  onClick={(e: React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLSpanElement>) => {
                    e.preventDefault();
                    if (isEmail(email)) {
                      handleCodeSubmit(e)
                    } else {
                      enqueueSnackbar("Email required", {variant: "warning"})
                    }
                  }}
                >
                  Reset password
                </MuiLink>
                <Button
                  type='submit'
                  variant='contained'
                  color='primary'
                  fullWidth
                  disabled={
                    isLoading || email.length === 0 || password.length === 0
                  }
                >
                  Log In
                </Button>
              </form>
            </div>

          </>
        )}
        {isNewPasswordRequired && (
          <SetNewPassword
            user={user}
            onDone={() => {
              setIsNewPasswordRequired(false);
              history.push("/");
            }}
          />
        )}
      </div>
    );
  },
);

export default Login;
