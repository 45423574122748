import Drawer, { DrawerProps } from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { Theme } from '@material-ui/core/styles';
import FolderSharedIcon from '@material-ui/icons/FolderShared';
import GroupIcon from '@material-ui/icons/Group';
import PersonIcon from '@material-ui/icons/Person';
import { makeStyles } from '@material-ui/styles';
import React, { FunctionComponent, memo, useState } from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';
import {
  hasOneOfRoles,
  hasUserOrgAnyRole,
  Role,
} from '../../../contexts/AuthProvider/types';
import './MainDrawer.css';
import {
  IconButton,
  Typography,
  Divider,
  FormControlLabel,
  Switch,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { useAppContext } from '../../../AppContext';
import CloseIcon from '@material-ui/icons/Close';
import Auth from '@aws-amplify/auth';
import ChangePasswordDialog from '../../../components/ChangePasswordDialog/ChangePasswordDialog';
import { useAuthUser } from '../../../contexts/AuthProvider/hooks';
import { useSnackbar } from 'notistack';
import { useUpdateUserMutation } from '../../../store/api-slice';
import { useConfig } from '../../../eliot-components/src/ConfigProvider/hooks';

const drawerWidth = 230;

// required for react-router-dom < 6.0.0
// see https://github.com/ReactTraining/react-router/issues/6056#issuecomment-435524678
const AdapterNavLink = React.forwardRef<HTMLAnchorElement, NavLinkProps>(
  (props, ref) => <NavLink innerRef={ref as any} {...props} />
);

const useStyles = makeStyles((theme: Theme) => ({
  username: {
    wordBreak: 'break-all',
  },
  drawer: {
    flexShrink: 0,
    transition: 'width 225ms',
    [theme.breakpoints.down('md')]: {
      width: 300,
    },
  },
  drawerPaper: {
    minWidth: drawerWidth,
    transition: 'none !important',
    [theme.breakpoints.down('md')]: {
      width: 300,
    },
  },
  userSidebar: {
    display: 'none',
    [theme.breakpoints.down('md')]: {
      display: 'block',
    },
  },
  toolbar: theme.mixins.toolbar,
  close: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    height: '100%',
    paddingRight: '2px',
    backgroundColor: theme.palette.primary.main,
    '& button': {
      color: theme.palette.primary.contrastText,
    },
  },
  darkModeLabel: {
    '&.MuiFormControlLabel-labelPlacementStart': {
      marginLeft: 0,
    },
  },
}));

const MainDrawer: FunctionComponent = memo(() => {
  const { state, dispatch, isDarkModeActive, toggleDarkMode } = useAppContext();
  const classes = useStyles();
  const authUser = useAuthUser();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();

  const hasUserRole = hasOneOfRoles(authUser, [
    Role.OrganizationManager,
    Role.UserManager,
    Role.UserViewer,
  ]);

  const isLarge = useMediaQuery(theme.breakpoints.up('lg'));
  const [isChangePasswordDialogOpen, setIsChangePasswordDialogOpen] =
    useState(false);

  let { sidebar } = state;

  let variant: DrawerProps['variant'] = 'temporary';

  let visibleBigScreen = true;

  if (isLarge) {
    variant = 'persistent';
    visibleBigScreen = !(sidebar ? sidebar : false);
  }

  const username = authUser.givenName || authUser.nickname || authUser.email;

  const handleLogout = async () => {
    window.location.replace('/logout');
  };

  const handleChangePassword = (isSuccess: boolean, hasError: boolean) => {
    if (isSuccess) {
      enqueueSnackbar('Password successfully changed', { variant: 'success' });
      setIsChangePasswordDialogOpen(false);
    }
  };

  const handleAPIKeyClipboard = async () => {
    const session = await Auth.currentSession();
    const token = session.getIdToken().getJwtToken();
    navigator.clipboard.writeText('Bearer ' + token);
    enqueueSnackbar('API Key copied to clipboard', { variant: 'info' });
  };

  const apiKey = hasUserOrgAnyRole(authUser) ? (
    <ListItem button onClick={handleAPIKeyClipboard}>
      <ListItemText>API Key</ListItemText>
    </ListItem>
  ) : null;

  const [updateUser] = useUpdateUserMutation();
  const { apiBaseURL } = useConfig();
  const [isLoading, setIsLoading] = useState(false);

  return (
    <Drawer
      variant={variant}
      open={isLarge ? visibleBigScreen : sidebar}
      className={classes.drawer}
      classes={{
        paper: classes.drawerPaper,
      }}
      onClose={() => {
        dispatch('sidebar-changed');
      }}
      style={{ width: visibleBigScreen ? drawerWidth : 0 }}
    >
      <div className={classes.toolbar}>
        <div className={classes.close}>
          <IconButton
            onClick={() => {
              dispatch('sidebar-changed');
            }}
          >
            <CloseIcon />
          </IconButton>
        </div>
      </div>
      <List className={classes.userSidebar}>
        <ListItem className={classes.username}>
          <Typography variant="h5">{username}</Typography>
        </ListItem>
        <ListItem>
          <FormControlLabel
            control={
              <Switch
                color="primary"
                checked={isDarkModeActive}
                onClick={toggleDarkMode}
              />
            }
            label="Dark mode"
          />
        </ListItem>

        {((authUser.globalRoles && authUser.globalRoles.length > 0) ||
          authUser?.globalRolesModeEnabled) && (
          <ListItem disabled={isLoading}>
            <FormControlLabel
              control={
                <Switch
                  color="primary"
                  checked={
                    authUser.globalRoles && authUser.globalRoles.length > 0
                  }
                  onClick={() => {
                    setIsLoading(true);
                    const enabled =
                      authUser.globalRoles && authUser.globalRoles.length > 0;
                    updateUser({
                      data: { globalRolesModeEnabled: !enabled },
                      apiBaseURL,
                      id: authUser.id,
                    }).then(() => {
                      window.location.reload();
                    });
                  }}
                />
              }
              label="Global mode"
            />
          </ListItem>
        )}

        <ListItem button onClick={() => setIsChangePasswordDialogOpen(true)}>
          <ListItemText>Change Password</ListItemText>
        </ListItem>
        {apiKey}
        <ListItem button onClick={handleLogout}>
          <ListItemText>Logout</ListItemText>
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListItem button component={AdapterNavLink} to="/orgs">
          <ListItemIcon>
            <FolderSharedIcon />
          </ListItemIcon>
          <ListItemText primary={`${hasUserRole ? '' : 'My '}Orgs`} />
        </ListItem>
        <ListItem button component={AdapterNavLink} to="/users" exact>
          <ListItemIcon>
            <GroupIcon />
          </ListItemIcon>
          <ListItemText primary="Users" />
        </ListItem>
        <ListItem
          button
          component={AdapterNavLink}
          to={`/users/${authUser.id}`}
        >
          <ListItemIcon>
            <PersonIcon />
          </ListItemIcon>
          <ListItemText primary="Me" />
        </ListItem>
      </List>
      <ChangePasswordDialog
        onConfirm={handleChangePassword}
        onClose={() => {
          setIsChangePasswordDialogOpen(false);
        }}
        isOpen={isChangePasswordDialogOpen}
      ></ChangePasswordDialog>
    </Drawer>
  );
});

export default MainDrawer;
