import React from 'react';
import OrganisationIcon from '@material-ui/icons/FolderShared';
import UserIcon from '@material-ui/icons/Group';
import MeIcon from '@material-ui/icons/Person';
import { IconButton, makeStyles, Theme, Box } from '@material-ui/core';
import { useHistory } from 'react-router';

const useStyles = makeStyles((theme: Theme) => ({
  menu: {
    '& .inactive': {
      color: 'white',
    },
    '& .Mui-disabled': {
      color: 'white',
      borderRadius: 0,
      '&:after': {
        content: '""',
        display: 'block',
        height: 3,
        width: '100%',
        position: 'absolute',
        bottom: -6,
        backgroundColor: theme.palette.primary.main,
        [theme.breakpoints.down('xs')]: {
          bottom: -2,
        },
      },
    },
  },
}));

const css = (current: string, page: string) => {
  if (current === page) {
    return '';
  } else {
    return 'inactive';
  }
};

const MobileMenu = (props: { page: string; userId: string }) => {
  const { page, userId } = props;
  const classes = useStyles();
  const history = useHistory();

  return (
    <Box className={classes.menu}>
      <IconButton
        disabled={css(page, 'organisations') === ''}
        onClick={() => {
          history.push('/orgs');
        }}
        className={css(page, 'organisations')}
      >
        <OrganisationIcon />
      </IconButton>
      <IconButton
        disabled={css(page, 'users') === ''}
        onClick={() => {
          history.push('/users');
        }}
        className={css(page, 'users')}
      >
        <UserIcon />
      </IconButton>
      <IconButton
        disabled={css(page, 'me') === ''}
        onClick={() => {
          history.push('/users/' + userId);
        }}
        className={css(page, 'me')}
        edge="end"
      >
        <MeIcon />
      </IconButton>
    </Box>
  );
};

export default MobileMenu;
