import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { Theme } from '@material-ui/core/styles';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import { makeStyles } from '@material-ui/styles';
import React, { FunctionComponent, useState, useRef } from 'react';
import {
  Role,
  hasOneOfGlobalRoles,
  hasOneOfRoles,
  hasRoleInAnyUsersOrg,
} from '../../../../contexts/AuthProvider/types';
import AddOrgRoleDialog from '../AddOrgRoleDialog/AddOrgRoleDialog';
import AddGlobalRoleDialog from '../AddGlobalRoleDialog/AddGlobalRoleDialog';
import { useAuthUser } from '../../../../contexts/AuthProvider/hooks';
import { User } from '../../../Users/types';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import RefreshIcon from '@material-ui/icons/Refresh';
import ConfirmationDialog from '../../../../eliot-components/src/ConfirmationDialog/ConfirmationDialog';
import { RemoveCircleOutline } from '@material-ui/icons';

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    marginRight: theme.spacing(1),
  },
  menuButton: {
    zIndex: 2,
  },
}));

interface Props {
  onMergeOrgRoles?(userID: string, roles: Role[]): void;
  onMergeGlobalRoles?(roles: Role[]): void;
  onChangeCareRole?(change: boolean): void;
  onReactivate?(): void;
  onToggleDisableUser?(actualState: boolean): void;
  onRemoveUser?(): void;
  fetchRoles?(): void;
  currentUser?: User;
}

const ActionsMenu: FunctionComponent<Props> = ({
  onMergeOrgRoles,
  onMergeGlobalRoles,
  onChangeCareRole,
  onReactivate,
  currentUser,
  onRemoveUser,
  onToggleDisableUser,
}) => {
  const classes = useStyles();
  const button = useRef<HTMLButtonElement>();
  const [isOpen, setIsOpen] = useState(false);
  const [isAddOrgRoleDialogOpen, setIsAddOrgRoleDialogOpen] = useState(false);
  const [isDisableUserDialogOpen, setIsDisableUserDialogOpen] = useState(false);
  const [isRemoveUserDialogOpen, setIsRemoveUserDialogOpen] = useState(false);
  const [isAddGlobalRoleDialogOpen, setIsAddGlobalRoleDialogOpen] =
    useState(false);
  const authUser = useAuthUser();
  const [activeCare, setActiveCare] = useState(
    currentUser ? currentUser.canCreateCare : false
  );

  const handleClose = () => setIsOpen(false);

  const shouldShowCanCreateCare =
    authUser.canCreateCare &&
    hasOneOfRoles(currentUser, [Role.OrganizationManager]);

  return (
    <>
      <Button
        innerRef={button}
        color="primary"
        aria-owns={isOpen ? 'actions-menu' : undefined}
        aria-haspopup="true"
        className={classes.menuButton}
        onClick={() => setIsOpen(true)}
      >
        Actions <KeyboardArrowDownIcon />
      </Button>
      <Menu
        id="actions-menu"
        anchorEl={button.current}
        open={isOpen}
        onClose={handleClose}
      >
        {(hasOneOfGlobalRoles(authUser, [
          Role.OrganizationManager,
          Role.UserManager,
        ]) ||
          (currentUser &&
            hasRoleInAnyUsersOrg(
              authUser,
              [Role.UserManager, Role.OrganizationManager],
              currentUser.orgs
            ))) && (
          <MenuItem
            onClick={() => {
              handleClose();
              setIsAddOrgRoleDialogOpen(true);
            }}
          >
            <PersonAddIcon className={classes.icon} />
            Organisation role(s)
          </MenuItem>
        )}
        {hasOneOfGlobalRoles(authUser, [Role.OrganizationManager]) && (
          <MenuItem
            onClick={() => {
              handleClose();
              setIsDisableUserDialogOpen(true);
            }}
          >
            {currentUser && currentUser.deactivated ? (
              <LockOpenIcon className={classes.icon} />
            ) : (
              <LockIcon className={classes.icon} />
            )}
            {currentUser && currentUser.deactivated ? 'Activate' : 'Deactivate'}
          </MenuItem>
        )}
        {(hasOneOfGlobalRoles(authUser, [Role.OrganizationManager]) ||
          (currentUser && authUser.id === currentUser.id)) && (
          <MenuItem
            onClick={() => {
              handleClose();
              setIsRemoveUserDialogOpen(true);
            }}
          >
            <RemoveCircleOutline className={classes.icon} />
            Remove user
          </MenuItem>
        )}
        {hasOneOfGlobalRoles(authUser, [
          Role.UserManager,
          Role.OrganizationManager,
        ]) && (
          <MenuItem
            onClick={() => {
              handleClose();
              setIsAddGlobalRoleDialogOpen(true);
            }}
          >
            <PersonAddIcon className={classes.icon} />
            Global role(s)
          </MenuItem>
        )}
        {(hasOneOfGlobalRoles(authUser, [Role.UserManager]) ||
          hasOneOfRoles(authUser, [Role.UserManager])) && (
          <MenuItem
            onClick={() => {
              handleClose();
              if (onReactivate) {
                onReactivate();
              }
            }}
          >
            <RefreshIcon className={classes.icon} />
            {currentUser && currentUser.isConfirmed
              ? 'Reset Password'
              : 'Resend Password'}
          </MenuItem>
        )}
        {shouldShowCanCreateCare && (
          <MenuItem>
            <FormControl component="fieldset">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      color="primary"
                      checked={activeCare}
                      onChange={(e) => {
                        handleClose();
                        setActiveCare(e.target.checked);
                        if (onChangeCareRole && currentUser) {
                          onChangeCareRole(e.target.checked);
                        }
                      }}
                    />
                  }
                  label="Care target system"
                />
              </FormGroup>
            </FormControl>
          </MenuItem>
        )}
      </Menu>
      {isAddOrgRoleDialogOpen && (
        <AddOrgRoleDialog
          isOpen={isAddOrgRoleDialogOpen}
          onClose={() => {
            setIsAddOrgRoleDialogOpen(false);
          }}
          onAdd={onMergeOrgRoles}
          user={currentUser}
        />
      )}
      {isAddGlobalRoleDialogOpen && (
        <AddGlobalRoleDialog
          isOpen={isAddGlobalRoleDialogOpen}
          onClose={() => {
            setIsAddGlobalRoleDialogOpen(false);
          }}
          onAdd={onMergeGlobalRoles}
          userRoles={
            !!currentUser && !!currentUser.globalRoles
              ? currentUser.globalRoles
              : []
          }
        />
      )}
      {isDisableUserDialogOpen && (
        <Dialog
          open={isDisableUserDialogOpen}
          onClose={() => setIsDisableUserDialogOpen(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you that you want to
              {currentUser && currentUser.deactivated
                ? ' activate'
                : ' deactivate'}{' '}
              user?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setIsDisableUserDialogOpen(false);
              }}
              color="primary"
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                setIsDisableUserDialogOpen(false);
                if (onToggleDisableUser) {
                  onToggleDisableUser(
                    currentUser ? !!currentUser.deactivated : false
                  );
                }
              }}
              color="primary"
              autoFocus
            >
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <ConfirmationDialog
        questionText="Are you sure you want to remove this user?"
        confirmationText="Remove"
        cancelText="Cancel"
        title="Remove user"
        onConfirm={() => {
          if (onRemoveUser) {
            onRemoveUser();
          }
        }}
        onClose={() => {
          setIsRemoveUserDialogOpen(false);
        }}
        isOpen={isRemoveUserDialogOpen}
      />
    </>
  );
};

export default ActionsMenu;
