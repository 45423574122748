/// <reference path="./utils/extensionMethods.d.ts" /MedisanteDevice>

import './utils/extensionMethods';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import '@openfonts/poppins_latin-ext';
import reportWebVitals from './reportWebVitals';
import App from './App';
import { initSessionManagement } from './eliot-components/src/utils/sessionManagment';
import store from './store';
import { Provider } from 'react-redux';

initSessionManagement().then(() => {
  ReactDOM.render(
    <Provider store={store}>
      <App />
    </Provider>,
    document.getElementById('root')
  );
});
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
