import { Org, shouldShowOrgDetails } from '../../types';
import { Button } from '@material-ui/core';
import { AdapterLink } from '../../Orgs';
import Visible from '../../../../components/Visible/Visible';
import React from 'react';
import { useAuthUser } from '../../../../contexts/AuthProvider/hooks';

interface Props {
  org: Org;
}

const DetailCell: React.FC<Props> = ({ org }) => {
  const authUser = useAuthUser();

  return (
    <Visible when={shouldShowOrgDetails(org, authUser)}>
      <Button
        color="primary"
        component={AdapterLink}
        to={`/orgs/${encodeURIComponent(org.id)}`}
      >
        Details
      </Button>
    </Visible>
  );
};

export default DetailCell;
