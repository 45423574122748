import CircularProgress from '@material-ui/core/CircularProgress';
import { Theme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import React, { FunctionComponent, memo } from 'react';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    textAlign: 'center',
    marginTop: theme.spacing(6),
  },
}));

const Loading: FunctionComponent = memo(() => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <CircularProgress />
    </div>
  );
});

export default Loading;
