import React, { useLayoutEffect } from 'react';
import { usePrevious } from '../../../common';
import Select, { SelectProps } from '@material-ui/core/Select';
import CheckBox from '@material-ui/core/Checkbox';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl, { FormControlProps } from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';

const nullifyIfEmptyArray = (obj: any) => {
  if (!!obj && Array.isArray(obj) && obj.length === 0) return null;

  return obj;
};

interface Props<T> {
  name: string;
  options?: T[];
  valueGetter?: (obj: T) => T[keyof T];
  optionTextGetter?: (obj: T) => T[keyof T];
  emptyMessage?: string;
  helperText?: string | null;
  formControlProps?: FormControlProps;
  itemDisabled?: (obj: T) => T[keyof T];
}

const useStyles = makeStyles((theme: Theme) => ({
  select: {
    minWidth: theme.spacing(25),
  },
  selectInput: {
    '&.Mui-disabled': {
      backgroundColor: 'rgba(1,1,1,0.1)',
    },
  },
}));

function MedSelect(props: SelectProps & Props<any>) {
  const {
    name,
    options,
    valueGetter,
    optionTextGetter,
    children,
    value,
    placeholder,
    emptyMessage,
    helperText,
    multiple,
    formControlProps,
    itemDisabled,
    ...restProps
  } = props;

  const classes = useStyles();

  const inputLabel = React.useRef<HTMLLabelElement>(null);
  const [labelWidth, setLabelWidth] = React.useState(0);
  React.useEffect(() => {
    inputLabel.current && setLabelWidth(inputLabel.current.offsetWidth);
  }, []);

  const previousValue = usePrevious(value);
  useLayoutEffect(() => {
    if (
      !!name &&
      !!nullifyIfEmptyArray(previousValue) &&
      !nullifyIfEmptyArray(value)
    ) {
      const select = document.getElementById(`select-${name}`);
      select && select.focus();
    }
  }, [name, value, previousValue]);

  const newProps: SelectProps = {
    margin: 'dense',
    variant: 'outlined',
    fullWidth: true,
    value: value || '',
    SelectDisplayProps: {
      // @ts-ignore
      'data-testid': `select-${name}`,
    },
    MenuProps: {
      marginThreshold: 32,
      anchorOrigin: { vertical: 'top', horizontal: 'center' },
      transformOrigin: { vertical: 'top', horizontal: 'center' },
    },
    inputProps: { className: classes.selectInput },
    labelWidth,
    multiple,
    ...restProps,
  };

  const getMenu = () => {
    if (props.children) {
      return props.children;
    }

    if (options && optionTextGetter && valueGetter) {
      return options.length > 0 ? (
        options.map((o: any) => (
          <MenuItem key={optionTextGetter(o)} value={valueGetter(o)} disabled={itemDisabled && itemDisabled(o)} >
            {multiple ? (
              <CheckBox
                role="multiselect-checkbox"
                checked={(value as any[]).indexOf(valueGetter(o)) > -1}
              />
            ) : null}

            {optionTextGetter(o)}
          </MenuItem>
        ))
      ) : (
        <MenuItem disabled={true}>
          {emptyMessage || 'No available options!'}
        </MenuItem>
      );
    }

    return null;
  };

  return (
    <FormControl
      variant="outlined"
      fullWidth={newProps.fullWidth}
      margin="dense"
      {...formControlProps}
    >
      <InputLabel
        ref={inputLabel}
        id={`${props.name}-input-label`}
        style={{ zIndex: 1 }}
      >
        {props.placeholder}
      </InputLabel>
      <Select {...newProps}>{getMenu()}</Select>
      {!!helperText ? <FormHelperText>{helperText}</FormHelperText> : null}
    </FormControl>
  );
}

export default MedSelect;
