import { FunctionComponent } from 'react';
import { Button, Grid, Paper, Typography } from '@material-ui/core';
import { ErrorOutline } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import {setDeviceFilters} from "../../../store/app-slice";
import {useAppDispatch} from "../../../hooks";

const ErrorPage: FunctionComponent<{ error?: string, buttonLink: string, buttonText: string }> = ({ error, buttonLink = "/", buttonText }) => {
  let errorMessage = error || 'Internal error';
  const history = useHistory();
  const query = new URLSearchParams(history.location.search);
  const dispatchRedux = useAppDispatch();

  if (query.get("error")) errorMessage = query.get("error") || errorMessage

  dispatchRedux(
    setDeviceFilters(
     []
    )
  );

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      style={{ height: '100vh', padding: '24px' }}
    >
      <Paper
        variant="outlined"
        style={{
          textAlign: 'center',
          height: '90vh',
          minHeight: '350px',
          width: '100%',
        }}
      >
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          style={{ height: '100%' }}
        >
          <div>
            <ErrorOutline color="error" style={{ fontSize: 200 }} />
          </div>
          <Typography color="textSecondary" variant="h4">
            {errorMessage}
          </Typography>
          <Button
            variant="outlined"
            color="primary"
            style={{ marginTop: 20 }}
            onClick={() => history.replace(buttonLink)}
          >
            {buttonText}
          </Button>
        </Grid>
      </Paper>
    </Grid>
  );
};

export default ErrorPage;
