import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import { Theme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import { makeStyles } from '@material-ui/styles';
import { FunctionComponent, memo, useState } from 'react';
import AddRoleButton from '../AddRoleButton/AddRoleButton';
import { OrgRole } from '../AddRoleButton/types';
import {
  ALL_ROLES,
  COMBINATED_ROLES,
  DescriptiveCombinatedRole,
  DescriptiveRole,
  Role,
} from '../../../../contexts/AuthProvider/types';
import ButtonProgress from '../../../../eliot-components/src/ButtonProgress/ButtonProgress';
import { useConfig } from '../../../../eliot-components/src/ConfigProvider/hooks';
import { groupBy } from '../../../../utils/helpers';
import { useCreateUserMutation } from '../../../../store/api-slice';
import { resolveCombinatedRoles } from '../../../../components/RolesTable/RolesTable';
import { useSnackbar } from 'notistack';
import { parseErrorMessage } from '../../../../eliot-components/src/utils/helpers';

const useStyles = makeStyles((theme: Theme) => ({
  fab: {
    position: 'fixed',
    right: theme.spacing(4),
    bottom: theme.spacing(4),
    [theme.breakpoints.down('xs')]: {
      right: theme.spacing(2),
      bottom: theme.spacing(2),
    },
  },
  dialogContent: {
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      maxWidth: '600px',
    },
  },
  mobile: {
    display: 'block',
    '& thead': {
      display: 'none',
    },
    '& tbody': {
      display: 'block',
      '& tr': {
        display: 'block',
        backgroundColor: theme.palette.action.hover,
        borderRadius: theme.shape.borderRadius,
        position: 'relative',
        marginBottom: theme.spacing(1),
        '& td': {
          display: 'block',
          borderBottom: 'none',
          padding: theme.spacing(2, 2, 2, 2),
          '&.organisation': {
            paddingBottom: 0,
            fontWeight: 'bold',
          },
          '&.actions': {
            padding: 0,
          },
          '& .remove': {
            position: 'absolute',
            bottom: 0,
            right: 0,
          },
        },
      },
    },
  },
}));

interface Props {
  orgID: string;
  isOpen?: boolean;

  onCreated?(): void;

  onClose?(): void;
}

const isFormValid = (
  email: string,
  confirmEmail: string,
  givenName: string,
  familyName: string,
  roles: OrgRole[]
) => {
  let isFormValid = true;

  isFormValid = isFormValid && !!email;
  isFormValid = isFormValid && !!confirmEmail;
  isFormValid = isFormValid && confirmEmail === email;
  isFormValid = isFormValid && !!givenName;
  isFormValid = isFormValid && !!familyName;

  return isFormValid;
};

export const resolveCombinatedRolesForOrgsRole = (roles: OrgRole[]) => {
  let orgRolesMapping: { [key: string]: { roles: Role[]; orgName: string } } =
    {};
  roles.forEach((r) => {
    if (!orgRolesMapping[r.orgID])
      orgRolesMapping[r.orgID] = { roles: [], orgName: r.orgName };
    orgRolesMapping[r.orgID].roles.push(r.role);
  });

  let selectedRoles: OrgRole[] = [];

  Object.keys(orgRolesMapping).forEach((orgID) => {
    const roles = resolveCombinatedRoles(orgRolesMapping[orgID].roles).map(
      (r) =>
        ({
          orgID,
          role: r.id,
          orgName: orgRolesMapping[orgID].orgName,
        } as OrgRole)
    );
    selectedRoles = selectedRoles.concat(roles);
  });

  return selectedRoles;
};

const CreateUserDialog: FunctionComponent<Props> = memo(
  ({ onCreated, isOpen = true, onClose, orgID = '' }) => {
    const classes = useStyles();
    const { apiBaseURL } = useConfig();
    const { enqueueSnackbar } = useSnackbar();

    const [createUser, { isLoading }] = useCreateUserMutation();

    const [email, setEmail] = useState('');
    const [confirmEmail, setConfirmEmail] = useState('');
    const [givenName, setGivenName] = useState('');
    const [familyName, setFamilyName] = useState('');
    const [selectedRoles, setSelectedRoles] = useState<OrgRole[]>([]);

    const handleClose = () => {
      setEmail('');
      setConfirmEmail('');
      setGivenName('');
      setFamilyName('');
      setSelectedRoles([]);
      if (onClose) {
        onClose();
      }
    };

    const handleSubmit = (e: any) => {
      e.preventDefault();

      const rolesByOrgId = groupBy(selectedRoles, 'orgID');
      const userOrgs = Object.entries(rolesByOrgId).map(([key, value]) => ({
        id: key,
        name: value[0].orgName,
        familyName,
        givenName,
        roles: value.map((v) => v.role),
      }));

      createUser({
        user: { email, givenName, familyName, orgs: userOrgs },
        apiBaseURL,
      }).then((resp: any) => {
        if (!resp.error) {
          if (onCreated) {
            onCreated();
          }
          handleClose();
          return;
        }
        enqueueSnackbar(parseErrorMessage(resp.error), { variant: 'error' });
      });
    };

    const isValid = isFormValid(
      email,
      confirmEmail,
      givenName,
      familyName,
      selectedRoles
    );
    const allRoles: (DescriptiveRole | DescriptiveCombinatedRole)[] =
      ALL_ROLES.concat(COMBINATED_ROLES);
    return (
      <>
        <Dialog
          aria-labelledby="dialog-title"
          open={isOpen}
          onClose={() => {
            handleClose();
          }}
        >
          <DialogTitle id="dialog-title">Invite User</DialogTitle>
          <form onSubmit={handleSubmit}>
            <DialogContent className={classes.dialogContent}>
              <TextField
                margin="dense"
                variant="outlined"
                id="email"
                label="Email"
                type="email"
                autoFocus
                required
                fullWidth
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
              <br />
              <br />
              <TextField
                margin="dense"
                variant="outlined"
                id="confirm-email"
                label="Confirm Email"
                type="email"
                required
                fullWidth
                value={confirmEmail}
                helperText={
                  confirmEmail && email !== confirmEmail
                    ? 'Confirm email must be the same as email'
                    : null
                }
                onChange={(e) => {
                  setConfirmEmail(e.target.value);
                }}
              />
              <br />
              <br />
              <Grid container spacing={2}>
                <Grid item sm={6} xs={12}>
                  <TextField
                    margin="dense"
                    variant="outlined"
                    id="givenName"
                    label="Given Name"
                    fullWidth
                    required
                    value={givenName}
                    onChange={(e) => {
                      setGivenName(e.target.value);
                    }}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <TextField
                    margin="dense"
                    variant="outlined"
                    id="familyName"
                    label="Family Name"
                    fullWidth
                    required
                    value={familyName}
                    onChange={(e) => {
                      setFamilyName(e.target.value);
                    }}
                  />
                </Grid>
              </Grid>
              <br />
              <br />
              <Typography variant="subtitle2" color="textSecondary">
                Roles
              </Typography>
              {selectedRoles.length > 0 && (
                <Table className={classes.mobile}>
                  <TableBody>
                    {resolveCombinatedRolesForOrgsRole(selectedRoles).map(
                      (r) => (
                        <TableRow key={`${r.orgID}-${r.role}`}>
                          <TableCell className="organisation">
                            {r.orgName}
                          </TableCell>
                          <TableCell>
                            {allRoles.filter((ar) => ar.id === r.role)[0].label}
                          </TableCell>
                          <TableCell className="actions" align="right">
                            <Tooltip title="Remove">
                              <IconButton
                                className="remove"
                                aria-label="Remove"
                                onClick={() => {
                                  const descRole = allRoles.find(
                                    (ar) => ar.id === r.role
                                  );
                                  if (descRole) {
                                    const roles =
                                      'roles' in descRole
                                        ? selectedRoles.filter(
                                            (fr) =>
                                              !(
                                                fr.orgID === r.orgID &&
                                                descRole.roles.indexOf(
                                                  fr.role
                                                ) !== -1
                                              )
                                          )
                                        : selectedRoles.filter(
                                            (fr) =>
                                              !(
                                                fr.orgID === r.orgID &&
                                                fr.role === r.role
                                              )
                                          );
                                    setSelectedRoles(roles);
                                  }
                                }}
                              >
                                <RemoveCircleOutlineIcon color="primary" />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      )
                    )}
                  </TableBody>
                </Table>
              )}
              <br />
              <AddRoleButton
                selectedOrg={orgID}
                selectedRoles={selectedRoles}
                onAdded={setSelectedRoles}
              />
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  handleClose();
                }}
              >
                Cancel
              </Button>
              <ButtonProgress
                type="submit"
                variant="contained"
                color="primary"
                isLoading={isLoading}
                data-testid="submit-create"
                disabled={!isValid}
              >
                Submit
              </ButtonProgress>
            </DialogActions>
          </form>
        </Dialog>
      </>
    );
  }
);

export default CreateUserDialog;
