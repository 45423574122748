import { UserOrg } from '../../pages/Users/types';

export enum Role {
  Caregiver = 'caregiver',
  OrganizationManager = 'organization-manager',
  UserManager = 'user-manager',
  UserViewer = 'user-viewer',
  DeviceManager = 'device-manager',
  DeviceViewer = 'device-viewer',
  SystemAdmin = 'system-admin',
  Viewer = 'viewer',
}

export interface DescriptiveRole {
  id: Role;
  label: string;
  description: string;
  conflictingRole?: Role[];
  shouldDisplayFullWidth?: boolean;
}

export interface DescriptiveCombinatedRole extends DescriptiveRole {
  roles: Role[];
}

export interface AuthRoles {
  readonly globalRoles: string[];
  readonly orgRoles: string[];
}

export interface AuthUser {
  readonly id: string;
  readonly email: string;
  readonly givenName?: string;
  readonly nickname?: string;
  readonly orgs?: UserOrg[];
  readonly globalRoles?: Role[];
  readonly canCreateCare?: boolean;
  readonly needResetPassword?: boolean;
  readonly globalRolesModeEnabled?: boolean;
}

export const COMBINATED_ROLES: DescriptiveCombinatedRole[] = [
  {
    id: Role.SystemAdmin,
    roles: [Role.UserManager, Role.DeviceManager, Role.OrganizationManager],
    label: 'System Admin',
    description: 'Comprises user, device and organization management roles',
    conflictingRole: [Role.UserViewer, Role.DeviceViewer],
  },
  {
    id: Role.Viewer,
    roles: [Role.UserViewer, Role.DeviceViewer],
    label: 'System Viewer',
    description: 'Comprises user and device viewer roles',
    conflictingRole: [Role.UserManager, Role.DeviceManager],
  },
];

export const ALL_ROLES: DescriptiveRole[] = [
  {
    id: Role.OrganizationManager,
    label: 'Organization Manager',
    description: 'For creating/managing users and creating organisations',
    shouldDisplayFullWidth: true,
  },
  {
    id: Role.UserManager,
    label: 'User Manager',
    description: 'For managing role based access',
    conflictingRole: [Role.UserViewer],
  },
  {
    id: Role.UserViewer,
    label: 'User Viewer',
    description: 'For viewing users',
    conflictingRole: [Role.UserManager],
  },
  {
    id: Role.DeviceManager,
    label: 'Device Manager',
    description:
      'For managing devices (configuration/transfer) and creating/editing target systems',
    conflictingRole: [Role.DeviceViewer],
  },
  {
    id: Role.DeviceViewer,
    label: 'Device Viewer',
    description: 'For viewing devices',
    conflictingRole: [Role.DeviceManager],
  },
  {
    id: Role.Caregiver,
    label: 'Caregiver',
    description: 'For managing patients',
    shouldDisplayFullWidth: true,
  },
];

export const hasOneOfGlobalRoles = (
  authUser: AuthUser = { id: '', email: '' },
  roles: Role[]
) => {
  if (authUser.globalRoles) {
    for (const r of roles) {
      if (authUser.globalRoles.indexOf(r) > -1) {
        return true;
      }
    }
  }
  return false;
};

export const hasOneOfOrgRoles = (
  authUser: AuthUser = { id: '', email: '' },
  orgID: string,
  roles: Role[]
) => {
  if (hasOneOfGlobalRoles(authUser, roles)) {
    return true;
  }
  if (authUser.orgs) {
    for (const o of authUser.orgs) {
      if (o.id === orgID) {
        for (const r of roles) {
          if (o.roles && o.roles.indexOf(r) > -1) {
            return true;
          }
        }
      }
    }
  }
  return false;
};

export const hasOneOfRoles = (
  authUser: AuthUser = { id: '', email: '' },
  roles: Role[]
) => {
  if (hasOneOfGlobalRoles(authUser, roles)) {
    return true;
  }
  if (authUser.orgs) {
    for (const o of authUser.orgs) {
      for (const r of roles) {
        if (o.roles && o.roles.indexOf(r) > -1) {
          return true;
        }
      }
    }
  }
};

export const hasRoleInAnyUsersOrg = (
  authUser: AuthUser = { id: '', email: '' },
  roles: Role[],
  orgs: UserOrg[]
) => {
  if (!authUser.orgs) {
    return false;
  }
  for (const r of roles) {
    for (const o of orgs) {
      for (const uo of authUser.orgs) {
        if (o.id === uo.id) {
          for (const ur of uo.roles) {
            if (r === ur) {
              return true;
            }
          }
        }
      }
    }
  }
  return false;
};

export const hasUserOrgRoles = (
  authuser: AuthUser = { id: '', email: '' },
  orgs: UserOrg[] = [],
  roles: Role[] = []
) => {
  if (authuser.orgs) {
    for (const o of orgs) {
      const valid = hasOneOfOrgRoles(authuser, o.id, roles);
      if (valid) {
        return true;
      }
    }
  }
  return false;
};

export const hasUserOrgAnyRole = (
  authUser: AuthUser = { id: '', email: '' }
) => {
  if (authUser && authUser.orgs) {
    if (authUser.orgs.filter((org) => org.roles.length).length > 0) {
      return true;
    } else if (authUser.globalRoles && authUser.globalRoles.length > 0) {
      return true;
    }
  }

  return false;
};

export const shouldBeRoleDisabled = (
  role: DescriptiveRole,
  selectedRoles: Role[]
) =>
  role.conflictingRole
    ? role.conflictingRole.filter((r) => selectedRoles.indexOf(r) > -1).length >
      0
    : false;
