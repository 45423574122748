import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Theme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import React, { FunctionComponent, memo, useState, useRef } from 'react';
import { ALL_ROLES, Role } from '../../../../contexts/AuthProvider/types';
import { useConfig } from '../../../../eliot-components/src/ConfigProvider/hooks';
import lunr from 'lunr';
import { AuthRoles } from '../../../../contexts/AuthProvider/types';
import RolesSelect from '../../../../components/Roles/RolesSelect';
import { useGetUserRolesQuery } from '../../../../store/api-slice';
import { unpackCombinatedRoles } from '../../../../components/RolesTable/RolesTable';

lunr.tokenizer.separator = /\s+/;

const useStyles = makeStyles((theme: Theme) => ({
  dialog: {
    width: '600px',
  },
  dialogContent: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },

  actions: {
    padding: theme.spacing(2),
  },
}));

interface Props {
  isOpen?: boolean;
  onClose?(): void;
  onAdd?(role: Role[]): void;
  userRoles?: Role[];
}

const AddGlobalRoleDialog: FunctionComponent<Props> = memo(
  ({ onAdd, isOpen = true, onClose, userRoles }) => {
    const classes = useStyles();
    const config = useConfig();
    const [roles, setRoles] = useState<Role[]>(userRoles || []);
    const { data: allRoles } = useGetUserRolesQuery({
      apiBaseURL: config.apiBaseURL,
    });
    const [confirmOpen, setConfirmOpen] = useState(false);
    let authRoles: AuthRoles = {
      globalRoles: [],
      orgRoles: [],
    };
    if (allRoles) {
      authRoles = allRoles;
    }

    const handleCancel = () => {
      setRoles(userRoles || []);
      handleClose();
    };

    const handleClose = () => {
      if (onClose) {
        onClose();
      }
    };

    const handleSubmit = () => {
      setConfirmOpen(false);

      if (onAdd && roles) {
        let rolesToAdd: Role[] = [];
        roles.forEach((r) => {
          rolesToAdd = rolesToAdd.concat(unpackCombinatedRoles(r));
        });
        onAdd(rolesToAdd);
      }

      handleClose();
    };

    const cRef = useRef<HTMLDivElement | null>(null);
    const globalRolesWithoutCaregiver = authRoles.globalRoles.filter(
      (r) => r !== Role.Caregiver
    );
    const globalRoles = ALL_ROLES.filter((r) => {
      for (const globalRole of globalRolesWithoutCaregiver) {
        if (globalRole === r.id) {
          return true;
        }
      }
      return false;
    });

    return (
      <>
        <Dialog
          PaperProps={{ className: classes.dialog }}
          aria-labelledby="dialog-title"
          open={isOpen}
          onClose={() => {
            handleClose();
          }}
        >
          <DialogTitle id="dialog-title">Global Role(s)</DialogTitle>
          <DialogContent className={classes.dialogContent}>
            <RolesSelect
              roles={roles}
              setRoles={setRoles}
              allAvailableRoles={globalRoles}
            />
            <br />
          </DialogContent>
          <div ref={cRef}></div>
          <DialogActions classes={{ root: classes.actions }}>
            <Button onClick={handleCancel}>Cancel</Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setConfirmOpen(true);
              }}
            >
              Submit
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={confirmOpen}
          onClose={() => setConfirmOpen(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you that you want to assign this user access to all
              organisations?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setConfirmOpen(false);
              }}
              color="primary"
            >
              Cancel
            </Button>
            <Button onClick={handleSubmit} color="primary" autoFocus>
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
);

export default AddGlobalRoleDialog;
