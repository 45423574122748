import React from 'react';
import Tooltip, { TooltipProps } from '@material-ui/core/Tooltip';

interface Props extends TooltipProps {
  shouldRenderWithTooltip?: boolean;
}

function MedTooltip({
  shouldRenderWithTooltip = true,
  children,
  ...rest
}: Props) {
  return shouldRenderWithTooltip ? (
    <Tooltip {...rest}>{children}</Tooltip>
  ) : (
    children
  );
}

export default MedTooltip;
