import React, { ReactNode } from 'react';

interface Props {
  when?: boolean;
  children: ReactNode;
}

const Visible = React.forwardRef((props: Props, ref) => {
  const { when, children } = props;
  if (when === true) {
    return <>{typeof children === 'function' ? children(ref) : children}</>;
  } else {
    return null;
  }
});

export default Visible;
