import { Theme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import { FunctionComponent, memo } from 'react';
import Loading from '../eliot-components/src/Loading/Loading';
import Header from './components/Header/Header';
import MainDrawer from './components/MainDrawer/MainDrawer';
import { MediaUp } from '../components/MediaQueries/MediaQueries';
import { useSnackbar } from 'notistack';
import { parseErrorMessage } from '../eliot-components/src/utils/helpers';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexGrow: 1,
    paddingTop: theme.mixins.toolbar.minHeight,
    [theme.breakpoints.up('xs')]: {
      paddingTop: 56,
    },
    [theme.breakpoints.up('sm')]: {
      paddingTop: 64,
    },
  },
  mainContentContainer: {
    flexGrow: 1,
    padding: theme.spacing(2),
    maxWidth: '100%',
    transition: 'width 500ms',
    display: 'flex',
    justifyContent: 'center',
    '& .mainContent': {
      flexGrow: 1,
      maxWidth: theme.breakpoints.values.lg,
      [theme.breakpoints.down('sm')]: {
        maxWidth: '100%',
      },
    },
  },
}));

interface Props {
  readonly isLoading?: boolean;
  readonly error?: Error | null;
}

const Layout: FunctionComponent<Props> = memo(
  ({ isLoading = false, error = null, children }) => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    return (
      <>
        <Header />
        <div className={classes.root}>
          <MediaUp query="lg">
            <MainDrawer />
          </MediaUp>
          <main className={classes.mainContentContainer}>
            <div className="mainContent">
              {(() => {
                if (error) {
                  enqueueSnackbar(parseErrorMessage(error), {
                    variant: 'error',
                  });
                  return <div />;
                }
                if (isLoading) {
                  return <Loading />;
                }
                return children;
              })()}
            </div>
          </main>
        </div>
      </>
    );
  }
);

export default Layout;
