import { useState } from 'react';
import {
  useAddUserRolesMutation,
  useDeleteUserRolesMutation,
} from '../../store/api-slice';
import { Org } from '../Orgs/types';

export const useOrg = (apiBaseURL: string, id: string, org?: Org) => {
  const [error, setError] = useState<Error | null>(null);
  const [removeUserRoles] = useDeleteUserRolesMutation();
  const [addUserRoles] = useAddUserRolesMutation();

  const mergeUserRoles = async (userID: string, roles: string[] = []) => {
    const user = org && org.users && org.users.find((u) => u.id === userID);
    const currentOrgRoles: string[] = user && user.roles ? user.roles : [];
    const rolesToRemove = currentOrgRoles.filter((gr) => !roles.includes(gr));
    const rolesToAdd = roles.filter((gr) => !currentOrgRoles.includes(gr));

    setError(null);
    try {
      let add: Promise<any> = Promise.resolve();
      let remove: Promise<any> = Promise.resolve();
      if (rolesToRemove && rolesToRemove.length > 0) {
        remove = removeUserRoles({
          apiBaseURL,
          userID,
          orgID: id,
          roles: rolesToRemove,
        });
      }
      if (rolesToAdd && rolesToAdd.length > 0) {
        add = addUserRoles({
          apiBaseURL,
          userID,
          orgID: id,
          roles: rolesToAdd,
        });
      }

      const resps: any[] = await Promise.all([add, remove]);
      resps.forEach((resp) => {
        if (resp && !!resp.error) throw resp.error;
      });
    } catch (err) {
      setError(err);
    } finally {
      setError(null); // ugh, this is a hack to get the error to clear
    }
  };

  return {
    error,
    mergeUserRoles,
  };
};
