import Auth, { AuthClass } from '@aws-amplify/auth';
import Button from '@material-ui/core/Button';
import { Theme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';
import React, {
  ChangeEvent,
  FormEvent,
  FunctionComponent,
  memo,
  useState,
  useEffect,
} from 'react';
import { useHistory } from 'react-router-dom';
import MuiLink from '@material-ui/core/Link';
import { Link } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { parseErrorMessage } from '../utils/helpers';

const useStyles = makeStyles((theme: Theme) => ({
  forgotPassword: {
    '& #root': {
      padding: theme.spacing(2),
      display: 'flex',
      flexDirection: 'column',
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    flexGrow: 1,
    marginTop: theme.spacing(6),
    textAlign: 'center',
  },
  back: {
    display: 'inline-block',
    padding: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  formBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'center',
    marginTop: theme.spacing(6),
    [theme.breakpoints.up('sm')]: {
      flexGrow: 0,
      marginTop: theme.spacing(16),
      alignItems: 'center',
      maxWidth: '30em',
      width: '100%',
      alignSelf: 'center',
    },
  },
  form: {
    width: '100%',
  },
  confirmation: {
    margin: theme.spacing(5),
  },
  myWrapper: {
    margin: 0,
    position: 'relative',
  },
}));

interface Props {
  readonly auth?: AuthClass;
}

const ForgotPassword: FunctionComponent<Props> = memo(({ auth = Auth }) => {
  const classes = useStyles();
  const [email, setEmail] = useState('');
  const [code, setCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordConfirmation, setNewPasswordConfirmation] = useState('');
  const [isPasswordReset, setIsPasswordReset] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const query = new URLSearchParams(history.location.search);
    if (query.get('email')) {
      setEmail(query.get('email') as string);
    } else {
      history.push('/login');
    }
  }, [history]);

  useEffect(() => {
    document.documentElement.classList.add(classes.forgotPassword);

    return () => {
      document.documentElement.classList.remove(classes.forgotPassword);
    };
  }, [classes.forgotPassword]);

  const handleNewPasswordConfirmationChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    setNewPasswordConfirmation(event.target.value);
  };

  const handleResetSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (newPassword.length < 8 || newPassword.length < 10) {
      enqueueSnackbar('Minimum password length is 10 characters', {
        variant: 'warning',
      });
      return;
    }
    if (newPassword.length >= 257) {
      enqueueSnackbar('Maximum password length is 256 characters', {
        variant: 'warning',
      });
      return;
    }
    if (newPassword !== newPasswordConfirmation) {
      enqueueSnackbar('New password does not match', { variant: 'warning' });
      return;
    }

    try {
      setIsLoading(true);
      await auth.forgotPasswordSubmit(email, code, newPassword);
      if (email) {
        try {
          await auth.signIn(email, newPassword);
          history.push('/');
          return;
        } catch (e) {}
      }
      setIsPasswordReset(true);
    } catch (err) {
      if (err.code === 'NotAuthorizedException') {
        enqueueSnackbar("You don't have permission to reset password", {
          variant: 'error',
        });
        return;
      }
      enqueueSnackbar(parseErrorMessage(err), { variant: 'error' });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={classes.container}>
      {!isPasswordReset && (
        <>
          <Typography variant="h6">Reset password</Typography>
          <div className={classes.formBox}>
            <form onSubmit={handleResetSubmit} className={classes.form}>
              <TextField
                id="code"
                label="Verification code"
                fullWidth
                autoComplete="code"
                value={code}
                onChange={(e) => {
                  setCode(e.target.value);
                }}
                variant="outlined"
                margin="normal"
                required
              />
              <TextField
                id="new-password"
                type="password"
                label="New password"
                fullWidth
                value={newPassword}
                autoComplete="new-password"
                onChange={(e) => {
                  setNewPassword(e.target.value);
                }}
                variant="outlined"
                margin="normal"
                required
              />
              <TextField
                id="confirm-password"
                type="password"
                label="Confirm new password"
                fullWidth
                autoComplete="new-password-confirm"
                value={newPasswordConfirmation}
                onChange={handleNewPasswordConfirmationChange}
                variant="outlined"
                margin="normal"
                required
              />
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                disabled={
                  isLoading ||
                  newPassword === '' ||
                  newPasswordConfirmation === '' ||
                  code === ''
                }
              >
                Submit
              </Button>
            </form>
          </div>
        </>
      )}
      {isPasswordReset && (
        <Typography variant="h5">Password successfully changed</Typography>
      )}
      <MuiLink
        component={Link}
        color="textPrimary"
        className={classes.back}
        to="/"
      >
        Back to login
      </MuiLink>
    </div>
  );
});

export default ForgotPassword;
