import React from 'react';
import { useOrgsTableColumns } from '../../hooks';
import { useInfinitePaginationWithRef } from '../../../../eliot-components/src/InfiniteTable/hooks';
import OrgsTable from '../OrgsTable/OrgsTable';
import { IOrgsTableDesktopProps, Org } from '../../types';

const DesktopLayout: React.FC<IOrgsTableDesktopProps> = ({
  orgs,
  sortState,
  updateSortState,
}) => {
  const tableColumns = useOrgsTableColumns(sortState, updateSortState);
  const [data, nextPage, hasMore, container] = useInfinitePaginationWithRef<
    Org,
    HTMLDivElement
  >(orgs);

  return (
    <OrgsTable
      data={data}
      nextPage={nextPage}
      hasMore={hasMore}
      container={container}
      tableColumns={tableColumns}
      dataName="organization"
      getKeyValue={(d) => d.id}
      dataToCount={orgs}
      sortState={sortState}
    />
  );
};

export default DesktopLayout;
