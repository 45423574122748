import Auth from '@aws-amplify/auth';
import { v4 as uuid } from 'uuid';

export type FetchFn = (
  input: string,
  init?: RequestInit,
  updateCache?: boolean
) => Promise<Response>;

interface ErrorResponse {
  message?: string;
}

export const fetchWithAuth: FetchFn = async (url, init) => {
  let modifiedInit = init;
  if (!modifiedInit) {
    modifiedInit = {
      headers: new Headers(),
    };
  }
  if (!modifiedInit.headers) {
    modifiedInit.headers = new Headers();
  }
  modifiedInit.headers = new Headers(modifiedInit.headers);

  modifiedInit.headers.append('X-Request-Id', uuid());

  let sess = null;
  try {
    sess = await Auth.currentSession();
  } catch (err) {
    await Auth.signOut();
    localStorage.clear();
    if (window.location.replace) {
      window.location.replace('/login');
    }
    throw new Error(err);
  }

  if (sess === null) {
    throw new Error('Error getting session');
  }

  const authHeader = `Bearer ${sess.getIdToken().getJwtToken()}`;
  modifiedInit.headers.append('Authorization', authHeader);

  const resp = await fetch(url, modifiedInit);
  if (!resp.ok) {
    if (resp.status === 401) {
      Auth.signOut();
      localStorage.clear();
      window.location.replace('/login');
      throw Error('Unauthorized');
    }
    if (init?.method === 'get' || init?.method === undefined) {
      let message = 'Internal error';
      if (resp.status === 404) {
        message = 'Page Not Found';
      }
      window.location.replace(`/error?error=${message}`);
    }
    const jsonResp: ErrorResponse = await resp.json();
    throw Error(jsonResp.message || 'Network error');
  }

  return resp;
};
