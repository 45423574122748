import {
  createApi,
  fetchBaseQuery,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react';
import { FetchFn, fetchWithAuth } from '../fetch';
import { AuthRoles, AuthUser } from '../contexts/AuthProvider/types';
import { User, UserOrg } from '../pages/Users/types';
import { Org } from '../pages/Orgs/types';
import { TagDescription } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { Config } from '../eliot-components/src/ConfigProvider/types';

interface BaseApiArg {
  apiBaseURL: string;
}

export interface ResendActivationEmailArg extends BaseApiArg {
  userID: string;
}

export interface DeleteUserRolesArg extends BaseApiArg {
  userID: string;
  orgID: string;
  roles: string[];
}

export interface AddUserGlobalRolesArg extends BaseApiArg {
  userID: string;
  roles: string[];
}

export interface CreateOrgArg extends BaseApiArg {
  name: string;
  restricted?: boolean;
  restrictedOpts?: {
    maxUsers: number;
    maxWebhooks: number;
  };
}

export interface DeleteOrgArg extends BaseApiArg {
  id: string;
}

export interface RemoveAllOrgUsersArg extends BaseApiArg {
  id: string;
  userIDs: string[];
}

export interface CreateUserArg extends BaseApiArg {
  user: {
    email: string;
    givenName: string;
    familyName: string;
    orgs: UserOrg[];
  };
}

export interface UpdateOrgArg extends BaseApiArg {
  org: Org;
}

export interface UpdateUserArg extends BaseApiArg {
  id: string;
  data: {
    canCreateCare?: boolean;
    deactivated?: boolean;
    givenName?: string;
    familyName?: string;
    globalRolesModeEnabled?: boolean;
  };
}

interface MessageResponse {
  message: string;
}

export interface GetUserArg extends BaseApiArg {
  id: string;
  token?: { [id: string]: any } | null;
}

export interface GetUsersArg extends BaseApiArg {
  roleFilter?: string[];
  globalFilter?: string[];
  verifiedFilter?: boolean;
}

export interface GetOrgArg extends BaseApiArg {
  id: string;
}

async function requestWrapper<T>(
  url: string,
  reqOptions: RequestInit = {},
  customError?: string,
  fetchFn: FetchFn = fetchWithAuth
): Promise<any> {
  try {
    const resp = await fetchFn(url, reqOptions);
    if (resp.status === 204 || resp.status === 404) return { data: {} };
    return resp.json().then((data: T) => ({ data }));
  } catch (e) {
    return {
      error: customError ? customError : e,
    };
  }
}

const provideTags = (
  defaultTags: TagDescription<'Users' | 'Orgs'>[],
  error: FetchBaseQueryError | undefined,
  errorTags: TagDescription<'Users' | 'Orgs'>[] = []
): TagDescription<'Users' | 'Orgs'>[] => {
  return error ? errorTags : defaultTags;
};

export const ApiSlice = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    fetchFn: (input, init) => {
      //@ts-ignore
      return fetchWithAuth(input.url, init);
    },
  }),
  tagTypes: ['Users', 'Orgs'],
  endpoints: (builder) => ({
    // QUERIES
    getConfig: builder.query<Config, null>({
      queryFn: async () => {
        return await requestWrapper<Config>(`/config.json`, {}, '', fetch);
      },
    }),
    getUsers: builder.query<User[], GetUsersArg>({
      queryFn: async ({
        apiBaseURL,
        roleFilter,
        verifiedFilter,
        globalFilter,
      }) => {
        const searchQuery = new URLSearchParams({});
        if (roleFilter) {
          roleFilter.forEach((f) => searchQuery.append('roles', f));
        }
        if (globalFilter) {
          globalFilter.forEach((f) => searchQuery.append('globalRoles', f));
        }
        if (verifiedFilter !== undefined) {
          searchQuery.append('verified', verifiedFilter.toString());
        }
        const { data: respJSON, ...resp } = await requestWrapper<User[]>(
          `${apiBaseURL}/v1/users?${searchQuery.toString()}`,
          {
            headers: { Accept: 'application/json' },
          }
        );
        if (respJSON) {
          return {
            data: respJSON.sort((a: User, b: User) =>
              a.name.toLowerCase().localeCompare(b.name.toLowerCase())
            ),
          };
        }

        return resp;
      },
      providesTags: (result, error, arg) =>
        result
          ? [
              ...result.map((u) => ({ type: 'Users' as const, id: u.id })),
              'Users',
            ]
          : ['Users'],
    }),
    getUser: builder.query<AuthUser | undefined, GetUserArg>({
      queryFn: async ({ apiBaseURL, id, token }) => {
        if (token) {
          const { data: respJSON } = await requestWrapper<AuthUser>(
            `${apiBaseURL}/v1/users/${encodeURIComponent(id)}`,
            {
              headers: { Accept: 'application/json' },
            }
          );
          const makeOrgs = (orgs: UserOrg[]) => {
            return orgs.map((v) => {
              if (!v.roles) {
                v.roles = [];
              }
              return v;
            });
          };
          let usr: AuthUser = { email: '', id };

          if (!!respJSON) {
            usr = {
              id: token.sub,
              email: respJSON.email,
              givenName: respJSON.givenName,
              nickname: token.nickname,
              orgs: makeOrgs(respJSON.orgs ? respJSON.orgs : []),
              globalRoles: respJSON.globalRoles,
              canCreateCare: respJSON.canCreateCare,
              needResetPassword: respJSON.needResetPassword,
              globalRolesModeEnabled: respJSON.globalRolesModeEnabled,
            };
          }

          return { data: usr };
        }
        return { data: undefined };
      },
      providesTags: (result, error, arg) =>
        result ? [{ type: 'Users' as const, id: arg.id }, 'Users'] : ['Users'],
    }),
    getUserDetail: builder.query<User, GetUserArg>({
      queryFn: async ({ apiBaseURL, id }) => {
        return await requestWrapper<User>(
          `${apiBaseURL}/v1/users/${encodeURIComponent(id)}`,
          {
            headers: { Accept: 'application/json' },
          }
        );
      },
      providesTags: (result, error, arg) =>
        result ? [{ type: 'Users' as const, id: arg.id }, 'Users'] : ['Users'],
    }),
    getUserRoles: builder.query<AuthRoles, BaseApiArg>({
      queryFn: async ({ apiBaseURL }) => {
        return await requestWrapper<AuthRoles>(`${apiBaseURL}/v1/roles`, {
          headers: { Accept: 'application/json' },
        });
      },
      providesTags: ['Users'],
    }),
    getOrgs: builder.query<Org[], BaseApiArg>({
      queryFn: async ({ apiBaseURL }) => {
        return await requestWrapper<Org[]>(`${apiBaseURL}/v1/orgs`, {
          headers: { Accept: 'application/json' },
        });
      },
      providesTags: (result, error, arg) =>
        result
          ? [
              ...result.map((u) => ({ type: 'Orgs' as const, id: u.id })),
              'Orgs',
            ]
          : ['Orgs'],
    }),
    getOrg: builder.query<Org, GetOrgArg>({
      queryFn: async ({ apiBaseURL, id }) => {
        return await requestWrapper<Org>(`${apiBaseURL}/v1/orgs/${id}`, {
          headers: { Accept: 'application/json' },
        });
      },
      providesTags: (result, error, arg) =>
        result ? [{ type: 'Orgs' as const, id: arg.id }, 'Orgs'] : ['Orgs'],
    }),
    // MUTATIONS
    createUser: builder.mutation<MessageResponse, CreateUserArg>({
      queryFn: async ({ apiBaseURL, user }) => {
        return await requestWrapper<MessageResponse>(`${apiBaseURL}/v1/users`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(user),
        });
      },
      invalidatesTags: (result, error, arg) =>
        provideTags(['Users', 'Orgs'], error),
    }),
    createOrg: builder.mutation<MessageResponse, CreateOrgArg>({
      queryFn: async ({ apiBaseURL, name, restricted, restrictedOpts }) => {
        return await requestWrapper<MessageResponse>(`${apiBaseURL}/v1/orgs`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            name,
            canCreateCare: false,
            restricted,
            restrictedOpts,
          }),
        });
      },
      invalidatesTags: (result, error, arg) =>
        provideTags(['Users', 'Orgs'], error),
    }),
    deleteOrg: builder.mutation<MessageResponse, DeleteOrgArg>({
      queryFn: async ({ apiBaseURL, id }) => {
        return await requestWrapper<MessageResponse>(
          `${apiBaseURL}/v1/orgs/${id}`,
          {
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );
      },
      invalidatesTags: (result, error, arg) =>
        provideTags(['Users', 'Orgs'], error),
    }),
    removeAllOrgUsers: builder.mutation<MessageResponse, RemoveAllOrgUsersArg>({
      queryFn: async ({ apiBaseURL, id, userIDs }) => {
        return await requestWrapper<MessageResponse>(
          `${apiBaseURL}/v1/orgs/${id}/remove-users`,
          {
            method: 'POST',
            headers: { Accept: 'application/json' },
            body: JSON.stringify({ userIDs }),
          }
        );
      },
      invalidatesTags: (result, error, arg) =>
        provideTags(['Users', { type: 'Orgs', id: arg.id }], error),
    }),
    updateOrg: builder.mutation<MessageResponse, UpdateOrgArg>({
      queryFn: async ({ apiBaseURL, org }) => {
        return await requestWrapper<MessageResponse>(
          `${apiBaseURL}/v1/orgs/${org.id}`,
          {
            method: 'PATCH',
            headers: { Accept: 'application/json' },
            body: JSON.stringify(org),
          }
        );
      },
      invalidatesTags: (result, error, arg) =>
        provideTags(['Users', { type: 'Orgs', id: arg.org.id }], error),
    }),
    updateUser: builder.mutation<MessageResponse, UpdateUserArg>({
      queryFn: async ({ apiBaseURL, id, data }) => {
        return await requestWrapper<MessageResponse>(
          `${apiBaseURL}/v1/users/${id}`,
          {
            method: 'PATCH',
            headers: { Accept: 'application/json' },
            body: JSON.stringify({ ...data }),
          }
        );
      },
      invalidatesTags: (result, error, arg) =>
        provideTags([{ type: 'Users', id: arg.id }], error),
    }),
    resendActivationEmail: builder.mutation<
      MessageResponse,
      ResendActivationEmailArg
    >({
      queryFn: async ({ apiBaseURL, userID }) => {
        return await requestWrapper<MessageResponse>(
          `${apiBaseURL}/v1/users/` + userID + '/resend-user-password',
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );
      },
      invalidatesTags: (result, error, arg) =>
        provideTags([{ type: 'Users', id: arg.userID }], error),
    }),
    deleteUser: builder.mutation<MessageResponse, ResendActivationEmailArg>({
      queryFn: async ({ apiBaseURL, userID }) => {
        return await requestWrapper<MessageResponse>(
          `${apiBaseURL}/v1/users/${userID}`,
          {
            method: 'DELETE',
            headers: { Accept: 'application/json' },
          }
        );
      },
      invalidatesTags: (result, error, arg) => provideTags(['Users'], error),
    }),
    addUserRoles: builder.mutation<MessageResponse, DeleteUserRolesArg>({
      queryFn: async ({ apiBaseURL, userID, orgID, roles }) => {
        return await requestWrapper<MessageResponse>(
          `${apiBaseURL}/v1/orgs/${orgID}/add-roles`,
          {
            method: 'POST',
            headers: { Accept: 'application/json' },
            body: JSON.stringify({ userID, roles }),
          }
        );
      },
      invalidatesTags: (result, error, arg) =>
        provideTags(
          [
            { type: 'Users', id: arg.userID },
            { type: 'Orgs', id: arg.orgID },
          ],
          error
        ),
    }),
    addUserGlobalRoles: builder.mutation<
      MessageResponse,
      AddUserGlobalRolesArg
    >({
      queryFn: async ({ apiBaseURL, userID, roles }) => {
        return await requestWrapper<MessageResponse>(
          `${apiBaseURL}/v1/users/add-global-roles`,
          {
            method: 'POST',
            headers: { Accept: 'application/json' },
            body: JSON.stringify({ userID, roles }),
          }
        );
      },
      invalidatesTags: (result, error, arg) =>
        provideTags([{ type: 'Users', id: arg.userID }], error),
    }),
    deleteUserRoles: builder.mutation<MessageResponse, DeleteUserRolesArg>({
      queryFn: async ({ apiBaseURL, userID, orgID, roles }) => {
        return await requestWrapper<MessageResponse>(
          `${apiBaseURL}/v1/orgs/${orgID}/remove-roles`,
          {
            method: 'POST',
            headers: { Accept: 'application/json' },
            body: JSON.stringify({ userID, roles }),
          }
        );
      },
      invalidatesTags: (result, error, arg) =>
        provideTags(
          [
            { type: 'Users', id: arg.userID },
            { type: 'Orgs', id: arg.orgID },
          ],
          error
        ),
    }),
    deleteUserGlobalRoles: builder.mutation<
      MessageResponse,
      AddUserGlobalRolesArg
    >({
      queryFn: async ({ apiBaseURL, userID, roles }) => {
        return await requestWrapper<MessageResponse>(
          `${apiBaseURL}/v1/users/remove-global-roles`,
          {
            method: 'POST',
            headers: { Accept: 'application/json' },
            body: JSON.stringify({ userID, roles }),
          }
        );
      },
      invalidatesTags: (result, error, arg) =>
        provideTags([{ type: 'Users', id: arg.userID }], error),
    }),
  }),
});

export const {
  // QUERIES
  useGetUserQuery,
  useGetUserDetailQuery,
  useGetUserRolesQuery,
  useGetUsersQuery,
  useGetOrgsQuery,
  useGetOrgQuery,
  useGetConfigQuery,
  // MUTATIONS
  useCreateOrgMutation,
  useDeleteOrgMutation,
  useUpdateOrgMutation,
  useResendActivationEmailMutation,
  useUpdateUserMutation,
  useDeleteUserRolesMutation,
  useDeleteUserMutation,
  useDeleteUserGlobalRolesMutation,
  useAddUserGlobalRolesMutation,
  useAddUserRolesMutation,
  useCreateUserMutation,
  useRemoveAllOrgUsersMutation,
} = ApiSlice;
