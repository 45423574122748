import { TableCell, TableRow, Theme, withStyles } from '@material-ui/core';
import React, { memo } from 'react';
import { getValueByPropName } from '../utils/helpers';

export const StyledTableRow = withStyles((theme: Theme) => ({
  root: {
    '& td': {
      padding: theme.spacing(0, 1),
      '&.noPadding': {
        padding: '0px 0px',
      },
    },
    '& th': {
      padding: theme.spacing(0, 1),
      '&.noPadding': {
        padding: '0px 0px',
      },
    },
  },
}))(TableRow);

export const InfiniteTableRow = memo(
  ({
    entity,
    tableColumns,
    getKeyValue,
  }: {
    entity: any;
    tableColumns: any[];
    getKeyValue: (entity: any) => string;
  }) => (
    <StyledTableRow key={getKeyValue(entity)}>
      {tableColumns.map(column => {
        let className = column.noPadding ? 'noPadding' : '';

        if (column.className) {
          className += ` ${column.className}`;
        }
        return (
          <TableCell
            className={className}
            key={getKeyValue(entity) + column.name}
                data-testid={column.name}
          >
            {!!column.cell
              ? column.cell(entity)
              : getValueByPropName(entity, column.name)}
          </TableCell>
        );
      })}
    </StyledTableRow>
  ),
  (prevProps, nextProps) => {
    if (
      prevProps.entity === nextProps.entity &&
      prevProps.tableColumns === nextProps.tableColumns
    ) {
      return true; // Component will use cached value and not re-render
    }
    return false; // Component will re-render
  }
);
