export const wrapperMessageHandler = async (config) => {
  const c = await config;

  return function(event) {
    if (event.data.action === 'save') {
      if (event.data.key === 'all') {
        Object.keys(event.data.value).forEach(key => {
          const newKey = key.replace(
            /CognitoIdentityServiceProvider\.([A-z0-9])+\./,
            `CognitoIdentityServiceProvider.${c.userPoolWebClientId}.`
          );

          localStorage.setItem(newKey, event.data.value[key]);
        });
      }
    } else if (event.data.action === 'clear') {
      localStorage.clear();
    }
  };
};

export async function initSessionManagement() {
  let config
  try {
    config = JSON.parse(sessionStorage.getItem('config'))
  } catch(e) {
    console.warn(e)
  }

  if (!config) {
    config = await fetch('/config.json', {
      headers: {Accept: 'application/json'},
    });
    config = await config.json()
    if (sessionStorage) sessionStorage.setItem('config', JSON.stringify(config))
  }

  const handler = await wrapperMessageHandler(config);

  window.addEventListener('message', handler, false);
}
