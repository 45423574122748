import React, { useMemo } from 'react';
import { Org, OrgsSortBy } from './types';
import { SortOption, SortState } from '../../utils/sort';
import { TableColumn } from '../../eliot-components/src/InfiniteTable/types';
import { Grid } from '@material-ui/core';
import DetailCell from './components/DetailCell/DetailCell';

export const useOrgsTableColumns = (
  sortState: SortState<Org>,
  updateSortState: React.Dispatch<SortOption<OrgsSortBy>>
): TableColumn<Org>[] => {
  const columns = useMemo(() => {
    const tableColumns: TableColumn<Org>[] = [];

    tableColumns.push({
      header: 'Name',
      name: 'name',
      width: 'auto',
      sortState: sortState.name,
      onHeaderClick: () => updateSortState({ sortBy: OrgsSortBy.NAME }),
    });

    tableColumns.push({
      name: '',
      header: '',
      cell: (org: Org) => (
        <Grid container justifyContent="flex-end" style={{ height: '69px' }}>
          <DetailCell org={org} />
        </Grid>
      ),
      width: 'auto',
    });

    return tableColumns;
  }, [sortState, updateSortState]);

  return columns;
};
