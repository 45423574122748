import Visible from '../../../../components/Visible/Visible';
import {
  hasOneOfGlobalRoles,
  hasRoleInAnyUsersOrg,
  Role,
} from '../../../../contexts/AuthProvider/types';
import React, { FunctionComponent } from 'react';
import { useAuthUser } from '../../../../contexts/AuthProvider/hooks';
import { User } from '../../types';
import MedTooltip from '../../../../components/MedTooltip/MedTooltip';
import RefreshIcon from '@material-ui/icons/Refresh';
import { OrgUser } from '../../../Orgs/types';
import Chip from '@material-ui/core/Chip';
import { makeStyles, Theme } from '@material-ui/core';

interface Props {
  readonly user: User | OrgUser;
  readonly setSelectedUserID: Function;
  readonly setIsConfirmationDialogOpen: Function;
}

const useStyles = makeStyles((theme: Theme) => ({
  primaryColor: {
    color: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.primary.dark,
    },
  },
}));

const VerificationStatus: FunctionComponent<Props> = ({
  user,
  setSelectedUserID,
  setIsConfirmationDialogOpen,
}) => {
  const authUser = useAuthUser();
  const classes = useStyles();

  const shouldDisableResendVerificationMailButton =
    !hasOneOfGlobalRoles(authUser, [Role.UserManager]) &&
    !hasRoleInAnyUsersOrg(authUser, [Role.UserManager], authUser.orgs || []);

  const isPermitedToSeeStatus =
    hasOneOfGlobalRoles(authUser, [
      Role.UserManager,
      Role.OrganizationManager,
      Role.UserViewer,
    ]) ||
    hasRoleInAnyUsersOrg(
      authUser,
      [Role.UserManager, Role.OrganizationManager, Role.UserViewer],
      authUser.orgs ? authUser.orgs : []
    );

  const status = user.emailVerified ? 'Verified' : 'Not verified';
  return (
    <Visible when={isPermitedToSeeStatus}>
      <Visible when={!user.emailVerified}>
        <MedTooltip
          shouldRenderWithTooltip={!shouldDisableResendVerificationMailButton}
          title="Resend verification email"
        >
          {shouldDisableResendVerificationMailButton ? (
            <Chip label={status} id="resend-button" data-testid="user-status" />
          ) : (
            <Chip
              label={status}
              clickable={true}
              id="resend-button"
              deleteIcon={
                <RefreshIcon
                  color="primary"
                  data-testid="resend-button"
                  className={classes.primaryColor}
                />
              }
              onDelete={() => {
                setSelectedUserID(user.id);
                setIsConfirmationDialogOpen(true);
              }}
            />
          )}
        </MedTooltip>
      </Visible>
      <Visible when={user.emailVerified}>
        <Chip label={status} />
      </Visible>
    </Visible>
  );
};

export default VerificationStatus;
