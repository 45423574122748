import React, { ReactNode } from 'react';
import { useTheme } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';

interface Props {
  query: Breakpoint | number;
  children: ReactNode;
}

const MediaUp = (props: Props) => {
  const { query, children } = props;
  const theme = useTheme();
  const match = useMediaQuery(theme.breakpoints.up(query));

  if (match) {
    return <>{children}</>;
  }

  return null;
};

const MediaDown = (props: Props) => {
  const { query, children } = props;
  const theme = useTheme();
  const match = useMediaQuery(theme.breakpoints.down(query));
  if (match) {
    return <>{children}</>;
  }

  return null;
};

export { MediaUp, MediaDown };
