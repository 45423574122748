import Auth, { AuthClass } from '@aws-amplify/auth';
import Button from '@material-ui/core/Button';
import { Theme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';
import React, {
  ChangeEvent,
  FormEvent,
  FunctionComponent,
  memo,
  useState,
} from 'react';
import { useSnackbar } from 'notistack';
import { parseErrorMessage } from '../../utils/helpers';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    textAlign: 'center',
  },
  msg: {
    margin: theme.spacing(2),
  },
  formBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  form: {
    maxWidth: '30rem',
    width: '100%',
  },
  confirmation: {
    margin: theme.spacing(5),
  },
}));

interface Props {
  readonly auth?: AuthClass;
  readonly user: any;
  onDone(): void;
}

const SetNewPassword: FunctionComponent<Props> = memo(
  ({ auth = Auth, user, onDone }) => {
    const classes = useStyles();
    const [newPassword, setNewPassword] = useState('');
    const { enqueueSnackbar } = useSnackbar();
    const [newPasswordConfirmation, setNewPasswordConfirmation] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const handleNewPasswordConfirmationChange = (
      event: ChangeEvent<HTMLInputElement>
    ) => {
      setNewPasswordConfirmation(event.target.value);
    };

    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      if (newPassword.length < 8 || newPassword.length < 10) {
        enqueueSnackbar('Minimum password length is 10 characters', {
          variant: 'warning',
        });
        return;
      }
      if (newPassword.length >= 257) {
        enqueueSnackbar('Maximum password length is 256 characters', {
          variant: 'warning',
        });
        return;
      }
      if (newPassword !== newPasswordConfirmation) {
        enqueueSnackbar('New password does not match', { variant: 'warning' });
        return;
      }

      try {
        setIsLoading(true);
        await auth.completeNewPassword(
          user,
          newPassword,
          user.challengeParam.requiredAttributes
        );
        setNewPassword('');
        setNewPasswordConfirmation('');
        onDone();
      } catch (err) {
        if (err.code === 'NotAuthorizedException') {
          enqueueSnackbar('Incorrect password', { variant: 'error' });
          return;
        }
        enqueueSnackbar(parseErrorMessage(err), { variant: 'error' });
      } finally {
        setIsLoading(false);
      }
    };

    return (
      <div className={classes.container}>
        <Typography variant="h6">Please change your password</Typography>
        <div className={classes.formBox}>
          <form onSubmit={handleSubmit} className={classes.form}>
            <TextField
              id="new-password"
              label="New Password"
              type="password"
              fullWidth
              variant="outlined"
              value={newPassword}
              onChange={(e) => {
                setNewPassword(e.target.value);
              }}
              margin="normal"
              required
            />
            <TextField
              id="confirm-new-password"
              label="Confirm New Password"
              type="password"
              fullWidth
              variant="outlined"
              value={newPasswordConfirmation}
              onChange={handleNewPasswordConfirmationChange}
              margin="normal"
              required
            />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              disabled={
                isLoading ||
                newPassword === '' ||
                newPasswordConfirmation === ''
              }
            >
              Submit
            </Button>
          </form>
        </div>
      </div>
    );
  }
);

export default SetNewPassword;
