import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/styles';
import { createRef, FunctionComponent, memo } from 'react';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import AuthProvider from './contexts/AuthProvider/AuthProvider';
import ConfigProvider from './eliot-components/src/ConfigProvider/ConfigProvider';
import ForgotPassword from './eliot-components/src/ForgotPassword/ForgotPassword';
import Login from './eliot-components/src/Login/Login';
import Org from './pages/Org/Org';
import Orgs from './pages/Orgs/Orgs';
import User from './pages/User/User';
import Users from './pages/Users/Users';
import { AppContext } from './AppContext';
import AppState from './AppState';
import MobileDrawer from './Layout/components/MainDrawer/MobileDrawer';
import { useThemeWithDarkMode } from './eliot-components/src/theme';
import { useSwipeableNavigation } from './utils/swipeable';
import { Button } from '@material-ui/core';
import { SnackbarProvider } from 'notistack';
import ErrorPage from './eliot-components/src/Error/ErrorPage';
import Auth from '@aws-amplify/auth';

const SwipeableNavigation = () => {
  useSwipeableNavigation();

  return <></>;
};

const App: FunctionComponent = memo(() => {
  return (
    <>
      <CssBaseline />
      <Router>
        <AuthProvider>
          <MobileDrawer />
          <SwipeableNavigation />
        </AuthProvider>
        <Switch>
          <Route
            path="/error"
            exact
            render={() => (
              <ErrorPage buttonLink="/orgs" buttonText="Go to Home" />
            )}
          />
          <Route path="/" exact render={() => <Redirect to="/orgs" />} />
          <Route path="/login" component={() => <Login appName="Admin" />} />
          <Route path="/forgot-password" component={ForgotPassword} />
          <Route
            path="/orgs"
            exact
            render={() => (
              <AuthProvider>
                <Orgs />
              </AuthProvider>
            )}
          />
          <Route
            path="/orgs/:orgID"
            render={() => (
              <AuthProvider>
                <Org />
              </AuthProvider>
            )}
          />
          <Route
            path="/users"
            exact
            render={() => (
              <AuthProvider>
                <Users />
              </AuthProvider>
            )}
          />
          <Route
            path="/users/:userID"
            render={() => (
              <AuthProvider>
                <User />
              </AuthProvider>
            )}
          />
          <Route
            path="/logout"
            render={async () => {
              try {
                await Auth.signOut({ global: true });
              } catch (e) {
                await Auth.signOut();
              }
              setTimeout(() => {
                window.location.replace('/login');
              });
              return <></>;
            }}
          />
          <Route
            path="*"
            exact={true}
            render={() => (
              <ErrorPage
                error={'Page Not Found'}
                buttonLink="/orgs"
                buttonText="Go to Home"
              />
            )}
          />
        </Switch>
      </Router>
    </>
  );
});

const AppWithContexts = () => {
  const themeState = useThemeWithDarkMode();
  const notistackRef = createRef<SnackbarProvider>();
  const onClickDismiss = (key: any) => () => {
    if (notistackRef && notistackRef.current) {
      //@ts-ignore
      notistackRef.current.closeSnackbar(key);
    }
  };

  return (
    <ThemeProvider theme={themeState.theme}>
      <SnackbarProvider
        maxSnack={3}
        preventDuplicate={true}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        ref={notistackRef}
        action={(key) => (
          <Button onClick={onClickDismiss(key)} style={{ color: 'white' }}>
            Dismiss
          </Button>
        )}
      >
        <AppContext
          state={AppState}
          toggleDarkMode={themeState.toggleDarkMode}
          isDarkModeActive={themeState.isDarkModeActive}
        >
          <ConfigProvider>
            <App />
          </ConfigProvider>
        </AppContext>
      </SnackbarProvider>
    </ThemeProvider>
  );
};

export default AppWithContexts;
