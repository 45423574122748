import { makeStyles } from '@material-ui/styles';
import { isDarkTheme } from '../../../../eliot-components/src/theme';
import { Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    height: 'calc(100vh - 152px)',
    overflow: 'auto',
  },
  summary: {
    height: '56px',
    borderTop: '1px',
    borderColor: theme.palette.text.primary,
    backgroundColor: isDarkTheme(theme)
      ? theme.palette.secondary.main
      : '#eeeeee',
  },
  summaryItem: {
    padding: theme.spacing(0, 10),
  },
}));
