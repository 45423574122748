import { Theme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import RemoveIcon from '@material-ui/icons/RemoveCircleOutline';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';
import React, { FunctionComponent, memo, useEffect, useState } from 'react';
import { useParams } from 'react-router';

import {
  ALL_ROLES,
  COMBINATED_ROLES,
  DescriptiveCombinatedRole,
  DescriptiveRole,
  hasOneOfGlobalRoles,
  hasOneOfOrgRoles,
  hasRoleInAnyUsersOrg,
  hasUserOrgRoles,
  Role,
} from '../../contexts/AuthProvider/types';
import { useConfig } from '../../eliot-components/src/ConfigProvider/hooks';
import Layout from '../../Layout/Layout';
import { useUser } from './hooks';
import { useAuthUser } from '../../contexts/AuthProvider/hooks';
import Page from '../../Layout/components/Page/Page';
import Grid from '@material-ui/core/Grid';
import ActionsMenu from './components/ActionMenu/ActionsMenu';
import ConfirmationDialog from '../../eliot-components/src/ConfirmationDialog/ConfirmationDialog';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import VerificationStatus from './components/VerificationStatus/VerificationStatus';
import { MediaDown, MediaUp } from '../../components/MediaQueries/MediaQueries';
import { InfoOutlined } from '@material-ui/icons';
import { UserOrg } from '../Users/types';
import MedTooltip from '../../components/MedTooltip/MedTooltip';
import { Chip } from '@material-ui/core';
import { isDarkTheme } from '../../eliot-components/src/theme';
import { useHistory } from 'react-router-dom';
import Auth from '@aws-amplify/auth';
import {
  useDeleteUserGlobalRolesMutation,
  useDeleteUserMutation,
  useDeleteUserRolesMutation,
  useGetUserDetailQuery,
  useResendActivationEmailMutation,
  useUpdateUserMutation,
} from '../../store/api-slice';
import { useSnackbar } from 'notistack';
import { parseErrorMessage } from '../../eliot-components/src/utils/helpers';
import {
  resolveCombinatedRoles,
  unpackCombinatedRoles,
} from '../../components/RolesTable/RolesTable';

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    marginLeft: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(0, 2, 0, 2),
      ...theme.typography.h5,
    },
  },
  subtitle: {
    marginLeft: theme.spacing(2),
  },
  orgTable: {
    marginBottom: theme.spacing(4),
    tableLayout: 'fixed',

    [theme.breakpoints.down('xs')]: {
      display: 'block',
      marginBottom: theme.spacing(2),
      '& th': {
        border: 'none',
      },
      '& tbody': {
        display: 'block',
        '& tr': {
          display: 'flex',
          width: '100%',
          borderBottom: '1px solid ' + theme.palette.divider,
          justifyContent: 'space-between',
          alignItems: 'center',

          '& td': {
            padding: '10px 0 10px 10px',
            borderBottom: 'none',
            '&:first-child': {
              fontWeight: theme.typography.fontWeightBold,
            },
            '&:nth-child(2)': {
              paddingRight: 0,
              fontSize: theme.typography.caption.fontSize,
            },
            '&:nth-child(3)': {
              '& button': {
                paddingTop: 0,
                paddingBottom: 0,
              },
            },
          },
        },
        boxShadow: theme.shadows[1],
        borderRadius: theme.shape.borderRadius,
        backgroundColor: theme.palette.background.paper,
        overflow: 'hidden',
      },
    },
  },
  section: {
    paddingTop: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(4),
      '& button': {
        padding: '10px',
      },
    },
  },
  sectionDisabled: {
    paddingTop: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(2),
    },
    opacity: 0.6,
  },
  global: {
    padding: theme.spacing(0, 0, 0, 0),
    '& table': {
      marginBottom: 0,
    },
  },
  noRoles: {
    padding: theme.spacing(4, 2, 4, 2),
    backgroundColor: isDarkTheme(theme)
      ? theme.palette.grey[900]
      : theme.palette.grey[100],
    borderRadius: theme.shape.borderRadius,
  },
  orgRoles: {
    padding: theme.spacing(0, 0, 0, 0),
  },
  extraPadding: {
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.mixins.toolbar.minHeight,
      minWidth: '100%',
    },
  },
  actions: {
    textAlign: 'right',
    float: 'right',
    [theme.breakpoints.down('xs')]: {
      position: 'fixed',
      top: theme.mixins.toolbar.minHeight,
      left: 0,
      'z-index': theme.zIndex.drawer + 1,
      minHeight: theme.mixins.toolbar.minHeight,
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      backgroundColor: theme.palette.primary.main,
      boxShadow: theme.shadows[4],
      minWidth: '100%',
      '& button': {
        color: 'white',
        marginRight: theme.spacing(1),
      },
    },
  },
  formEdit: {
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(2),
    '& .MuiInput-formControl': {
      marginRight: theme.spacing(2),
    },
  },
  emtpyCell: {
    marginTop: 12,
    marginBottom: 12,
    paddingTop: 12,
    paddingBottom: 12,
  },
  infoClass: {
    verticalAlign: 'middle',
    marginRight: theme.spacing(1),
  },
  deactivatedChip: {
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(4),
    },
  },
}));

interface selectedOrg {
  org: string | null;
  role: string[] | null;
}

const User: FunctionComponent = memo(() => {
  const classes = useStyles();
  const { userID } = useParams<{ userID: string }>();
  const { apiBaseURL } = useConfig();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [resendActivationEmail] = useResendActivationEmailMutation();
  const [updateUser] = useUpdateUserMutation();
  const [removeUser, { isLoading: isLoadingDelete, isSuccess }] =
    useDeleteUserMutation();
  const {
    data: user,
    isFetching: isLoading,
    error,
    refetch: fetchUser,
  } = useGetUserDetailQuery(
    { apiBaseURL, id: userID },
    { skip: isLoadingDelete || isSuccess }
  );
  const [removeUserRole] = useDeleteUserRolesMutation();
  const [removeUserGlobalRoles] = useDeleteUserGlobalRolesMutation();

  if (error) {
    enqueueSnackbar(parseErrorMessage(error), { variant: 'error' });
  }

  const {
    mergeUserRoles,
    mergeUserGlobalRoles,
    removeAllRoles,
    error: errUser,
  } = useUser(apiBaseURL, userID, user);
  if (errUser) {
    enqueueSnackbar(parseErrorMessage(errUser), { variant: 'error' });
  }

  const [edit, setEdit] = useState(false);
  const [givenName, setGivenName] = useState('');
  const [familyName, setFamilyName] = useState('');
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const authUser = useAuthUser();

  const selectedUserId = userID;
  const authUserId = authUser.id;

  const pageName = selectedUserId === authUserId ? 'me' : '';

  const hasGlobalWritingPermissions = hasOneOfGlobalRoles(authUser, [
    Role.OrganizationManager,
    Role.UserManager,
  ]);

  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState(false);
  const [
    isGlobalRoleConfirmationDialogOpen,
    setIsGlobalRoleConfirmationDialogOpen,
  ] = useState(false);
  const sOrg: selectedOrg = {
    org: null,
    role: null,
  };
  const [selectedOrg, setSelectedOrg] = useState(sOrg);

  const [selectedGlobalRole, setSelectedGlobalRole] = useState<string[]>([]);

  const handleUpdate = () => {
    setEdit(false);
    setGivenName(givenName);
    setFamilyName(familyName);
    updateUser({ data: { givenName, familyName }, apiBaseURL, id: userID });
  };

  const handleEdit = (enable: boolean) => {
    setEdit(enable);
    if (!enable && user) {
      setGivenName(user.givenName);
      setFamilyName(user.familyName);
    }
  };

  const handleRemoveAllRoles = async () => {
    if (user) {
      await removeAllRoles(user.id, user.orgs);
      fetchUser();
    }
  };

  const isOrgManager =
    hasUserOrgRoles(authUser, user ? user.orgs : [], [
      Role.OrganizationManager,
    ]) || hasOneOfGlobalRoles(authUser, [Role.OrganizationManager]);

  const handleChangeCare = (active: boolean) => {
    let msg = 'Permission: ';
    if (active) {
      msg += 'on';
    } else {
      msg += 'off';
    }
    updateUser({
      data: { canCreateCare: active },
      apiBaseURL,
      id: userID,
    }).then((resp: any) => {
      if (!resp.error) {
        enqueueSnackbar(msg, { variant: 'info' });
        return;
      }
      enqueueSnackbar(parseErrorMessage(resp.error), { variant: 'error' });
    });
  };

  const handleRemoveUser = () => {
    removeUser({ apiBaseURL, userID }).then((resp: any) => {
      if (resp.error) {
        enqueueSnackbar(parseErrorMessage(resp.error), { variant: 'error' });
      } else {
        if (userID === authUser.id) {
          Auth.signOut().then(() => {
            window.setTimeout(() => {
              window.location.replace('/login');
            });
          });
        } else {
          console.log('redirect');
          history.push('/users?user-removed=true');
        }
      }
    });
  };

  const handleToggleDisableUser = (actualState: boolean) => {
    let msg = 'Login ';
    if (actualState) {
      msg += 'permitted';
    } else {
      msg += 'prohibited';
    }

    updateUser({
      data: { deactivated: !actualState },
      apiBaseURL,
      id: userID,
    }).then((resp: any) => {
      if (!resp.error) {
        enqueueSnackbar(msg, { variant: 'info' });
        return;
      }
      enqueueSnackbar(parseErrorMessage(resp.error), { variant: 'error' });
    });
  };

  useEffect(() => {
    if (user) {
      setGivenName(user.givenName);
      setFamilyName(user.familyName);
    }
  }, [user]);

  const colGroup = (
    <>
      <MediaUp query="md">
        <colgroup>
          <col width="320px" />
          <col width="auto" />
          <col width="85px" />
        </colgroup>
      </MediaUp>
      <MediaDown query="sm">
        <colgroup>
          <col width="160px" />
          <col width="auto" />
          <col width="85px" />
        </colgroup>
      </MediaDown>
    </>
  );

  const isAllowedToChangeByUserManager = (r: Role, o?: UserOrg): boolean => {
    const isGlobalAllowed =
      (r !== Role.OrganizationManager &&
        hasOneOfGlobalRoles(authUser, [Role.UserManager])) ||
      hasOneOfGlobalRoles(authUser, [Role.OrganizationManager]);

    if (o) {
      const isOrgAllowed =
        (r !== Role.OrganizationManager &&
          hasOneOfOrgRoles(authUser, o.id, [Role.UserManager])) ||
        hasOneOfOrgRoles(authUser, o.id, [Role.OrganizationManager]);
      return isGlobalAllowed || isOrgAllowed;
    }

    return isGlobalAllowed;
  };

  const allRoles: (DescriptiveRole | DescriptiveCombinatedRole)[] =
    ALL_ROLES.concat(COMBINATED_ROLES);
  return (
    <Page name={pageName}>
      <Layout isLoading={isLoading}>
        {user && (
          <>
            <section className={classes.section}>
              {user.deactivated && (
                <Chip
                  color="primary"
                  label="User deactivated"
                  className={classes.deactivatedChip}
                />
              )}
              {(hasGlobalWritingPermissions ||
                authUser.id === user.id ||
                hasRoleInAnyUsersOrg(
                  authUser,
                  [Role.UserManager, Role.OrganizationManager],
                  user.orgs
                )) && (
                <Grid item xs={6} className={classes.actions}>
                  <ActionsMenu
                    onMergeOrgRoles={mergeUserRoles}
                    onMergeGlobalRoles={mergeUserGlobalRoles}
                    onChangeCareRole={handleChangeCare}
                    onRemoveUser={handleRemoveUser}
                    onReactivate={() => setIsDialogOpen(true)}
                    onToggleDisableUser={handleToggleDisableUser}
                    currentUser={user}
                  />
                </Grid>
              )}
            </section>
            <section
              className={
                user.deactivated ? classes.sectionDisabled : classes.section
              }
            >
              {!edit && (
                <Typography variant="h4" paragraph className={classes.title}>
                  {givenName || familyName
                    ? givenName + ' ' + familyName
                    : user.name}{' '}
                  {isOrgManager && !user.deactivated && (
                    <Tooltip title="Edit" aria-label="edit">
                      <IconButton
                        data-testid="edit-user-name"
                        onClick={() => handleEdit(true)}
                        aria-label="edit"
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                  {hasOneOfGlobalRoles(authUser, [Role.OrganizationManager]) &&
                    !user.deactivated &&
                    user.orgs.length > 0 && (
                      <Tooltip title="Remove All Roles" aria-label="remove">
                        <IconButton
                          data-testid="remove-all-roles"
                          onClick={() => handleRemoveAllRoles()}
                          aria-label="remove"
                        >
                          <RemoveIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                </Typography>
              )}

              {edit && (
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleUpdate();
                  }}
                  className={classes.formEdit}
                >
                  <TextField
                    margin="dense"
                    variant="outlined"
                    required
                    label="Firstname"
                    defaultValue={givenName}
                    onChange={(e) => {
                      setGivenName(e.target.value);
                    }}
                  />
                  <TextField
                    margin="dense"
                    variant="outlined"
                    required
                    label="Lastname"
                    defaultValue={familyName}
                    onChange={(e) => {
                      setFamilyName(e.target.value);
                    }}
                  />

                  <IconButton type="submit" aria-label="save">
                    <CheckIcon />
                  </IconButton>
                  <IconButton
                    onClick={() => handleEdit(false)}
                    aria-label="close"
                  >
                    <CloseIcon />
                  </IconButton>
                </form>
              )}
              <Typography
                variant="subtitle2"
                paragraph
                color="textSecondary"
                className={classes.subtitle}
              >
                {user.email}
              </Typography>
              <Typography
                variant="subtitle2"
                color="textSecondary"
                className={classes.subtitle}
              >
                <VerificationStatus user={user} />
              </Typography>
            </section>

            {user.globalRoles && user.globalRoles.length > 0 && (
              <section
                className={[
                  user.deactivated ? classes.sectionDisabled : classes.section,
                  classes.global,
                ].join(' ')}
              >
                <Table className={classes.orgTable}>
                  {colGroup}
                  <TableHead>
                    <TableRow>
                      <TableCell colSpan={2}>Global Roles</TableCell>
                      <TableCell />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {resolveCombinatedRoles(user.globalRoles).map((r) => (
                      <TableRow hover={!user.deactivated} key={r.id}>
                        <TableCell colSpan={2}>
                          <MedTooltip
                            enterTouchDelay={0}
                            title={
                              allRoles.find((ar) => ar.id === r.id)!.description
                            }
                            className={classes.infoClass}
                          >
                            <InfoOutlined />
                          </MedTooltip>
                          {allRoles.find((ar) => ar.id === r.id)!.label}
                        </TableCell>
                        {hasGlobalWritingPermissions && (
                          <TableCell align="right">
                            <MedTooltip
                              title="Remove"
                              disableHoverListener={user.deactivated}
                              shouldRenderWithTooltip={isAllowedToChangeByUserManager(
                                r.id
                              )}
                            >
                              <IconButton
                                data-testid={`remove-global-role-${r.id}`}
                                aria-label="Remove"
                                disabled={
                                  !isAllowedToChangeByUserManager(r.id) ||
                                  user.deactivated
                                }
                                onClick={() => {
                                  setSelectedGlobalRole(
                                    unpackCombinatedRoles(r.id)
                                  );
                                  setIsGlobalRoleConfirmationDialogOpen(true);
                                }}
                              >
                                <RemoveCircleOutlineIcon
                                  color={
                                    isAllowedToChangeByUserManager(r.id)
                                      ? 'primary'
                                      : 'secondary'
                                  }
                                />
                              </IconButton>
                            </MedTooltip>
                          </TableCell>
                        )}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </section>
            )}
            <section
              className={
                user.deactivated ? classes.sectionDisabled : classes.section
              }
            >
              {user.orgs && user.orgs.length > 0 ? (
                [...user.orgs]
                  .sort((a, b) => {
                    if (!a.name || !b.name) {
                      return 0;
                    }
                    return a.name
                      .toLowerCase()
                      .localeCompare(b.name.toLowerCase());
                  })
                  .map(
                    (o) =>
                      o.roles &&
                      o.roles.length > 0 && (
                        <Table
                          key={o.id}
                          className={[classes.orgTable, classes.orgRoles].join(
                            ' '
                          )}
                        >
                          {colGroup}
                          <TableHead>
                            <TableRow>
                              <TableCell colSpan={2}>{o.name}</TableCell>
                              <TableCell />
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {resolveCombinatedRoles(o.roles).map(
                              ({ id: r }) => (
                                <TableRow hover={!user.deactivated} key={r}>
                                  <TableCell colSpan={2}>
                                    <MedTooltip
                                      title={
                                        allRoles.find((ar) => ar.id === r)!
                                          .description
                                      }
                                      enterTouchDelay={0}
                                      className={classes.infoClass}
                                    >
                                      <InfoOutlined />
                                    </MedTooltip>
                                    {allRoles.find((ar) => ar.id === r)!.label}
                                  </TableCell>
                                  <TableCell align="right">
                                    {hasOneOfOrgRoles(authUser, o.id, [
                                      Role.UserManager,
                                      Role.OrganizationManager,
                                    ]) &&
                                    (o.roles.length > 1 ||
                                      hasOneOfGlobalRoles(authUser, [
                                        Role.UserManager,
                                        Role.OrganizationManager,
                                      ])) ? (
                                      <MedTooltip
                                        title="Remove"
                                        disableHoverListener={user.deactivated}
                                        shouldRenderWithTooltip={isAllowedToChangeByUserManager(
                                          r,
                                          o
                                        )}
                                      >
                                        <IconButton
                                          aria-label="Remove"
                                          data-testid={`remove-${o.id}-role-${r}`}
                                          disabled={
                                            !isAllowedToChangeByUserManager(
                                              r,
                                              o
                                            ) || user.deactivated
                                          }
                                          onClick={() => {
                                            setSelectedOrg({
                                              org: o.id,
                                              role: unpackCombinatedRoles(r),
                                            });
                                            setIsConfirmationDialogOpen(true);
                                          }}
                                        >
                                          <RemoveCircleOutlineIcon
                                            color={
                                              isAllowedToChangeByUserManager(
                                                r,
                                                o
                                              )
                                                ? 'primary'
                                                : 'secondary'
                                            }
                                          />
                                        </IconButton>
                                      </MedTooltip>
                                    ) : (
                                      <div className={classes.emtpyCell}></div>
                                    )}
                                  </TableCell>
                                </TableRow>
                              )
                            )}
                          </TableBody>
                        </Table>
                      )
                  )
              ) : (
                <Typography
                  color="textSecondary"
                  className={['medisante-placeholder', classes.noRoles].join(
                    ' '
                  )}
                >
                  No roles
                </Typography>
              )}
              <ConfirmationDialog
                questionText="Are you sure you want to remove this role from the user?"
                confirmationText="Remove"
                cancelText="Cancel"
                title="Remove Org Role"
                onConfirm={() => {
                  if (selectedOrg.org != null && selectedOrg.role != null) {
                    removeUserRole({
                      orgID: selectedOrg.org,
                      roles: selectedOrg.role,
                      userID,
                      apiBaseURL,
                    }).then((resp: any) => {
                      if (resp.error) {
                        enqueueSnackbar(parseErrorMessage(resp.error), {
                          variant: 'error',
                        });
                      }
                    });
                  }
                }}
                onClose={() => {
                  setIsConfirmationDialogOpen(false);
                }}
                isOpen={isConfirmationDialogOpen}
              />
              <ConfirmationDialog
                questionText="Are you sure you want to remove this role from the user?"
                confirmationText="Remove"
                cancelText="Cancel"
                title="Remove Global Role"
                onConfirm={() => {
                  removeUserGlobalRoles({
                    userID,
                    apiBaseURL,
                    roles: selectedGlobalRole,
                  });
                }}
                onClose={() => {
                  setIsGlobalRoleConfirmationDialogOpen(false);
                }}
                isOpen={isGlobalRoleConfirmationDialogOpen}
              />
              <ConfirmationDialog
                questionText={
                  user.isConfirmed
                    ? 'Are you sure you want to reset password for this user?'
                    : 'Are you sure you want to resend a verification email?'
                }
                confirmationText="Confirm"
                cancelText="Cancel"
                title={
                  user.isConfirmed
                    ? 'Reset User Password'
                    : 'Resend Verification Email'
                }
                onConfirm={() => {
                  resendActivationEmail({ userID, apiBaseURL }).then(
                    (resp: any) => {
                      if (!resp.error) {
                        enqueueSnackbar('Email sent', { variant: 'info' });
                        return;
                      }

                      enqueueSnackbar(parseErrorMessage(resp.error), {
                        variant: 'error',
                      });
                    }
                  );
                }}
                onClose={() => {
                  setIsDialogOpen(false);
                }}
                isOpen={isDialogOpen}
              />
            </section>
          </>
        )}
      </Layout>
    </Page>
  );
});

export default User;
