import Visible from '../../../../components/Visible/Visible';
import {
  hasOneOfGlobalRoles,
  hasRoleInAnyUsersOrg,
  Role,
} from '../../../../contexts/AuthProvider/types';
import React, { FunctionComponent } from 'react';
import { useAuthUser } from '../../../../contexts/AuthProvider/hooks';
import { User } from '../../../Users/types';
import Chip from '@material-ui/core/Chip';

interface Props {
  readonly user: User;
}

const VerificationStatus: FunctionComponent<Props> = ({ user }) => {
  const authUser = useAuthUser();

  const status = user.emailVerified ? 'Verified' : 'Not verified';

  return (
    <Visible
      when={
        hasOneOfGlobalRoles(authUser, [
          Role.OrganizationManager,
          Role.UserManager,
          Role.UserViewer,
        ]) ||
        hasRoleInAnyUsersOrg(
          authUser,
          [Role.OrganizationManager, Role.UserManager, Role.UserViewer],
          user.orgs
        )
      }
    >
      <span>
        <Chip label={status} />
      </span>
    </Visible>
  );
};

export default VerificationStatus;
