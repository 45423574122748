import React, { useCallback, useEffect, useRef, useState } from 'react';

function useInfinitePagination<T, TRef extends HTMLElement>(
  data: T[],
  container: TRef | null
): [T[], () => void, boolean] {
  const getPage = useCallback(
    (page: number) => data && data.slice(page * size, (page + 1) * size),
    [data]
  );
  const size = 25;
  const [pageNum, setPageNum] = useState(0);
  const [showedData, setShowedData] = useState<T[]>(getPage(pageNum));
  const [hasMore, setHasMore] = useState(true);

  const getNextPage = useCallback(() => {
    var promise = new Promise<{ page: any[]; pageNum: number }>(resolve => {
      setTimeout(() => {
        const nextPage = pageNum + 1;
        const page = getPage(nextPage);
        resolve({ page, pageNum: nextPage });
      });
    });
    return promise;
  }, [pageNum, getPage]);

  const goNextPage = useCallback(async () => {
    const { page, pageNum } = await getNextPage();
    const sData = [...showedData, ...page];
    if (sData.length === data.length) setHasMore(false);
    setShowedData(sData);
    setPageNum(pageNum);
  }, [setPageNum, showedData, getNextPage, data]);

  useEffect(() => {
    setShowedData(getPage(0));
    setPageNum(0);
    setHasMore(true);
    if (container && container.scrollTo) container.scrollTo(0, 0);
  }, [setShowedData, setPageNum, getPage, container]);

  return [showedData, goNextPage, hasMore];
}

export function useInfinitePaginationWithRoot<T>(
  data: T[]
): [T[], () => void, boolean] {
  const root = document.documentElement;
  const pagination = useInfinitePagination(data, root);
  return pagination;
}

export function useInfinitePaginationWithRef<T, TRef extends HTMLElement>(
  data: T[]
): [T[], () => void, boolean, React.RefObject<TRef>] {
  const containerRef = useRef<TRef>(null);
  const pagination = useInfinitePagination(data, containerRef.current);
  const result = [...pagination, containerRef] as [
    T[],
    () => void,
    boolean,
    React.RefObject<TRef>
  ];
  return result;
}
