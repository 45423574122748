import {
  Role,
  AuthUser,
  hasOneOfOrgRoles,
} from '../../contexts/AuthProvider/types';
import { SortState, SortOption } from '../../utils/sort';
export interface OrgUser {
  id: string;
  name: string;
  familyName: string;
  givenName: string;
  email: string;
  roles: Role[];
  isConfirmed?: boolean;
  lastLoginTime?: string;
  emailVerified?: boolean;
}

export interface Org {
  readonly id: string;
  readonly name: string;
  readonly users?: OrgUser[];
  readonly demo?: boolean;
  canCreateCare?: boolean;
  deactivated?: boolean;
  restricted?: boolean;
  restrictedOpts?: {
    maxUsers: number;
    maxWebhooks: number;
  };
}

export interface IOrgsTableDesktopProps {
  orgs: Org[];
  sortState: SortState<Org>;
  updateSortState: React.Dispatch<SortOption<OrgsSortBy>>;
}

export interface IOrgsTableMobileProps {
  orgs: Org[];
  sortOptionId: string | undefined;
  updateSortState: React.Dispatch<SortOption<OrgsSortBy>>;
  authUser: AuthUser;
}

export const shouldShowOrgDetails = (org: Org, authUser: AuthUser) =>
  hasOneOfOrgRoles(authUser, org.id, [
    Role.OrganizationManager,
    Role.UserManager,
    Role.UserViewer,
  ]);

export enum OrgsSortBy {
  NAME = 'NAME',
}
