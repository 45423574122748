import React, { useState, useEffect, FunctionComponent } from 'react';
import {
  ALL_ROLES,
  Role,
  DescriptiveRole,
  COMBINATED_ROLES,
  DescriptiveCombinatedRole,
} from '../../contexts/AuthProvider/types';
import { FormControl, Checkbox, Grid, FormLabel } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { useAuthUser } from '../../contexts/AuthProvider/hooks';
import { onlyUnique } from '../../eliot-components/src/utils/helpers';
import Loading from '../../eliot-components/src/Loading/Loading';

interface Props {
  roles: Role[];
  orgID?: string;
  disabled?: boolean;
  setRoles: (roles: Role[]) => void;
  allAvailableRoles: DescriptiveRole[];
}

function RolesSelect(props: Props) {
  const { roles, setRoles, allAvailableRoles, disabled, orgID } = props;
  const [internalRoles, setInternalRoles] = useState<Role[]>(roles);

  let orgRoles: DescriptiveRole[] = ALL_ROLES;
  const authUser = useAuthUser();

  useEffect(() => {
    setInternalRoles(roles);
  }, [roles]);

  let isOrgManager =
    authUser.globalRoles &&
    authUser.globalRoles.indexOf(Role.OrganizationManager) === -1;
  // disable remove org manger if you are not org manger in specific Organization or have global role
  if (
    authUser.orgs &&
    orgID &&
    ((authUser.globalRoles &&
      authUser.globalRoles.indexOf(Role.OrganizationManager) === -1) ||
      !authUser.globalRoles)
  ) {
    const org = authUser.orgs.filter((org) => org.id === orgID);
    if (org.length > 0) {
      if (org[0].roles.indexOf(Role.OrganizationManager) === -1) {
        orgRoles = ALL_ROLES.filter((r) => r.id !== Role.OrganizationManager);
        isOrgManager = false;
      }
    }
  }

  if (isOrgManager) {
    orgRoles = orgRoles.concat(COMBINATED_ROLES);
  }

  const handleChange = (
    r: DescriptiveRole | DescriptiveCombinatedRole,
    isAdd: boolean
  ) => {
    let value: Role[] = internalRoles;
    if (isAdd) {
      // in case of combinated role it will replace existing selection
      if ('roles' in r) {
        value = r.roles;
      } else {
        value.push(r.id);
      }

      // deselect conflicting role
      if (r.conflictingRole) {
        value = value.filter((ir) => r.conflictingRole?.indexOf(ir) === -1);
      }
    } else {
      if ('roles' in r) {
        value = internalRoles.filter((ir) => r.roles.indexOf(ir) === -1);
      } else {
        value = internalRoles.filter((ir) => ir !== r.id);
      }
    }

    value = value.filter(onlyUnique((x, y) => x === y));

    setInternalRoles(value);
    setRoles(value);
  };

  const RoleSelectRow: FunctionComponent<{
    role: DescriptiveRole | DescriptiveCombinatedRole;
    fullWidth?: boolean;
    disabled?: boolean;
  }> = (props) => {
    const { role: r, fullWidth } = props;
    const orgRolesIds = orgRoles.map((r) => r.id);

    return (
      <Grid item lg={fullWidth ? 12 : 6} xs={12} style={{ padding: '16px 0' }}>
        <Checkbox
          size="small"
          disabled={
            'roles' in r
              ? r.roles.filter((r) => orgRolesIds.indexOf(r) !== -1)?.length !==
                r.roles.length
              : orgRoles.length
              ? orgRoles.indexOf(r) === -1
              : false
          }
          onChange={(e, add) => handleChange(r, add)}
          data-testid={`${r.id}-checkbox`}
          checked={
            'roles' in r
              ? r.roles.filter((r) => internalRoles.indexOf(r) !== -1)
                  ?.length === r.roles.length
              : internalRoles.indexOf(r.id) > -1
          }
          value={props.role.id}
          style={{ top: 16 }}
        />
        <Typography variant="subtitle2" display="inline">
          {props.role.label}
        </Typography>

        <div style={{ paddingLeft: 42, paddingRight: 8 }}>
          <Typography variant="caption">{props.role.description}</Typography>
        </div>
      </Grid>
    );
  };

  return (
    <div>
      {orgRoles ? (
        <FormControl
          fullWidth
          margin="dense"
          variant="outlined"
          disabled={disabled}
        >
          <FormLabel disabled={disabled}>Roles</FormLabel>
          {allAvailableRoles.length > 0 ? (
            <Grid container>
              {COMBINATED_ROLES.map((r) => (
                <RoleSelectRow role={r} key={'role-select-' + r.id} />
              ))}
              {allAvailableRoles.map((r) => (
                <RoleSelectRow
                  role={r}
                  fullWidth={!!r.shouldDisplayFullWidth}
                  key={'role-select-' + r.id}
                />
              ))}
            </Grid>
          ) : (
            <Loading />
          )}
        </FormControl>
      ) : null}
    </div>
  );
}

export default RolesSelect;
