export function removeDiacritics() {
  //@ts-ignore
  return this.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}
// eslint-disable-next-line
Object.defineProperty(String.prototype, 'removeDiacritics', {
  value: removeDiacritics,
  writable: true,
  configurable: true,
});
