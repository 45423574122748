import { GropedByStringKey, StringKey } from './types';
import {FetchBaseQueryError} from "@reduxjs/toolkit/query";
import {SerializedError} from "@reduxjs/toolkit";

export const partial = (fn: (...args: any[]) => any, ...partialArgs: any[]) => (
  ...restArgs: any[]
) => {
  const args = [...partialArgs, ...restArgs];
  return fn(...args);
};

export const partialRight = (
  fn: (...args: any[]) => any,
  ...partialArgs: any[]
) => (...restArgs: any[]) => {
  const args = [...restArgs, ...partialArgs.reverse()];
  return fn(...args);
};

/*FILTER HELPERS*/
export const onlyUnique = <T>(
  comparer: (item1: T, item2: T) => boolean = (item1, item2) => item1 === item2
) => (item: T, index: number, self: T[]) => {
  return self.findIndex(current => comparer(item, current)) === index;
};

/**/

export const isNullOrUndefined = (obj: any) =>
  obj === undefined || obj === null;

export const nullifyIfEmptyOrWhitespace = (text: any) =>
  typeof text === 'string' && text.trim() === '' ? null : text;

export const nullifyIfEmptyArray = (obj: any) => {
  if (!!obj && Array.isArray(obj) && obj.length === 0) return null;

  return obj;
};

export const isRenderedInIframe = () => {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
};

export const groupBy = <T, K extends keyof T>(
  collection: T[],
  key: StringKey<T, K>
): GropedByStringKey<T> => {
  return collection.reduce((prev: GropedByStringKey<T>, curr: T) => {
    const newKey = (curr[key as K] as unknown) as string;
    return { ...prev, [newKey]: [...(prev[newKey] || []), curr] };
  }, {});
};

export const getValueByPropName = (obj: any, propName: string) => obj[propName];


export const parseErrorMessage = (error: FetchBaseQueryError | SerializedError | Error | null | undefined) => error && 'message' in error ? error.message : 'An unknown error occurred'
