import {Button, Table, TableBody, TableCell, TableHead} from '@material-ui/core';
import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { getSortArrow, SortState } from '../utils/sort';
import useStyles from './styles';
import { StyledTableRow, InfiniteTableRow } from './TableRow';
import { TableColumn } from './types';

interface Props<T> {
  container: React.RefObject<HTMLDivElement>;
  containerClass?: string;
  loadMore: () => void;
  hasMore: boolean;
  tableColumns: TableColumn<T>[];
  sortState: SortState<T>;
  data: T[];
  getKeyValue: (entity: T) => string;
  useWindow?: boolean
}

function InfiniteTable<T>(props: Props<T>) {
  const {
    container,
    containerClass,
    loadMore,
    hasMore,
    tableColumns,
    sortState,
    data,
    getKeyValue,
    useWindow = false
  } = props;
  const classes = useStyles();

  const columns = tableColumns.filter((column: TableColumn<T>) => !column.hide)

  return (
    <div className={containerClass ? containerClass : ''} ref={container}>
      <InfiniteScroll
        element="div"
        pageStart={0}
        initialLoad={false}
        loadMore={loadMore}
        hasMore={hasMore}
        useWindow={useWindow}
        threshold={300}
      >
        <Table stickyHeader className={classes.table} size="small">
          <colgroup>
            {columns.map((column: TableColumn<T>) => (
              <col key={'col-' + column.name} width={column.width} />
            ))}
          </colgroup>
          <TableHead>
            <StyledTableRow>
              {columns.map((column: TableColumn<T>) => (
                <TableCell
                  key={'header-' + column.name}
                  className={column.noPadding ? 'noPadding' : ''}
                >
                  <div
                    className={classes.sortableCell}
                    onClick={() =>
                      column.onHeaderClick && column.onHeaderClick(column)
                    }
                  >
                    {column.header}

                    {!!column.onHeaderClick
                      ? getSortArrow(column.name as keyof T, sortState, column.sortState)
                      : null}
                  </div>
                </TableCell>
              ))}
            </StyledTableRow>
          </TableHead>
          <TableBody data-testid="table-body">
            {data.map((entity, index) => (
              <InfiniteTableRow
                key={getKeyValue(entity)}
                getKeyValue={getKeyValue}
                entity={entity}
                tableColumns={columns}
              />
            ))}
            { hasMore ? <tr className="loadMore">
              <td colSpan={tableColumns.length} style={{textAlign: 'center'}}>
                <Button onClick={loadMore} color="primary" variant="text">Load more</Button>
              </td>
            </tr> : null }
          </TableBody>
        </Table>
      </InfiniteScroll>
    </div>
  );
}

export default InfiniteTable;
