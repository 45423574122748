import Auth from '@aws-amplify/auth';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { Theme } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import ClearIcon from '@material-ui/icons/Clear';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { makeStyles } from '@material-ui/styles';
import { FunctionComponent, memo, useState } from 'react';
import { useHistory } from 'react-router';
import { useAuthUser } from '../../../contexts/AuthProvider/hooks';
import { useAppContext } from '../../../AppContext';
import MobileMenu from './components/MobileMenu/MobileMenu';
import HamburgerMenu from './components/HamburgerMenu/HamburgerMenu';
import Version from '../Version/Version';
import { Divider, FormControlLabel, Switch } from '@material-ui/core';
import ChangePasswordDialog from '../../../components/ChangePasswordDialog/ChangePasswordDialog';
import LockIcon from '@material-ui/icons/Lock';
import UnlockIcon from '@material-ui/icons/LockOpen';
import { hasUserOrgAnyRole } from '../../../contexts/AuthProvider/types';
import { useSnackbar } from 'notistack';
import { useUpdateUserMutation } from '../../../store/api-slice';
import { useConfig } from '../../../eliot-components/src/ConfigProvider/hooks';

const useStyles = makeStyles((theme: Theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  pullRight: {
    textAlign: 'right',
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  logoButton: {
    paddingLeft: 0,

    '& .MuiButton-label': {
      display: 'flex',
      alignItems: 'baseline',
    },

    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  orgsButton: {
    textTransform: 'none',
  },
  userButton: {
    textTransform: 'none',
    marginRight: theme.spacing(-2),
  },
  logout: {
    flexGrow: 1,
    textAlign: 'right',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  menu: {
    flexGrow: 1,
    textAlign: 'right',
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
}));

const Header: FunctionComponent = memo(() => {
  const history = useHistory();
  const classes = useStyles();
  const authUser = useAuthUser();
  const { enqueueSnackbar } = useSnackbar();

  const [userAnchorEl, setUserAnchorEl] = useState<
    (EventTarget & HTMLElement) | null
  >(null);
  const [isChangePasswordDialogOpen, setIsChangePasswordDialogOpen] =
    useState(false);

  const handleLogout = async () => {
    setUserAnchorEl(null);
    window.location.replace('/logout');
  };

  const handleChangePassword = (isSuccess: boolean, hasError: boolean) => {
    if (isSuccess) {
      enqueueSnackbar('Password successfully changed', { variant: 'success' });
      setIsChangePasswordDialogOpen(false);
    }
  };

  const { state, toggleDarkMode, isDarkModeActive } = useAppContext();
  const { page } = state;
  const handleAPIKeyClipboard = async () => {
    const session = await Auth.currentSession();
    const token = session.getIdToken().getJwtToken();
    navigator.clipboard.writeText('Bearer ' + token);
    enqueueSnackbar('API Key copied to clipboard', { variant: 'info' });
    setUserAnchorEl(null);
  };

  const apiKey = hasUserOrgAnyRole(authUser) ? (
    <span>
      <MenuItem onClick={handleAPIKeyClipboard}>
        <UnlockIcon className={classes.icon} /> API Key
      </MenuItem>
      <Divider />
    </span>
  ) : null;

  const [updateUser] = useUpdateUserMutation();
  const { apiBaseURL } = useConfig();
  const [isLoading, setIsLoading] = useState(false);
  return (
    <AppBar position="fixed" color="secondary" className={classes.appBar}>
      <Toolbar>
        <HamburgerMenu />
        {!state.isEmbedded ? (
          <Button
            size="large"
            className={classes.logoButton}
            color="inherit"
            onClick={() => {
              history.push('/');
            }}
          >
            Medisanté Admin
          </Button>
        ) : null}
        <div className={classes.menu}>
          <MobileMenu page={page} userId={authUser.id} />
        </div>
        <div className={classes.logout}>
          <Button
            size="large"
            color="inherit"
            className={classes.userButton}
            onClick={(e) => {
              setUserAnchorEl(e.currentTarget);
            }}
          >
            {authUser.givenName || authUser.nickname || authUser.email}{' '}
            <KeyboardArrowDownIcon />
          </Button>
          <Menu
            style={{ zIndex: 1202 }}
            anchorEl={userAnchorEl}
            open={Boolean(userAnchorEl)}
            onClose={() => {
              setUserAnchorEl(null);
            }}
          >
            <MenuItem onClick={() => setIsChangePasswordDialogOpen(true)}>
              <LockIcon className={classes.icon} /> Change Password
            </MenuItem>
            <Divider />
            {apiKey}
            <MenuItem onClick={toggleDarkMode}>
              <FormControlLabel
                control={
                  <Switch
                    color="primary"
                    checked={isDarkModeActive}
                    onClick={toggleDarkMode}
                  />
                }
                label="Dark mode"
              />
            </MenuItem>

            {((authUser.globalRoles && authUser.globalRoles.length > 0) ||
              authUser?.globalRolesModeEnabled) && (
              <MenuItem disabled={isLoading}>
                <FormControlLabel
                  control={
                    <Switch
                      color="primary"
                      checked={
                        authUser.globalRoles && authUser.globalRoles.length > 0
                      }
                      onClick={() => {
                        setIsLoading(true);
                        const enabled =
                          authUser.globalRoles &&
                          authUser.globalRoles.length > 0;
                        updateUser({
                          data: { globalRolesModeEnabled: !enabled },
                          apiBaseURL,
                          id: authUser.id,
                        }).then(() => {
                          window.location.reload();
                        });
                      }}
                    />
                  }
                  label="Global mode"
                />
              </MenuItem>
            )}

            <Divider />
            <MenuItem onClick={handleLogout}>
              <ClearIcon className={classes.icon} /> Log Out
            </MenuItem>
            <Divider />
            <Version />
          </Menu>
        </div>
      </Toolbar>
      <ChangePasswordDialog
        onConfirm={handleChangePassword}
        onClose={() => {
          setIsChangePasswordDialogOpen(false);
        }}
        isOpen={isChangePasswordDialogOpen}
      ></ChangePasswordDialog>
    </AppBar>
  );
});

export default Header;
