import React from 'react';
import AuthProvider from '../../../contexts/AuthProvider/AuthProvider';
import MainDrawer from './MainDrawer';
import { MediaDown } from '../../../components/MediaQueries/MediaQueries';
import { useAppContext } from '../../../AppContext';

const MobileDrawer = () => {
  const { state } = useAppContext();
  const { user } = state;

  if (user != null) {
    return (
      <MediaDown query="md">
        <AuthProvider isLoadingVisible={false}>
          <MainDrawer />
        </AuthProvider>
      </MediaDown>
    );
  }

  return null;
};

export default MobileDrawer;
