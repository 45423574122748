import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Theme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import React, { FunctionComponent, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import { useChangePassword } from '../../pages/User/hooks';
import ButtonProgress from '../../eliot-components/src/ButtonProgress/ButtonProgress';
import { useAuthUser } from '../../contexts/AuthProvider/hooks';
import { useConfig } from '../../eliot-components/src/ConfigProvider/hooks';
import { fetchWithAuth } from '../../fetch';
import { Typography } from '@material-ui/core';
import { Warning } from '@material-ui/icons';
import Auth from '@aws-amplify/auth';
import { parseErrorMessage } from '../../eliot-components/src/utils/helpers';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme: Theme) => ({
  mainDialog: {
    '& .MuiDialog-paperWidthSm': {
      maxWidth: theme.spacing(50),
      width: theme.spacing(50),
    },
  },
  dialogContent: {
    '& > *': {
      marginTop: theme.spacing(2),
    },
  },
  actions: {},
  formSubmit: {
    marginTop: theme.spacing(-3),
  },
}));

interface Props {
  isOpen?: boolean;
  onClose(): void;
  onConfirm(isSuccess: boolean, hasError: boolean): void;
}

const ChangePasswordDialog: FunctionComponent<Props> = ({
  onConfirm,
  isOpen = true,
  onClose,
}) => {
  const classes = useStyles();
  const authUser = useAuthUser();
  const { apiBaseURL } = useConfig();
  const { enqueueSnackbar } = useSnackbar();

  const [oldPwd, setOldPwd] = useState('');
  const [newPwd, setNewPwd] = useState('');
  const [newPwdConfirm, setNewPwdConfirm] = useState('');
  const { changePassword, isLoading, error: pwdError } = useChangePassword();

  const handleSubmit = (oldPwd: string, newPwd: string) => {
    if (oldPwd.length < 8 || newPwd.length < 10) {
      enqueueSnackbar('Minimum password length is 10 characters', {
        variant: 'warning',
      });
      return;
    }
    if (newPwd.length >= 257) {
      console.log(newPwd.length, 'max password');
      enqueueSnackbar('Maximum password length is 256 characters', {
        variant: 'warning',
      });
      return;
    }
    if (newPwd !== newPwdConfirm) {
      enqueueSnackbar('New password does not match', { variant: 'warning' });
      return;
    }

    const updateUserFlag = async (): Promise<Error | undefined> => {
      try {
        await fetchWithAuth(`${apiBaseURL}/v1/users/${authUser.id}`, {
          method: 'PATCH',
          headers: { Accept: 'application/json' },
          body: JSON.stringify({ needResetPassword: false }),
        });
      } catch (err) {
        enqueueSnackbar(parseErrorMessage(err), { variant: 'error' });
        return err;
      }
    };

    changePassword(oldPwd, newPwd).then((isSuccess) => {
      if (pwdError && pwdError.message) {
        enqueueSnackbar(pwdError.message, { variant: 'error' });
        return;
      }
      if (!!authUser.needResetPassword) {
        isSuccess = !!isSuccess;
        if (isSuccess) {
          updateUserFlag().then((err) => {
            onConfirm(!!isSuccess, err !== null);
            if (!err) {
              window.location.reload();
            }
          });
        } else {
          onConfirm(isSuccess, false);
        }
      } else {
        isSuccess = !!isSuccess;
        onConfirm(isSuccess, false);
      }
    });
  };

  const handleLogout = async () => {
    try {
      await Auth.signOut({ global: true });
    } catch (e) {
      await Auth.signOut();
    }
    window.setTimeout(() => {
      window.location.replace('/login');
    });
  };

  return (
    <>
      <Dialog
        className={classes.mainDialog}
        aria-labelledby="dialog-title"
        open={isOpen || !!authUser.needResetPassword}
        onClose={() => {
          if (!authUser.needResetPassword) {
            onClose();
          }
        }}
      >
        <DialogTitle id="dialog-title">Change Password</DialogTitle>
        <form
          className={classes.formSubmit}
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit(oldPwd, newPwd);
          }}
        >
          <DialogContent className={classes.dialogContent}>
            {!!authUser.needResetPassword && (
              <Typography color="primary" variant="subtitle2">
                {' '}
                <Warning
                  fontSize="small"
                  style={{ verticalAlign: 'middle' }}
                />{' '}
                Password requirement change. Please change your password.
              </Typography>
            )}
            <TextField
              margin="dense"
              variant="outlined"
              fullWidth
              type="password"
              required
              label="Current password"
              onChange={(e) => {
                setOldPwd(e.target.value);
              }}
            />
            <br />
            <TextField
              margin="dense"
              variant="outlined"
              fullWidth
              type="password"
              required
              label="New password"
              onChange={(e) => {
                setNewPwd(e.target.value);
              }}
            />
            <br />
            <TextField
              fullWidth
              margin="dense"
              variant="outlined"
              type="password"
              required
              label="Confirm new password"
              onChange={(e) => {
                setNewPwdConfirm(e.target.value);
              }}
            />
          </DialogContent>
          <DialogActions classes={{ root: classes.actions }}>
            {!authUser.needResetPassword && (
              <Button
                onClick={() => {
                  onClose();
                }}
              >
                Cancel
              </Button>
            )}
            {!!authUser.needResetPassword && (
              <Button
                onClick={() => {
                  handleLogout();
                }}
              >
                Logout
              </Button>
            )}
            <ButtonProgress
              isLoading={isLoading}
              type="submit"
              variant="contained"
              color="primary"
            >
              Confirm
            </ButtonProgress>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default ChangePasswordDialog;
