import { Theme } from '@material-ui/core';
import {
  createTheme,
  darken,
  responsiveFontSizes,
} from '@material-ui/core/styles';
import { useCallback, useMemo, useState } from 'react';
import { getDarkModeStatus, storeDarkModeStatus } from './utils/darkModeHelper';

const primaryColor_main = '#ff5432';
const contrastText = '#ffffff';
const secondaryColor_main = 'rgb(102, 110, 117)';

export const isDarkTheme = (theme: Theme) => theme.palette.type === 'dark';

const track = '#202022';
const thumb = '#585859';
const active = '#838384';

const track_light = '#F5F5F5';
const thumb_light = '#bcbcbc';
const active_light = '#9e9e9e';

export const getTheme = (isDark: boolean = false) => {
  const theme = createTheme({
    palette: {
      type: isDark ? 'dark' : 'light',
      primary: {
        main: primaryColor_main,
        contrastText: contrastText,
      },
      secondary: {
        main: isDark ? '#424242' : secondaryColor_main,
        contrastText: contrastText,
      },

      contrastThreshold: 6,
      tonalOffset: 0.3,
    },
    typography: {
      fontFamily: 'Poppins',
      fontSize: 16,
    },
  });

  theme.overrides = {
    ...theme.overrides,
    MuiCssBaseline: {
      '@global': {
        body: {
          '&.search-results': {
            backgroundColor: isDarkTheme(theme)
              ? theme.palette.background.default
              : '#dedede',
          },
        },
        ...(theme.palette.type === 'dark'
          ? {
              html: {
                scrollbarColor: `${thumb} ${track}`,
              },
              '*::-webkit-scrollbar': {
                backgroundColor: track,
              },
              '*::-webkit-scrollbar-thumb:focus': {
                backgroundColor: active,
              },
              '*::-webkit-scrollbar-thumb:active': {
                backgroundColor: active,
              },
              '*::-webkit-scrollbar-corner': {
                backgroundColor: track,
              },
              '*::-webkit-scrollbar-thumb': {
                borderRadius: 8,
                backgroundColor: thumb,
                minHeight: 24,
                border: `3px solid ${track}`,
              },
            }
          : {
              html: {
                scrollbarColor: `${thumb_light} ${track_light}`,
              },
              '*::-webkit-scrollbar': {
                backgroundColor: track_light,
              },
              '*::-webkit-scrollbar-thumb:focus': {
                backgroundColor: active_light,
              },
              '*::-webkit-scrollbar-thumb:active': {
                backgroundColor: active_light,
              },
              '*::-webkit-scrollbar-corner': {
                backgroundColor: track_light,
              },
              '*::-webkit-scrollbar-thumb': {
                borderRadius: 8,
                backgroundColor: thumb_light,
                minHeight: 24,
                border: `3px solid ${track_light}`,
              },
            }),
        input: {
          '&:-webkit-autofill': {
            '&::first-line': {
              fontFamily: theme.typography.fontFamily,
              fontSize: theme.typography.body1.fontSize,
            },

            WebkitTextFillColor: theme.palette.text.primary + ' !important ',
            WebkitBoxShadow: '0 0 0 1000px transparent inset !important',
            boxShadow: '0 0 0 1000px transparent inset !important',
            transition: 'background-color 5000s ease-in-out 0s',
          },
        },
      },
    },
    MuiSelect: {
      select: {
        '&:focus': {
          backgroundColor: isDarkTheme(theme)
            ? darken(theme.palette.background.default, 0.3)
            : 'rgba(0,0,0,0.12)',
        },
      },
    },
    MuiInputBase: {
      root: {
        fontSize: theme.typography.body1.fontSize,
        backgroundColor: isDarkTheme(theme)
          ? darken(theme.palette.background.default, 0.2)
          : 'transparent',
        '& input::-webkit-outer-spin-button': {
          WebkitAppearance: 'none',
          margin: '0',
        },
        '& input::-webkit-inner-spin-button': {
          WebkitAppearance: 'none',
          margin: '0',
        },

        '& input[type=number]': {
          MozAppearance: 'textfield',
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderColor: theme.palette.text.primary,
        },
      },
    },
    MuiFormLabel: {
      root: {
        '&.Mui-focused': {
          color: theme.palette.text.primary,
        },
      },
      asterisk: {
        display: 'none',
      },
    },
    MuiInputLabel: {
      shrink: {
        'z-index': '1 !important',
      },
    },
    MuiDialog: {
      paperFullWidth: {
        width: 'calc(100% - 32px)',
      },
      paper: {
        margin: 16,
        width: '100%',
      },
      paperScrollPaper: {
        maxHeight: 'calc(100% - 32px)',
      },
    },
    MuiDialogContent: {
      root: {
        padding: '8px 16px',
      },
    },
    MuiDialogTitle: {
      root: {
        padding: '16px 16px 8px 16px',
      },
    },
    MuiSnackbarContent: {
      root: {
        justifyContent: 'center',
      },
    },
    MuiButton: {
      contained: {
        '&::hover': {
          backgroundColor: theme.palette.primary.dark,
        },
      },
    },
    MuiFormHelperText: {
      root: {
        lineHeight: '1.3em',
        color: theme.palette.text.primary,
      },
    },
    MuiCheckbox: {
      colorSecondary: {
        '&$checked': {
          color: theme.palette.text.secondary,
        },
      },
    },
  };

  return responsiveFontSizes(theme, {
    factor: 6,
  });
};

export interface ThemeState {
  theme: Theme;
  isDarkModeActive: boolean;
  toggleDarkMode: () => void;
}

export const useThemeWithDarkMode = () => {
  const isDarkModeActiveInitial = getDarkModeStatus();
  const [isDarkModeActive, setIsDarkModeActive] = useState(
    isDarkModeActiveInitial
  );
  const theme = useMemo(() => getTheme(isDarkModeActive), [isDarkModeActive]);

  const toggleDarkMode = useCallback(
    event => {
      event.preventDefault();
      event.stopPropagation();

      setIsDarkModeActive(!isDarkModeActive);
      storeDarkModeStatus(!isDarkModeActive);
    },
    [isDarkModeActive]
  );

  const state = useMemo(() => ({ theme, isDarkModeActive, toggleDarkMode }), [
    theme,
    isDarkModeActive,
    toggleDarkMode,
  ]);
  return state;
};
