import { Org, OrgsSortBy } from './types';
import {
  sort,
  Direction,
  SortState,
  SortOption,
  SortOptionSelectItem,
} from '../../utils/sort';

export const orgsSortingReducer = (
  state: SortState<Org>,
  action: SortOption<OrgsSortBy>
): SortState<Org> => {
  const getDirectionByPrevious = (direction?: Direction) => {
    return direction === Direction.ASC ? Direction.DESC : Direction.ASC;
  };

  switch (action.sortBy) {
    case OrgsSortBy.NAME:
      return {
        name: action.direction || getDirectionByPrevious(state.name),
      };
    default:
      return state;
  }
};

export const sortOrgs = (orgs: Org[], sortState: SortState<Org>) =>
  sort(orgs, sortState, {
    name: (org) => org.name,
  });

export const mobileSortOptions: SortOptionSelectItem<OrgsSortBy>[] = [
  {
    value: { sortBy: OrgsSortBy.NAME, direction: Direction.ASC },
    label: 'Name (ascending)',
    id: 'name-asc',
  },
  {
    value: { sortBy: OrgsSortBy.NAME, direction: Direction.DESC },
    label: 'Name (descending)',
    id: 'name-desc',
  },
];
