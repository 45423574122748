import { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useAuthUser } from '../contexts/AuthProvider/hooks';

export const useSwipeable = (
  onSwipeRight = () => {},
  onSwipeLeft = () => {}
) => {
  useEffect(() => {
    let startPositionX = 0;
    let startPositionY = 0;
    let diffX = 0;
    let diffY = 0;
    let timeStart = 0;
    let timeout = 500;
    let swipeTrashold = 20;

    const onTouchStart = (e: any) => {
      startPositionX = e.touches[0].clientX;
      startPositionY = e.touches[0].clientY;
      timeStart = Date.now();
    };
    document.addEventListener('touchstart', onTouchStart);

    const onTouchMove = (e: any) => {
      diffX = startPositionX - e.touches[0].clientX;
      diffY = startPositionY - e.touches[0].clientY;
    };
    document.addEventListener('touchmove', onTouchMove);

    const onTouchEnd = () => {
      if (
        Date.now() - timeStart > timeout ||
        Math.abs(diffX) < swipeTrashold ||
        Math.abs(diffY) > Math.abs(diffX)
      )
        return;

      if (diffX > 0) {
        onSwipeLeft();
      } else if (diffX < 0) {
        onSwipeRight();
      }
    };
    document.addEventListener('touchend', onTouchEnd);

    return () => {
      document.removeEventListener('touchstart', onTouchStart);
      document.removeEventListener('touchmove', onTouchMove);
      document.removeEventListener('touchends', onTouchEnd);
    };
  }, [onSwipeLeft, onSwipeRight]);
};

export const getAllPages = (userId: string) => [
  '/orgs',
  '/users',
  `/users/${userId}`,
];

export const getPage = (nextIndex: number, userId: string) => {
  const pages = getAllPages(userId);
  if (nextIndex >= 0 && nextIndex < pages.length) {
    return pages[nextIndex];
  } else if (nextIndex >= pages.length) {
    return pages[0];
  } else if (nextIndex < 0) {
    return pages[pages.length - 1];
  }
};

export const getNextPage = (path: string, userId: string) => {
  const pages = getAllPages(userId);
  const nextIndex = pages.indexOf(path) + 1;
  return getPage(nextIndex, userId);
};

export const getPreviousPage = (path: string, userId: string) => {
  const pages = getAllPages(userId);
  const nextIndex = pages.indexOf(path) - 1;
  return getPage(nextIndex, userId);
};

export const useSwipeableNavigation = () => {
  const history = useHistory();
  const authUser = useAuthUser();

  const goForward = useCallback(() => {
    if (authUser) {
      const page = getPreviousPage(window.location.pathname, authUser.id);
      if (page) {
        history.push(page);
      }
    }
  }, [history, authUser]);

  const goBack = useCallback(() => {
    if (authUser) {
      const page = getNextPage(window.location.pathname, authUser.id);
      if (page) {
        history.push(page);
      }
    }
  }, [history, authUser]);

  useSwipeable(goForward, goBack);
};
