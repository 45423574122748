import Auth, { AuthClass } from '@aws-amplify/auth';
import React, { useState, useEffect, FunctionComponent, memo } from 'react';
import { AuthContext, AuthRefreshContext } from './hooks';
import Loading from '../../eliot-components/src/Loading/Loading';
import { useAppContext } from '../../AppContext';
import { useConfig } from '../../eliot-components/src/ConfigProvider/hooks';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { useGetUserQuery } from '../../store/api-slice';
import { AuthUser } from './types';
import { useLocation } from 'react-router-dom';

interface Props {
  readonly auth?: AuthClass;
  readonly isLoadingVisible?: boolean;
}

const allowedPaths = ['/login', '/forgot-password'];

const initialUser: AuthUser = {
  id: '',
  email: '',
  orgs: [],
};

const AuthProvider: FunctionComponent<Props> = memo(
  ({ auth = Auth, isLoadingVisible = false, ...props }) => {
    const { state, dispatch } = useAppContext();
    const config = useConfig();

    let [sess, setSess] = useState<CognitoUserSession | null>(null);
    const location = useLocation();

    const token = sess ? sess.getIdToken().decodePayload() : null;
    const {
      data: usr,
      refetch: fetchUser,
      isLoading,
    } = useGetUserQuery(
      {
        apiBaseURL: config.apiBaseURL,
        id: token?.sub,
        token,
      },
      { skip: !token }
    );

    useEffect(() => {
      auth
        .currentSession()
        .then((s) => {
          setSess(s);
        })
        .catch(() => {
          if (allowedPaths.indexOf(location.pathname) === -1) {
            auth.signOut();
            localStorage.clear();
            window.location.replace('/login');
          }
        });
    }, [auth, location.pathname]);

    useEffect(() => {
      if (usr) {
        dispatch('user-changed', usr);
      }
    }, [usr, auth, dispatch]);

    if (isLoading) {
      if (isLoadingVisible) {
        return <Loading />;
      }
      return <></>;
    }

    return (
      <AuthRefreshContext.Provider value={fetchUser}>
        <AuthContext.Provider
          value={!!state.user ? state.user : initialUser}
          {...props}
        />
      </AuthRefreshContext.Provider>
    );
  }
);

export default AuthProvider;
