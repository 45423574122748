import React, { useEffect, useState } from 'react';
import { usePrevious } from '../common';
import { AllStringKeys } from './types';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import ImportExport from '@material-ui/icons/ImportExport';

export enum Direction {
  ASC = 'asc',
  DESC = 'desc',
}

export interface SortOption<TSortBy> {
  sortBy: TSortBy;
  direction?: Direction;
}

export interface SortOptionSelectItem<TSortBy> {
  value: SortOption<TSortBy>;
  label: string;
  id: string;
}
export type SortState<T extends AllStringKeys> = {
  [P in keyof T]?: Direction;
};

export type SortValueGetters<
  T extends AllStringKeys,
  TObject extends AllStringKeys
> = {
  [P in keyof T]?: (obj: TObject) => string | number;
};

interface ISortStateReturn<TState extends {}, TSortBy> {
  sortState: TState;
  updateSortState: React.Dispatch<SortOption<TSortBy>>;
  currentOptionId: string | undefined;
}

export const getSortArrow = (direction: Direction | undefined) => {
  switch (direction) {
    case Direction.ASC:
      return <ArrowUpward />;
    case Direction.DESC:
      return <ArrowDownward />;
    default:
      return <ImportExport />;
  }
};

export const useSortState = <TState extends {}, TSortBy>(
  reducer: (state: TState, action: SortOption<TSortBy>) => TState,
  initialValue: TState,
  onSortStateChanged: (state: TState) => void,
  mobileSortOptions: SortOptionSelectItem<TSortBy>[]
): ISortStateReturn<TState, TSortBy> => {
  const [currentOptionId, setCurrentOptionId] = useState<string>();
  const reducerWrapper = React.useCallback(
    (state: TState, action: SortOption<TSortBy>) => {
      const newState = reducer(state, action);
      const newDirection = Object.values(newState).find((value) => !!value);
      const currentOption = mobileSortOptions.find(
        (o) =>
          o.value.sortBy === action.sortBy && o.value.direction === newDirection
      );
      setCurrentOptionId(currentOption && currentOption.id);

      return newState;
    },
    [reducer, mobileSortOptions, setCurrentOptionId]
  );
  const [sortState, updateSortState] = React.useReducer(
    reducerWrapper,
    initialValue
  );

  const previousSortState = usePrevious(sortState);
  useEffect(() => {
    if (previousSortState !== sortState) {
      onSortStateChanged(sortState);
    }
  }, [sortState, previousSortState, onSortStateChanged]);

  return { sortState, updateSortState, currentOptionId };
};

// eslint-disable-next-line
const sortCompare = <T, _>(
  direction: Direction,
  getProperty: (obj: T) => string | number
) => {
  const prepare = (first: T) => {
    return typeof getProperty(first) === 'string'
      ? (getProperty(first) as string).toUpperCase()
      : (getProperty(first) as number);
  };

  const asc = (first: T, second: T) => {
    const firstProperty = prepare(first);
    const secondProperty = prepare(second);

    return firstProperty > secondProperty ? 1 : -1;
  };

  const desc = (first: T, second: T) => {
    const firstProperty = prepare(first);
    const secondProperty = prepare(second);

    return firstProperty < secondProperty ? 1 : -1;
  };

  return direction === Direction.ASC
    ? (first: T, second: T) => asc(first, second)
    : (first: T, second: T) => desc(first, second);
};

export const sort = <T extends AllStringKeys, TSortBy extends AllStringKeys>(
  array: T[],
  state: SortState<T>,
  sortValueGetters: SortValueGetters<TSortBy, T> = {}
) => {
  const name = Object.keys(state).find((key) => !!state && !!state[key]);
  if (!name) return array;

  const direction = state[name];
  if (!direction) return array;

  const sortValueGetter = sortValueGetters[name] || ((obj) => obj[name]);
  const newArray = array.sort(sortCompare(direction, sortValueGetter));
  return [...newArray];
};
