import React from 'react';
import { useAppContext } from '../../../../../AppContext';
import { IconButton, makeStyles, Theme } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';

const useStyles = makeStyles((theme: Theme) => ({
  hamburger: {
    color: 'white',
  },
}));

const HamburgerMenu = () => {
  const classes = useStyles();

  const { dispatch } = useAppContext();

  return (
    <IconButton
      className={classes.hamburger}
      edge="start"
      onClick={() => {
        dispatch('sidebar-changed');
      }}
    >
      <MenuIcon />
    </IconButton>
  );
};

export default HamburgerMenu;
