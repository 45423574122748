import {
  createContext,
  ReactNode,
  useContext,
  useReducer,
  useCallback,
} from 'react';

export interface Action {
  type: string;
  data?: any;
}

interface Store {
  state: any;
  dispatch: (action: string, args?: any) => void;
  toggleDarkMode: (event: any) => void;
  isDarkModeActive: boolean;
}

interface Reducer {
  (state: any, action: Action): any;
}

const init: Store = {
  state: {},
  dispatch: (action: string, args: any = null) => {},
  toggleDarkMode: () => {},
  isDarkModeActive: false,
};

const StoreContext = createContext(init);
const useAppContext = () => useContext(StoreContext);

const reducers: { [id: string]: Reducer } = {
  any: (state: any, action: Action): any => {
    if (process.env.NODE_ENV === 'development') {
      console.log(action.type.toUpperCase(), state, action);
    }

    return state;
  },
  warning: (state: any, action: Action): any => {
    if (process.env.NODE_ENV === 'development') {
      console.warn(action.type.toUpperCase(), state, action);
    }

    return state;
  },
};

const reduce = (action: string, reducer: Reducer) => {
  reducers[action] = reducer;
};

const reducer = (state: any, action: Action): any => {
  const anyReducer = reducers['any'];
  state = anyReducer(state, action);

  const actionReducer = reducers[action.type];
  if (actionReducer) {
    return actionReducer(state, action);
  }

  const warningReducer = reducers['warning'];
  return warningReducer(state, action);
};

const AppContext = (
  props: {
    state: any;
    children?: ReactNode;
    toggleDarkMode: (event: any) => void;
    isDarkModeActive: boolean;
  } = {
    state: {},
    children: null,
    toggleDarkMode: () => {},
    isDarkModeActive: false,
  }
) => {
  const { state: init, children, toggleDarkMode, isDarkModeActive } = props;
  const [state, dispatch] = useReducer(reducer, init);

  const sd = useCallback(
    (action: string, args: any = null) => {
      dispatch({
        type: action,
        data: args,
      });
    },
    [dispatch]
  );

  return (
    <StoreContext.Provider
      value={{ state, dispatch: sd, toggleDarkMode, isDarkModeActive }}
    >
      {children}
    </StoreContext.Provider>
  );
};

export { useAppContext, AppContext, reduce };
