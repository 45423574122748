import { createContext, useContext } from 'react';
import { AuthUser } from './types';

export const AuthContext = createContext<AuthUser | null>(null);
export const AuthRefreshContext = createContext<(() => void) | null>(null);

export const useAuthUser = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuthUser must be used within a AuthProvider');
  }
  return context;
};

export const useAuthRefreshUser = () => {
  const context = useContext(AuthRefreshContext);
  if (!context) {
    throw new Error('useAuthRefreshUser must be used within a AuthProvider');
  }
  return context;
};
