import React from 'react';
import {
  shouldShowOrgDetails,
  IOrgsTableMobileProps,
  OrgsSortBy,
} from '../../types';
import {
  Card,
  CardHeader,
  CardActions,
  Button,
  makeStyles,
  Theme,
} from '@material-ui/core';
import { AdapterLink } from '../../Orgs';
import Visible from '../../../../components/Visible/Visible';
import MedSelect from '../../../../eliot-components/src/Common/MedSelect';
import { SortOptionSelectItem } from '../../../../utils/sort';
import { mobileSortOptions } from '../../config';
import { useInfinitePaginationWithRoot } from '../../../../eliot-components/src/InfiniteTable/hooks';
import InfiniteScroll from 'react-infinite-scroller';

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    marginBottom: theme.spacing(1),
    '& .MuiCardHeader-title': {
      fontSize: theme.typography.body1.fontSize,
    },
    '& .MuiCardHeader-root': {
      paddingBottom: 0,
    },
  },
}));

const MobileLayout: React.FC<IOrgsTableMobileProps> = ({
  orgs,
  authUser,
  sortOptionId,
  updateSortState,
}) => {
  const classes = useStyles();

  const [data, nextPage, hasMore] = useInfinitePaginationWithRoot(orgs);

  const view = (
    <InfiniteScroll
      element="div"
      pageStart={0}
      initialLoad={false}
      loadMore={nextPage}
      hasMore={hasMore}
      threshold={270}
    >
      {data.map((org, index) => {
        return (
          <Card key={index} className={classes.card}>
            <CardHeader title={org.name} />
            <CardActions>
              <Visible when={shouldShowOrgDetails(org, authUser)}>
                <Button
                  color="primary"
                  size="small"
                  component={AdapterLink}
                  to={`/orgs/${encodeURIComponent(org.id)}`}
                >
                  Details
                </Button>
              </Visible>
            </CardActions>
          </Card>
        );
      })}
    </InfiniteScroll>
  );

  return (
    <div>
      <MedSelect
        name="sort-option"
        placeholder="Sort by"
        variant="outlined"
        fullWidth
        options={mobileSortOptions}
        valueGetter={(o: SortOptionSelectItem<OrgsSortBy>) => o.id}
        optionTextGetter={(o: SortOptionSelectItem<OrgsSortBy>) => o.label}
        value={sortOptionId}
        onChange={(e) => {
          const option = mobileSortOptions.find((o) => o.id === e.target.value);
          option && updateSortState(option.value);
        }}
      />
      {view}
    </div>
  );
};

export default MobileLayout;
