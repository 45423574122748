import { createSlice } from '@reduxjs/toolkit';

const loadState = () => {
  try {
    const serializedState = localStorage.getItem('state');
    if (serializedState === null) {
      return {};
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

export interface AppSliceState {
  deviceFilters: any[];
}

const initialState: AppSliceState = {
  deviceFilters: [],
};
const persistedState = loadState();
const AppSlice = createSlice({
  name: 'appState',
  initialState: {
    ...initialState,
    ...persistedState,
    actionMenuDevices: [], // force clear on reload
  },
  reducers: {
    setDeviceFilters(state, action) {
      state.deviceFilters = action.payload;
    },
  },
});

export default AppSlice;

export const { setDeviceFilters } = AppSlice.actions;
