import { isDarkTheme } from '../theme';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';
import { darken } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => ({
  selected: {
    backgroundColor: '#efefef',
  },
  table: {
    tableLayout: 'fixed',
    '& thead .MuiTableCell-stickyHeader': {
      backgroundColor: isDarkTheme(theme) ? '#393939' : '#eeeeee',
      borderBottom: '2px solid rgba(0,0,0,0.12)',
    },

    '& tbody tr:nth-of-type(even)': {
      backgroundColor: isDarkTheme(theme) ? '#393939' : '#fafafa',
    },
  },
  sortableCell: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    minHeight: '50px',
    '&::hover': {
      color: darken(theme.palette.text.secondary, 0.3),
    },
    '& > svg': {
      width: '18px',
      height: '18px',
      marginLeft: '7px',
    },
  },
  sortableCenter: {
    justifyContent: 'center',
  },
  container: {
    overflow: 'auto',
    [theme.breakpoints.up('md')]: {
      height: 'calc(100vh - 370px)',
    },
    [theme.breakpoints.up('lg')]: {
      height: 'calc(100vh - 270px)',
    },
  },
  summary: {
    height: '56px',
    borderTop: '1px',
    borderColor: theme.palette.text.primary,
    backgroundColor: isDarkTheme(theme)
      ? theme.palette.secondary.main
      : '#eeeeee',
  },
  summaryItem: {
    padding: '0 15px',
  },
}));
