import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Fab from '@material-ui/core/Fab';
import { Theme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import AddIcon from '@material-ui/icons/Add';
import { makeStyles } from '@material-ui/styles';
import { FunctionComponent, memo, useState } from 'react';
import ButtonProgress from '../../../../eliot-components/src/ButtonProgress/ButtonProgress';
import { useConfig } from '../../../../eliot-components/src/ConfigProvider/hooks';
import { useCreateOrgMutation } from '../../../../store/api-slice';
import { useSnackbar } from 'notistack';
import { parseErrorMessage } from '../../../../eliot-components/src/utils/helpers';
import { FormControlLabel, Grid, Switch } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  fab: {
    position: 'fixed',
    right: theme.spacing(4),
    bottom: theme.spacing(4),
    [theme.breakpoints.down('xs')]: {
      right: theme.spacing(2),
      bottom: theme.spacing(2),
    },
  },
  dialogContent: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  dialog: {
    '& .MuiDialog-paperFullWidth': {
      minWidth: 0,
    },
    '& .MuiFormGroup-root': {
      marginBottom: 5,
    },
  },
  orgName: {
    '& .MuiFormHelperText-root': {
      color: theme.palette.primary.main,
    },
  },
}));

interface Props {
  onCreated?(name: string): void;
  hasGlobalOrgAdminRole: boolean;
}

const CreateOrgButton: FunctionComponent<Props> = memo(
  ({ onCreated, hasGlobalOrgAdminRole }) => {
    const classes = useStyles();

    const { apiBaseURL } = useConfig();
    const { enqueueSnackbar } = useSnackbar();
    const [createOrg, { isLoading }] = useCreateOrgMutation();
    const [isOpen, setIsOpen] = useState(false);
    const [name, setName] = useState('');
    const [restricted, setRestricted] = useState(false);
    const [maxUsers, setMaxUsers] = useState(0);
    const [maxWebhooks, setMaxWebhooks] = useState(1);

    const handleClose = () => {
      setName('');
      setIsOpen(false);
    };

    return (
      <>
        <Fab
          color="primary"
          data-testid="create-org-button"
          aria-label="Create Org"
          className={classes.fab}
          onClick={() => {
            setIsOpen(true);
          }}
        >
          <AddIcon />
        </Fab>
        {isOpen && (
          <Dialog
            className={classes.dialog}
            fullWidth
            maxWidth="sm"
            aria-labelledby="dialog-title"
            open={true}
            onClose={() => {
              handleClose();
            }}
          >
            <DialogTitle id="dialog-title">Create Org</DialogTitle>
            <DialogContent className={classes.dialogContent}>
              <TextField
                margin="dense"
                variant="outlined"
                id="name"
                label="Name"
                inputProps={{ maxLength: 60 }}
                className={classes.orgName}
                autoFocus
                fullWidth
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
                helperText={
                  name && name.length === 60
                    ? 'Maximum character length reached'
                    : null
                }
              />
              {hasGlobalOrgAdminRole && (
                <>
                  <br />
                  <br />
                  <FormControlLabel
                    control={
                      <Switch
                        checked={restricted}
                        onChange={(e) => setRestricted(e.target.checked)}
                        name="checkedB"
                        color="primary"
                      />
                    }
                    label="Restricted"
                  />
                  {restricted && (
                    <>
                      <br />
                      <br />
                      <Grid container spacing={2}>
                        <Grid item sm={6} xs={12}>
                          <TextField
                            margin="dense"
                            variant="outlined"
                            id="max_users"
                            label="Max Users"
                            className={classes.orgName}
                            value={maxUsers}
                            onChange={(e) => {
                              let val = parseInt(e.target.value);
                              if (!val) {
                                val = 0;
                              }
                              setMaxUsers(val);
                            }}
                          />
                        </Grid>
                        <Grid item sm={6} xs={12}>
                          <TextField
                            margin="dense"
                            variant="outlined"
                            id="max_webhooks"
                            label="Max Webhooks"
                            className={classes.orgName}
                            value={maxWebhooks}
                            onChange={(e) => {
                              let val = parseInt(e.target.value);
                              if (!val) {
                                val = 0;
                              }
                              setMaxWebhooks(val);
                            }}
                          />
                        </Grid>
                      </Grid>
                    </>
                  )}
                </>
              )}
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  handleClose();
                }}
              >
                Cancel
              </Button>
              <ButtonProgress
                variant="contained"
                color="primary"
                data-testid="submit-create-org"
                isLoading={isLoading}
                onClick={async () => {
                  let orgObj = {
                    apiBaseURL,
                    name,
                  };
                  if (hasGlobalOrgAdminRole) {
                    // @ts-ignore
                    orgObj.restricted = restricted;
                    // @ts-ignore
                    orgObj.restrictedOpts = {
                      maxUsers: maxUsers,
                      maxWebhooks: maxWebhooks,
                    };
                  }
                  const resp: any = await createOrg(orgObj);
                  if (!resp.error) {
                    if (onCreated) {
                      onCreated(name);
                    }
                    handleClose();
                    return;
                  }

                  enqueueSnackbar(parseErrorMessage(resp.error), {
                    variant: 'error',
                  });
                }}
              >
                Submit
              </ButtonProgress>
            </DialogActions>
          </Dialog>
        )}
      </>
    );
  }
);

export default CreateOrgButton;
