import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import MenuItem from '@material-ui/core/MenuItem';
import { Theme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import React, {
  FunctionComponent,
  memo,
  useEffect,
  useState,
  FormEvent,
  useRef,
} from 'react';
import {
  ALL_ROLES,
  Role,
  DescriptiveRole,
} from '../../../../contexts/AuthProvider/types';
import { useConfig } from '../../../../eliot-components/src/ConfigProvider/hooks';
import Autosuggest, {
  RenderSuggestionsContainerParams,
} from 'react-autosuggest';
import {
  Paper,
  TextField,
  ListItemText,
  FormControl,
  Popper,
} from '@material-ui/core';
import lunr from 'lunr';
import { User } from '../../../Users/types';
import RolesSelect from '../../../../components/Roles/RolesSelect';
import { getUserName } from '../../../Users/config';
import {
  useGetUserRolesQuery,
  useGetUsersQuery,
} from '../../../../store/api-slice';
import { useSnackbar } from 'notistack';
import { parseErrorMessage } from '../../../../eliot-components/src/utils/helpers';
import { unpackCombinatedRoles } from '../../../../components/RolesTable/RolesTable';

lunr.tokenizer.separator = /\s+/;

const useStyles = makeStyles((theme: Theme) => ({
  dialog: {
    width: '600px',
  },
  dialogContent: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  root: {
    height: 250,
    flexGrow: 1,
  },
  noScroll: {
    overflowY: 'visible',
  },
  container: {
    position: 'relative',
  },
  suggestionsContainerOpen: {
    position: 'absolute',
    left: 0,
    right: 0,
    maxHeight: 200,
    [theme.breakpoints.down('xs')]: {
      maxHeight: 250,
    },
    overflowY: 'auto',
    boxShadow: theme.shadows[5],
    '& .MuiListItemText-primary': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    '& .MuiListItemText-secondary': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  suggestionBox: {
    zIndex: theme.zIndex.modal + 1,
    width: '100%',
    maxWidth:
      'calc(' +
      theme.breakpoints.width('sm') +
      'px - ' +
      theme.spacing(4) +
      'px)',
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    [theme.breakpoints.down('xs')]: {
      width: 'calc(100% - 4 * ' + theme.spacing(2) + 'px)',
    },
  },
  suggestion: {
    display: 'block',
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  },
  divider: {
    height: theme.spacing(2),
  },
  actions: {
    padding: theme.spacing(2),
  },
}));

interface Props {
  isOpen?: boolean;

  onClose?(): void;

  onAdd?(userID: string, role: Role[]): void;

  orgID?: string;
}

interface Highlighted {
  isHighlighted: boolean;
}

interface Match {
  ref: string;
}

const AddRoleAction: FunctionComponent<Props> = memo(
  ({ onAdd, isOpen = true, onClose, orgID }) => {
    const classes = useStyles();
    const { apiBaseURL } = useConfig();
    const { data: users = [], error } = useGetUsersQuery({ apiBaseURL });
    const { data: userRoles, error: rolesError } = useGetUserRolesQuery({
      apiBaseURL,
    });
    const [userID, setUserID] = useState('');
    const { enqueueSnackbar } = useSnackbar();

    if (error || rolesError) {
      enqueueSnackbar(parseErrorMessage(error || rolesError), {
        variant: 'error',
      });
    }

    const availableRoles = (role: DescriptiveRole) => {
      return userRoles ? userRoles.orgRoles.includes(role.id) : false;
    };

    const [roles, setRoles] = useState<Role[]>([]);
    const [index, setIndex] = useState<lunr.Index | null>(null);

    useEffect(() => {
      if (users && users.length) {
        setIndex(
          lunr(function (this: lunr.Builder) {
            this.ref('index');
            this.field('name');
            this.field('familyName');
            this.field('givenName');
            this.field('email');

            this.pipeline.remove(lunr.stemmer);
            this.pipeline.remove(lunr.stopWordFilter);

            users.forEach((item, index) => {
              const doc = { index, ...item };
              this.add(doc);
            });
          })
        );
        const user = users.find((u) => u.id === userID);
        const userOrg =
          user && user.orgs && user.orgs.find((o) => o.id === orgID);
        const userOrgRoles = userOrg && userOrg.roles ? userOrg.roles : [];
        setRoles(userOrgRoles);
      }
    }, [userID, users, orgID]);

    const handleClose = () => {
      setRoles([]);
      setValue('');
      setUserID('');
      handleClear();

      setValue('');
      if (onClose) {
        onClose();
      }
    };

    const handleSubmit = () => {
      if (userID === '') {
        setValidateUser(true);
        return;
      }

      if (onAdd) {
        let rolesToAdd: Role[] = [];
        roles.forEach((r) => {
          rolesToAdd = rolesToAdd.concat(unpackCombinatedRoles(r));
        });
        onAdd(userID, rolesToAdd);
      }

      handleClose();
    };

    const [validateUser, setValidateUser] = useState(false);
    const [suggestions, setSuggestions] = useState(users);
    const [value, setValue] = useState('');

    const handleChange = (
      e: FormEvent<HTMLInputElement>,
      params: Autosuggest.ChangeEvent
    ) => {
      setValue(params.newValue);
      setUserID('');
    };

    const handleClear = () => {
      setSuggestions([]);
    };

    const handleFetch = (
      params: Autosuggest.SuggestionsFetchRequestedParams
    ) => {
      const { value } = params;
      if (index != null) {
        const results = index.query((q) => {
          const parts = value.toLowerCase().trim().split(/\s/);

          parts.forEach((item) => {
            q.term(item.replace('-', '-'), {
              wildcard:
                lunr.Query.wildcard.LEADING | lunr.Query.wildcard.TRAILING,
              presence: lunr.Query.presence.REQUIRED,
            });
          });
        });

        const items: User[] = [];

        results.forEach((match: Match) => {
          items.push(users[+match.ref]);
        });

        setSuggestions(items);
      }
    };

    const renderInputComponent = (inputProps: any) => {
      const { inputRef = () => {}, ref, ...other } = inputProps;
      return (
        <TextField
          margin="dense"
          variant="outlined"
          autoFocus
          error={validateUser === true && userID === ''}
          fullWidth
          InputProps={{
            inputRef: (node) => {
              ref(node);
              inputRef(node);
            },
          }}
          {...other}
        />
      );
    };

    const formatSuggestion = (suggestion: User) => {
      setUserID(suggestion.id);
      return getUserName(suggestion);
    };

    const handleSuggestionSelected = (event: FormEvent<any>, args: any) => {
      setUserID(args.suggestion.id);
    };

    const cRef = useRef<HTMLDivElement | null>(null);
    const [anchorRef, setAnchorRef] = useState<HTMLInputElement | null>(null);

    return (
      <>
        <Dialog
          PaperProps={{ className: classes.dialog }}
          aria-labelledby="dialog-title"
          open={isOpen}
          onClose={() => {
            handleClose();
          }}
        >
          <DialogTitle id="dialog-title">User role(s)</DialogTitle>
          <DialogContent className={classes.dialogContent}>
            <FormControl fullWidth error={true} required>
              <Autosuggest
                renderInputComponent={renderInputComponent}
                suggestions={suggestions}
                onSuggestionsFetchRequested={handleFetch}
                onSuggestionsClearRequested={handleClear}
                getSuggestionValue={formatSuggestion}
                onSuggestionSelected={handleSuggestionSelected}
                renderSuggestion={(user: User, args: Highlighted) => (
                  <MenuItem selected={args.isHighlighted} component="div">
                    <ListItemText
                      primary={getUserName(user)}
                      secondary={user.email}
                    />
                  </MenuItem>
                )}
                theme={{
                  container: classes.container,
                  suggestionsContainerOpen: classes.suggestionsContainerOpen,
                  suggestionsList: classes.suggestionsList,
                  suggestion: classes.suggestion,
                }}
                inputProps={{
                  id: 'react-autosuggest-simple',
                  //@ts-ignore
                  label: 'User',
                  placeholder: 'Username or email',
                  value: value,
                  onChange: handleChange,
                  inputRef: (ref: HTMLInputElement | null) => {
                    setAnchorRef(ref);
                  },
                }}
                renderSuggestionsContainer={(
                  options: RenderSuggestionsContainerParams
                ) => {
                  if (
                    cRef !== null &&
                    cRef.current !== null &&
                    anchorRef !== null
                  ) {
                    return (
                      <Popper
                        keepMounted={true}
                        className={classes.suggestionBox}
                        placement="bottom-start"
                        open={Boolean(options.children)}
                        anchorEl={anchorRef}
                      >
                        <Paper {...options.containerProps}>
                          {options.children}
                        </Paper>
                      </Popper>
                    );
                  }

                  return null;
                }}
              />
            </FormControl>
            <RolesSelect
              roles={roles}
              disabled={!userID}
              orgID={orgID}
              setRoles={setRoles}
              allAvailableRoles={ALL_ROLES.filter(availableRoles)}
            />

            <br />
          </DialogContent>
          <div ref={cRef} />
          <DialogActions classes={{ root: classes.actions }}>
            <Button
              onClick={() => {
                handleClose();
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                handleSubmit();
              }}
            >
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
);

export default AddRoleAction;
