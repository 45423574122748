import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import { Theme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import { makeStyles } from '@material-ui/styles';
import React, { FunctionComponent, memo, useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useAuthUser } from '../../contexts/AuthProvider/hooks';
import {
  ALL_ROLES,
  hasOneOfOrgRoles,
  Role,
  hasOneOfGlobalRoles,
  hasRoleInAnyUsersOrg,
  COMBINATED_ROLES,
  DescriptiveRole,
  DescriptiveCombinatedRole,
} from '../../contexts/AuthProvider/types';
import { useConfig } from '../../eliot-components/src/ConfigProvider/hooks';
import Layout from '../../Layout/Layout';
import ActionsMenu from './components/ActionsMenu/ActionsMenu';
import { useOrg } from './hooks';
import ConfirmationDialog from '../../eliot-components/src/ConfirmationDialog/ConfirmationDialog';
import Page from '../../Layout/components/Page/Page';
import EditIcon from '@material-ui/icons/Edit';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import TextField from '@material-ui/core/TextField';
import VerificationStatus from '../Users/components/VerificationStatus/VerificationStatus';
import { UserOrg } from '../Users/types';
import { InfoOutlined } from '@material-ui/icons';
import MedTooltip from '../../components/MedTooltip/MedTooltip';
import { isDarkTheme } from '../../eliot-components/src/theme';
import { Paper } from '@material-ui/core';
import { MediaDown, MediaUp } from '../../components/MediaQueries/MediaQueries';
import {
  useDeleteOrgMutation,
  useDeleteUserRolesMutation,
  useGetOrgQuery,
  useRemoveAllOrgUsersMutation,
  useResendActivationEmailMutation,
  useUpdateOrgMutation,
} from '../../store/api-slice';
import { useSnackbar } from 'notistack';
import { parseErrorMessage } from '../../eliot-components/src/utils/helpers';
import {
  resolveCombinatedRoles,
  unpackCombinatedRoles,
} from '../../components/RolesTable/RolesTable';

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    marginLeft: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      ...theme.typography.h5,
    },
  },
  userTable: {
    marginBottom: theme.spacing(4),
    tableLayout: 'fixed',
    '& thead': {
      backgroundColor: isDarkTheme(theme) ? '#393939' : '#eeeeee',
    },
    '& tbody td': {
      wordBreak: 'break-all',
    },
    '& tbody tr:nth-of-type(even)': {
      backgroundColor: isDarkTheme(theme) ? '#393939' : '#fafafa',
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(2),
      display: 'block',
      '& thead': {
        display: 'block',
      },
      '& tbody': {
        display: 'block',
        boxShadow: theme.shadows[1],
        borderRadius: theme.shape.borderRadius,
        overflow: 'hidden',
      },
      '& tbody .MuiTableRow-root': {
        display: 'flex',
        flexWrap: 'wrap',
        position: 'relative',

        '& td': {
          // backgroundColor: 'white',
        },
        '& td:nth-child(1)': {
          paddingBottom: 0,
          order: 1,
          flexGrow: 1,
        },
        '& td:nth-child(2)': {
          color: theme.palette.text.secondary,
          paddingTop: 0,
          order: 3,
          flexBasis: '100%',
        },
        '& td:nth-child(3)': {
          order: 4,
          flexBasis: '100%',
        },
        '& td:nth-child(4)': {
          order: 2,
          flexBasis: '40px',
        },
        '&:first-child td:nth-child(1)': {
          paddingTop: theme.spacing(2),
          borderTopLeftRadius: theme.shape.borderRadius,
          borderTopRightRadius: theme.shape.borderRadius,
        },
        '&:last-child td:nth-child(2)': {
          paddingBottom: theme.spacing(1.5),
          borderBottomLeftRadius: theme.shape.borderRadius,
          borderBottomRightRadius: theme.shape.borderRadius,
        },
      },
      '& .MuiTableCell-head': {
        border: 'none',
        padding: theme.spacing(1, 2, 1, 2),
      },
      '& .MuiTableCell-body': {
        display: 'block',
        padding: theme.spacing(1, 8, 1, 2),
        whiteSpace: 'nowrap',
        border: 'none',
        '& .email': {
          textOverflow: 'ellipsis',
          overflow: 'hidden',
        },
      },
    },
  },
  section: {
    paddingTop: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      marginBottom: '56px',
    },
  },
  mobile: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  extraPadding: {
    [theme.breakpoints.down('sm')]: {
      paddingTop: '10px',
      minWidth: '100%',
    },
  },
  noPadding: {
    [theme.breakpoints.down('sm')]: {
      paddingTop: 0,
    },
  },
  actions: {
    textAlign: 'right',
    [theme.breakpoints.down('sm')]: {
      position: 'fixed',
      top: theme.mixins.toolbar.minHeight,
      left: 0,
      'z-index': theme.zIndex.drawer + 1,
      minHeight: theme.mixins.toolbar.minHeight,
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      backgroundColor: theme.palette.primary.main,
      boxShadow: theme.shadows[4],
      minWidth: '100%',
      '& button': {
        color: 'white',
        marginRight: theme.spacing(1),
      },
    },
  },
  formEdit: {
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(2),
    '& .MuiInput-formControl': {
      marginRight: theme.spacing(2),
    },
  },
  editOrgName: {
    width: '100%',
    '& .MuiFormHelperText-root': {
      color: theme.palette.primary.main,
    },
  },

  editOrgNameButtons: {
    justifyContent: 'flex-start',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'flex-end',
    },
  },
  clickableRow: {
    cursor: 'pointer',
  },
  tableIcon: {
    whiteSpace: 'nowrap',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'left',
      padding: theme.spacing(1, 1, 1, 2) + ' !important',
      '& button': {
        padding: 0,
      },
    },
  },
  infoClass: {
    verticalAlign: 'middle',
    marginRight: theme.spacing(1),
    zIndex: 999,
  },
  summary: {
    height: '56px',
    borderTop: '1px',
    borderColor: theme.palette.text.primary,
    backgroundColor: isDarkTheme(theme)
      ? theme.palette.secondary.main
      : '#eeeeee',
  },
  summaryItem: {
    padding: '0 15px',
  },
  totals: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
  },
  mobileTotals: {
    marginLeft: '-16px',
    marginRight: '-16px',
    marginTop: '35px',
    display: 'flex',
    flexBasis: 'calc(100% + 32px)',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: theme.palette.action.hover,
    position: 'relative',
  },
  mobileCount: {
    marginTop: theme.spacing(2),
    fontSize: '1rem',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      right: 0,
      top: 0,
      marginTop: theme.spacing(0.25),
    },
  },
  mobileTotalSmall: {
    marginLeft: '-16px',
    marginRight: '-16px',
    marginTop: '-24px',
    marginBottom: '16px',
    display: 'flex',
    flexBasis: 'calc(100% + 32px)',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: theme.palette.action.hover,
    position: 'relative',
  },
}));

interface SelectedUser {
  user: string | null;
  role: string[] | null;
}

const Org: FunctionComponent = memo(() => {
  const classes = useStyles();
  const { orgID } = useParams<{ orgID: string }>();
  const { apiBaseURL } = useConfig();
  const authUser = useAuthUser();
  const [selectedUserID, setSelectedUserID] = useState('');
  const history = useHistory();
  const [orgName, setOrgName] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  const [edit, setEdit] = useState(false);
  const [resendActivationEmail] = useResendActivationEmailMutation();
  const [updateOrg] = useUpdateOrgMutation();
  const [removeOrg] = useDeleteOrgMutation();
  const [removeOrgRoles] = useDeleteUserRolesMutation();
  const [removeAllUsersFromOrg] = useRemoveAllOrgUsersMutation();
  const {
    data: org,
    isFetching: isLoading,
    error: errorGetOrg,
  } = useGetOrgQuery({
    apiBaseURL,
    id: orgID,
  });
  const { error: errorOrg, mergeUserRoles } = useOrg(apiBaseURL, orgID, org);

  const error = errorOrg || errorGetOrg;

  if (error) {
    enqueueSnackbar(parseErrorMessage(error), { variant: 'error' });
  }

  useEffect(() => {
    setOrgName(!!org ? org.name : '');
  }, [org]);

  const hasOrgUserRole = hasOneOfOrgRoles(authUser, orgID, [
    Role.OrganizationManager,
    Role.UserManager,
    Role.UserViewer,
  ]);

  const hasWritingPermissions = hasOneOfOrgRoles(authUser, orgID, [
    Role.OrganizationManager,
    Role.UserManager,
  ]);

  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState(false);

  const [isConfirmationResendDialogOpen, setIsConfirmationResendDialogOpen] =
    useState(false);
  const sUser: SelectedUser = {
    user: null,
    role: null,
  };
  const [selectedUser, setSelectedUser] = useState(sUser);

  const extraPadding = hasWritingPermissions ? classes.extraPadding : '';

  const isOrgManager =
    hasOneOfGlobalRoles(authUser, [Role.OrganizationManager]) ||
    hasOneOfOrgRoles(authUser, orgID, [Role.OrganizationManager]);

  const handleEdit = (enable: boolean) => {
    setEdit(enable);
    if (!enable && org) {
      setOrgName(org.name);
    }
  };

  const handleUpdate = (name: string) => {
    updateOrg({ org: { id: org ? org.id : '', name: name }, apiBaseURL }).then(
      (resp: any) => {
        if (!resp.error) {
          setEdit(false);
          enqueueSnackbar('Organization updated');
          return;
        }
        enqueueSnackbar(parseErrorMessage(resp.error), { variant: 'error' });
      }
    );
  };

  const onCreateUser = () => {
    enqueueSnackbar('User created', { variant: 'info' });
  };

  const onRemoveAllUsers = () => {
    if (org && org.users) {
      removeAllUsersFromOrg({
        userIDs: org.users ? org.users.map((user) => user.id) : [],
        apiBaseURL,
        id: org.id,
      }).then(
        (resp: any) => {
          if (resp.error) {
            enqueueSnackbar('Remove users failed', { variant: 'error' });
          }
        },
        (err) => {
          if (err) {
            enqueueSnackbar('Remove users failed', { variant: 'error' });
          }
        }
      );
    }
  };

  const onRemoveOrg = () => {
    if (org) {
      removeOrg({ apiBaseURL, id: org.id }).then((resp: any) => {
        if (resp.error) {
          enqueueSnackbar(parseErrorMessage(resp.error), { variant: 'error' });
          return;
        }
        window.location.replace('/orgs');
      });
    }
  };

  const goToUser = (u: UserOrg) => {
    history.push(`/users/${encodeURIComponent(u.id)}`);
  };

  const isPermitedToSeeStatus =
    hasOneOfGlobalRoles(authUser, [
      Role.UserManager,
      Role.OrganizationManager,
      Role.UserViewer,
    ]) ||
    hasRoleInAnyUsersOrg(
      authUser,
      [Role.UserManager, Role.OrganizationManager, Role.UserViewer],
      authUser.orgs ? authUser.orgs : []
    );

  const onChangeCareRole = (active: boolean) => {
    if (org) {
      let msg = 'Care target system ';
      if (active) {
        msg += 'enabled';
      } else {
        msg += 'disabled';
      }

      const newOrg = { id: org.id, name: org.name, canCreateCare: active };
      updateOrg({ apiBaseURL, org: newOrg }).then((resp: any) => {
        if (!resp.error) {
          enqueueSnackbar(msg, { variant: 'info' });
          return;
        }
        enqueueSnackbar(parseErrorMessage(resp.error), { variant: 'error' });
      });
    }
  };

  const onChangeDeactivated = (deactivated: boolean) => {
    let msg = 'Organization ';
    if (deactivated) {
      msg += 'deactivated';
    } else {
      msg += 'activated';
    }
    if (org) {
      const newOrg = { id: org.id, name: org.name, deactivated: deactivated };
      updateOrg({ org: newOrg, apiBaseURL }).then((resp: any) => {
        if (!resp.error) {
          enqueueSnackbar(msg, { variant: 'info' });
          return;
        }
        enqueueSnackbar(parseErrorMessage(resp.error), { variant: 'error' });
      });
    }
  };

  const onChangeUnlinkedGateway = (active: boolean) => {
    let msg = 'Organization ';
    if (active) {
      msg += 'gateway option enabled';
    } else {
      msg += 'gateway option disabled';
    }
    if (org) {
      const newOrg = {
        id: org.id,
        name: org.name,
      };
      updateOrg({ org: newOrg, apiBaseURL }).then((resp: any) => {
        if (!resp.error) {
          enqueueSnackbar(msg, { variant: 'info' });
          return;
        }
        enqueueSnackbar(parseErrorMessage(resp.error), { variant: 'error' });
      });
    }
  };
  const allRoles: (DescriptiveRole | DescriptiveCombinatedRole)[] =
    ALL_ROLES.concat(COMBINATED_ROLES);
  return (
    <Page name="">
      <Layout isLoading={isLoading}>
        {org && (
          <>
            <section className={classes.section}>
              <MediaDown query="sm">
                <Grid container justifyContent="flex-end" wrap="wrap">
                  <Grid
                    item
                    className={
                      hasWritingPermissions
                        ? classes.mobileTotals
                        : classes.mobileTotalSmall
                    }
                  >
                    <Typography
                      variant="subtitle1"
                      color="textSecondary"
                      className={classes.mobileCount}
                    >
                      Total user orgs count:{' '}
                      {org.users && org.users.length > 0 ? org.users.length : 0}
                    </Typography>
                  </Grid>
                </Grid>
              </MediaDown>

              <Grid container justifyContent="space-between">
                <Grid item xs={9} className={extraPadding}>
                  {!edit && (
                    <Typography
                      variant="h4"
                      paragraph
                      className={classes.title}
                    >
                      {org.name}

                      {isOrgManager && (
                        <Tooltip title="Edit" aria-label="edit">
                          <IconButton
                            data-testid="organisation-name-edit-icon"
                            onClick={() => handleEdit(true)}
                            aria-label="edit"
                          >
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                    </Typography>
                  )}
                  {edit && (
                    <form
                      onSubmit={(e) => {
                        e.preventDefault();
                        handleUpdate(orgName);
                      }}
                      className={classes.formEdit}
                    >
                      <Grid container>
                        <Grid xs={12} sm={9} item>
                          <TextField
                            margin="dense"
                            variant="outlined"
                            required
                            id="organization-name-input"
                            inputProps={{ maxLength: 60 }}
                            className={classes.editOrgName}
                            label="Organization name"
                            defaultValue={org.name}
                            onChange={(e) => {
                              setOrgName(e.target.value);
                            }}
                            helperText={
                              (orgName && orgName.length === 60) ||
                              (!orgName && org.name && org.name.length === 60)
                                ? 'Maximum character length reached'
                                : null
                            }
                          />
                        </Grid>
                        <Grid xs={12} sm={3} item>
                          <Grid
                            container
                            className={classes.editOrgNameButtons}
                          >
                            <IconButton
                              type="submit"
                              role="submit-name-edit"
                              aria-label="save"
                            >
                              <CheckIcon />
                            </IconButton>
                            <IconButton
                              onClick={() => handleEdit(false)}
                              aria-label="close"
                            >
                              <CloseIcon />
                            </IconButton>
                          </Grid>
                        </Grid>
                      </Grid>
                    </form>
                  )}
                </Grid>
                {hasWritingPermissions && (
                  <Grid item xs={3} className={classes.actions}>
                    <ActionsMenu
                      orgID={orgID}
                      org={org}
                      onRemoveOrg={onRemoveOrg}
                      onMergeOrgRoles={mergeUserRoles}
                      onCreateUser={onCreateUser}
                      onRemoveAllUsers={onRemoveAllUsers}
                      onChangeCareRole={onChangeCareRole}
                      onChangeDeactivated={onChangeDeactivated}
                      onChangeUnlinkedGateway={onChangeUnlinkedGateway}
                      onCopyToClipboard={() => {
                        let clip = '';
                        org.users?.forEach((u) => {
                          clip += `Name: ${
                            u.givenName || u.familyName
                              ? u.givenName + ' ' + u.familyName
                              : u.name
                          }\nEmail: ${u.email}\nVerified: ${
                            u.emailVerified ? 'Verified' : 'Not Verified'
                          }\n\n`;
                        });
                        navigator.clipboard.writeText(clip);
                        enqueueSnackbar('Users copied to clipboard', {
                          variant: 'info',
                        });
                      }}
                    />
                  </Grid>
                )}
              </Grid>
            </section>

            <section className={[classes.section, classes.noPadding].join(' ')}>
              {org.users && org.users.length > 0 ? (
                allRoles.map((r) => {
                  const roleUsers = org.users
                    ? org.users
                        .filter(
                          (u) =>
                            u.roles &&
                            resolveCombinatedRoles(u.roles).indexOf(r) > -1
                        )
                        .sort((a, b) =>
                          a.name
                            .toLowerCase()
                            .localeCompare(b.name.toLowerCase())
                        )
                    : [];
                  if (roleUsers.length === 0) {
                    return null;
                  }
                  return (
                    <Paper key={r.id}>
                      <Table className={classes.userTable}>
                        <colgroup>
                          <col width="280px" />
                          <col width="auto" />
                          {isPermitedToSeeStatus && <col width="auto" />}
                          {hasWritingPermissions && <col width="auto" />}
                        </colgroup>
                        <TableHead>
                          <TableRow>
                            <TableCell>
                              <MedTooltip
                                enterTouchDelay={0}
                                title={r.description}
                                className={classes.infoClass}
                              >
                                <InfoOutlined />
                              </MedTooltip>
                              {r.label}
                            </TableCell>
                            <TableCell />
                            {isPermitedToSeeStatus && <TableCell />}
                            {hasWritingPermissions && <TableCell />}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {roleUsers.map((u) => (
                            <TableRow
                              key={u.id}
                              className={classes.clickableRow}
                              hover
                              onClick={() => goToUser(u)}
                            >
                              <TableCell>
                                {u.givenName || u.familyName
                                  ? u.givenName + ' ' + u.familyName
                                  : u.name}
                              </TableCell>
                              <TableCell>
                                <div className="email">{u.email}</div>
                              </TableCell>
                              {isPermitedToSeeStatus && (
                                <TableCell
                                  align="center"
                                  className={classes.tableIcon}
                                >
                                  <VerificationStatus
                                    setSelectedUserID={setSelectedUserID}
                                    setIsConfirmationDialogOpen={
                                      setIsConfirmationResendDialogOpen
                                    }
                                    user={u}
                                  />
                                </TableCell>
                              )}
                              {hasWritingPermissions && (
                                <TableCell
                                  align="right"
                                  className={classes.tableIcon}
                                  onClick={(e) => {
                                    if (!isOrgManager) {
                                      e.preventDefault();
                                      e.stopPropagation();
                                    }
                                  }}
                                >
                                  <Tooltip title="Remove">
                                    <IconButton
                                      aria-label="Remove"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        if (
                                          isOrgManager ||
                                          ([
                                            Role.OrganizationManager,
                                            Role.UserManager,
                                          ].indexOf(r.id) === -1 &&
                                            hasWritingPermissions)
                                        ) {
                                          setSelectedUser({
                                            user: u.id,
                                            role: unpackCombinatedRoles(r.id),
                                          });
                                          setIsConfirmationDialogOpen(true);
                                        } else {
                                          enqueueSnackbar('Permission denied', {
                                            variant: 'error',
                                          });
                                        }
                                      }}
                                    >
                                      <RemoveCircleOutlineIcon color="primary" />
                                    </IconButton>
                                  </Tooltip>
                                </TableCell>
                              )}
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </Paper>
                  );
                })
              ) : hasOrgUserRole ? (
                <Typography
                  color="textSecondary"
                  className="medisante-placeholder"
                >
                  No users
                </Typography>
              ) : (
                <Typography
                  color="textSecondary"
                  className="medisante-placeholder"
                >
                  You have insufficient permissions to view this org's
                  members...
                </Typography>
              )}
              <ConfirmationDialog
                questionText="Are you sure you want to remove this role from the user?"
                confirmationText="Remove"
                cancelText="Cancel"
                title="Remove User Role"
                onConfirm={() => {
                  if (selectedUser.user != null && selectedUser.role != null) {
                    removeOrgRoles({
                      userID: selectedUser.user,
                      roles: selectedUser.role,
                      apiBaseURL,
                      orgID: org.id,
                    }).then((resp: any) => {
                      if (resp.error) {
                        enqueueSnackbar(parseErrorMessage(resp.error), {
                          variant: 'error',
                        });
                      }
                    });
                  }
                }}
                onClose={() => {
                  setIsConfirmationDialogOpen(false);
                }}
                isOpen={isConfirmationDialogOpen}
              />
            </section>
            <MediaUp query="md">
              <Paper className={classes.totals}>
                <Grid
                  container
                  className={classes.summary}
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <Grid item className={classes.summaryItem}>
                    Total user orgs count:{' '}
                    {org.users && org.users.length > 0 ? org.users.length : 0}
                  </Grid>
                </Grid>
              </Paper>
            </MediaUp>
          </>
        )}
        <ConfirmationDialog
          questionText="Are you sure you want to resend a verification email?"
          confirmationText="Resend"
          cancelText="Cancel"
          title="Resend Verification Email"
          onConfirm={() => {
            resendActivationEmail({ userID: selectedUserID, apiBaseURL }).then(
              () => {
                if (error === null) {
                  enqueueSnackbar('Email sent', { variant: 'info' });
                }
              }
            );
          }}
          onClose={() => {
            setIsConfirmationResendDialogOpen(false);
          }}
          isOpen={isConfirmationResendDialogOpen}
        />
      </Layout>
    </Page>
  );
});

export default Org;
