import React, {
  FunctionComponent,
  memo,
} from 'react';
import { ConfigContext } from './hooks';
import Loading from '../Loading/Loading';
import Amplify from '@aws-amplify/core';
import {useGetConfigQuery} from "../../../store/api-slice";
import {useSnackbar} from "notistack";
import {parseErrorMessage} from "../utils/helpers";


const ConfigProvider: FunctionComponent = memo(
  ({ ...props }) => {
    const {data: config, error} = useGetConfigQuery(null);
    const { enqueueSnackbar } = useSnackbar();
    if (config) {
      // for debugging
      //Amplify.Logger.LOG_LEVEL = "DEBUG";
      Amplify.configure({
        Auth: {
          region: config.region,
          userPoolId: config.userPoolId,
          userPoolWebClientId: config.userPoolWebClientId
        },
      });
    }

    if (error) {
      enqueueSnackbar(parseErrorMessage(error), {variant: "error"})
      return <Loading />;
    }
    if (!config) {
      return <Loading />;
    }
    return <ConfigContext.Provider value={config} {...props} />;
  }
);

export default ConfigProvider;
