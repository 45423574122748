import { reduce, Action } from './AppContext';

const AppState = {
  sidebar: false,
  page: null,
  user: null,
  version: null,
};

reduce('sidebar-changed', (state: any): any => {
  return { ...state, sidebar: !state.sidebar };
});

reduce('page-changed', (state: any, action: Action): any => {
  return { ...state, page: action.data, sidebar: false };
});

reduce('user-changed', (state: any, action: Action): any => {
  return { ...state, user: action.data };
});

reduce('version-changed', (state: any, action: Action): any => {
  return { ...state, version: action.data.version };
});

reduce('embedded-app', (state: any): any => {
  return { ...state, isEmbedded: true };
});

export default AppState;
