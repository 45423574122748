import React, { ReactNode, useLayoutEffect } from 'react';
import { useAppContext } from '../../../AppContext';

const Page = (props: { name: string; children: ReactNode }) => {
  const { name, children } = props;
  const { dispatch } = useAppContext();

  useLayoutEffect(() => {
    dispatch('page-changed', name);
  }, [name, dispatch]);

  return <>{children}</>;
};

export default Page;
