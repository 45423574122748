type GropedByStringKey<T> = { [key: string]: T[] };
type StringKey<T, K extends keyof T> = T[K] extends string ? K : never;

export const groupBy = <T, K extends keyof T>(
  collection: T[],
  key: StringKey<T, K>
): GropedByStringKey<T> => {
  return collection.reduce((prev: GropedByStringKey<T>, curr: T) => {
    const newKey = curr[key as K] as unknown as string;
    return { ...prev, [newKey]: [...(prev[newKey] || []), curr] };
  }, {});
};
