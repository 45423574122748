import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { Theme } from '@material-ui/core/styles';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import RemoveIcon from '@material-ui/icons/RemoveCircleOutline';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import { makeStyles } from '@material-ui/styles';
import { FunctionComponent, useState, useRef } from 'react';
import AddUserRoleDialog from '../AddUserRoleDialog/AddUserRoleDialog';
import CreateUserDialog from '../CreateUserDialog/CreateUserDialog';
import {
  hasOneOfOrgRoles,
  hasOneOfRoles,
} from '../../../../contexts/AuthProvider/types';
import { useAuthUser } from '../../../../contexts/AuthProvider/hooks';
import Visible from '../../../../components/Visible/Visible';
import {
  Role,
  hasOneOfGlobalRoles,
} from '../../../../contexts/AuthProvider/types';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { Org } from '../../../Orgs/types';
import ConfirmationDialog from '../../../../eliot-components/src/ConfirmationDialog/ConfirmationDialog';
import { FileCopy, Lock, LockOpen } from '@material-ui/icons';
import RestrictedDialog from '../RestrictedDialog/RestrictedDialog';
import { Divider } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    marginRight: theme.spacing(1),
  },
}));

interface Props {
  orgID: string;
  org: Org;

  onCreateUser?(): void;

  onRemoveAllUsers?(): void;

  onRemoveOrg?(): void;

  onMergeOrgRoles?(userID: string, roles: Role[]): void;

  onChangeCareRole?(active: boolean): void;

  onChangeDeactivated?(deactivated: boolean): void;

  onChangeUnlinkedGateway?(active: boolean): void;

  onCopyToClipboard?(): void;
}

const ActionsMenu: FunctionComponent<Props> = ({
  orgID,
  org,
  onCreateUser,
  onRemoveAllUsers,
  onMergeOrgRoles,
  onRemoveOrg,
  onChangeCareRole,
  onChangeDeactivated,
  onChangeUnlinkedGateway,
  onCopyToClipboard,
}) => {
  const classes = useStyles();
  const button = useRef<HTMLButtonElement>();
  const [isOpen, setIsOpen] = useState(false);
  const [isAddUserRoleDialogOpen, setIsAddUserRoleDialogOpen] = useState(false);
  const [isDeleteAllUserDialogOpen, setDeleteAllUsersDialog] = useState(false);
  const [isCreateUserDialogOpen, setIsCreateUserDialogOpen] = useState(false);
  const [isOpenRemoveOrgDialog, setOpenRemoveOrgDialog] = useState(false);
  const [isRestrictedDialogOpen, setIsRestrictedDialogOpen] = useState(false);
  const [activeCare, setActiveCare] = useState(org.canCreateCare);
  const [deactivated, setDeactivated] = useState(org.deactivated);

  const authUser = useAuthUser();

  const isOrgManager =
    hasOneOfOrgRoles(authUser, orgID, [Role.OrganizationManager]) ||
    hasOneOfGlobalRoles(authUser, [Role.OrganizationManager]);

  const handleClose = () => setIsOpen(false);

  return (
    <>
      <Button
        innerRef={button}
        color="primary"
        aria-owns={isOpen ? 'actions-menu' : undefined}
        aria-haspopup="true"
        onClick={(e) => {
          setIsOpen(true);
        }}
      >
        Actions <KeyboardArrowDownIcon />
      </Button>
      <Menu
        id="actions-menu"
        anchorEl={button.current}
        open={isOpen}
        onClose={handleClose}
      >
        <Visible when={isOrgManager}>
          <MenuItem
            onClick={() => {
              handleClose();
              setIsCreateUserDialogOpen(true);
            }}
          >
            <PersonAddIcon className={classes.icon} />
            Create user
          </MenuItem>
        </Visible>
        <MenuItem
          onClick={() => {
            handleClose();
            setIsAddUserRoleDialogOpen(true);
          }}
        >
          <PersonAddIcon className={classes.icon} />
          User role(s)
        </MenuItem>
        <Visible
          when={hasOneOfGlobalRoles(authUser, [Role.OrganizationManager])}
        >
          <MenuItem
            data-testid="delete-all-users"
            onClick={() => {
              handleClose();
              setDeleteAllUsersDialog(true);
            }}
          >
            <RemoveIcon className={classes.icon} />
            Remove all users
          </MenuItem>
        </Visible>
        <Visible
          when={hasOneOfGlobalRoles(authUser, [Role.OrganizationManager])}
        >
          <MenuItem
            data-testid="restricted-org"
            onClick={() => {
              handleClose();
              setIsRestrictedDialogOpen(true);
            }}
          >
            {org.restricted && <Lock className={classes.icon} />}
            {!org.restricted && <LockOpen className={classes.icon} />}
            Restrictions
          </MenuItem>
        </Visible>
        {hasOneOfGlobalRoles(authUser, [Role.OrganizationManager]) &&
          ((org.users && org.users.length === 0) || !org.users) && (
            <MenuItem
              data-testid="deleteorg"
              onClick={() => {
                handleClose();
                setOpenRemoveOrgDialog(true);
              }}
            >
              <RemoveIcon className={classes.icon} />
              Remove Org
            </MenuItem>
          )}
        {(hasOneOfGlobalRoles(authUser, [Role.OrganizationManager]) ||
          (hasOneOfOrgRoles(authUser, orgID, [Role.OrganizationManager]) &&
            authUser.canCreateCare)) && (
          <MenuItem>
            <FormControl component="fieldset">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      color="primary"
                      checked={activeCare}
                      onChange={(e) => {
                        handleClose();
                        setActiveCare(e.target.checked);
                        if (onChangeCareRole) {
                          onChangeCareRole(e.target.checked);
                        }
                      }}
                    />
                  }
                  label="Care target system"
                />
              </FormGroup>
            </FormControl>
          </MenuItem>
        )}
        {(hasOneOfGlobalRoles(authUser, [Role.OrganizationManager]) ||
          hasOneOfOrgRoles(authUser, orgID, [Role.OrganizationManager])) && (
          <MenuItem>
            <FormControl component="fieldset">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      color="primary"
                      checked={deactivated}
                      onChange={(e) => {
                        handleClose();
                        setDeactivated(e.target.checked);
                        if (onChangeDeactivated) {
                          onChangeDeactivated(e.target.checked);
                        }
                      }}
                    />
                  }
                  label="De-activate"
                />
              </FormGroup>
            </FormControl>
          </MenuItem>
        )}
        <Divider />
        <MenuItem
          onClick={() => {
            if (onCopyToClipboard) {
              onCopyToClipboard();
            }
            handleClose();
          }}
        >
          <FileCopy className={classes.icon} />
          Copy to clipboard
        </MenuItem>
      </Menu>
      {isAddUserRoleDialogOpen && (
        <AddUserRoleDialog
          isOpen={isAddUserRoleDialogOpen}
          onClose={() => {
            setIsAddUserRoleDialogOpen(false);
          }}
          onAdd={onMergeOrgRoles}
          orgID={orgID}
        />
      )}
      <Visible
        when={
          hasOneOfRoles(authUser, [
            Role.OrganizationManager,
            Role.UserManager,
          ]) && isCreateUserDialogOpen
        }
      >
        <CreateUserDialog
          isOpen={isCreateUserDialogOpen}
          orgID={orgID}
          onClose={() => {
            setIsCreateUserDialogOpen(false);
          }}
          onCreated={() => {
            if (onCreateUser) {
              onCreateUser();
            }
          }}
        />
      </Visible>
      <ConfirmationDialog
        questionText="Are you sure you want remove all users in organization?"
        confirmationText="Remove"
        cancelText="Cancel"
        title="Remove All Users"
        onConfirm={() => {
          if (onRemoveAllUsers) {
            onRemoveAllUsers();
          }
        }}
        onClose={() => {
          setDeleteAllUsersDialog(false);
        }}
        isOpen={isDeleteAllUserDialogOpen}
      />
      <ConfirmationDialog
        questionText="Are you sure you want remove organization?"
        confirmationText="Remove"
        cancelText="Cancel"
        title="Remove Organization"
        onConfirm={() => {
          if (onRemoveOrg) {
            onRemoveOrg();
          }
        }}
        onClose={() => {
          setOpenRemoveOrgDialog(false);
        }}
        isOpen={isOpenRemoveOrgDialog}
      />

      <Visible
        when={
          hasOneOfGlobalRoles(authUser, [Role.OrganizationManager]) &&
          isRestrictedDialogOpen
        }
      >
        <RestrictedDialog
          isOpen={isRestrictedDialogOpen}
          org={org}
          onClose={() => {
            setIsRestrictedDialogOpen(false);
          }}
          onUpdated={() => {}}
        />
      </Visible>
    </>
  );
};

export default ActionsMenu;
