import { Role } from '../../contexts/AuthProvider/types';

export interface UserOrg {
  id: string;
  name?: string;
  roles: Role[];
}

export interface User {
  readonly id: string;
  readonly email: string;
  readonly name: string;
  readonly givenName: string;
  readonly familyName: string;
  readonly isConfirmed?: boolean;
  readonly lastLoginTime?: string;
  readonly orgs: UserOrg[];
  readonly globalRoles?: Role[];
  readonly canCreateCare?: boolean;
  readonly deactivated?: boolean;
  readonly emailVerified?: boolean;
}

export enum UsersSortBy {
  NAME = 'NAME',
  ORGS = 'ORGS',
  STATUS = 'STATUS',
  GLOBAL_ROLES = 'GLOBAL_ROLES',
  EMAIL = 'EMAIL',
}
