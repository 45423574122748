import { Grid, Paper } from '@material-ui/core';
import React from 'react';
import { SortState } from '../../../../eliot-components/src/utils/sort';
import { useStyles } from './styles';
import InfiniteTable from '../../../../eliot-components/src/InfiniteTable/InfiniteTable';
import { TableColumn } from '../../../../eliot-components/src/InfiniteTable/types';
import clsx from 'clsx';

interface Props<T> {
  dataToCount: any[];
  dataName: string;
  sortState: SortState<T>;
  measurementCount?: number;
  data: T[];
  nextPage: () => void;
  hasMore: boolean;
  container?: any;
  tableColumns: TableColumn<T>[];
  getKeyValue: (entity: T) => string;
  tableClass?: string;
}

function PaginatedTable<T>(props: Props<T>) {
  const { dataToCount, sortState, tableClass } = props;

  const classes = useStyles();

  return (
    <Paper>
      <InfiniteTable
        container={props.container}
        containerClass={clsx(tableClass, classes.container)}
        loadMore={props.nextPage}
        hasMore={props.hasMore}
        data={props.data}
        tableColumns={props.tableColumns}
        sortState={sortState}
        getKeyValue={props.getKeyValue}
      />
      <Grid
        container
        className={classes.summary}
        justifyContent="flex-end"
        alignItems="center"
      >
        <Grid item className={classes.summaryItem}>
          Total {props.dataName} count: {dataToCount.length}
        </Grid>
      </Grid>
    </Paper>
  );
}

export default PaginatedTable;
