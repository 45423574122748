import React, { FunctionComponent, memo } from 'react';
import {
  ALL_ROLES,
  COMBINATED_ROLES,
  DescriptiveCombinatedRole,
  DescriptiveRole,
  Role,
} from '../../contexts/AuthProvider/types';
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@material-ui/core';

import Tooltip from '@material-ui/core/Tooltip';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import { useAuthUser } from '../../contexts/AuthProvider/hooks';

interface RolesTableProps {
  maxTableHeight?: number;
  roles: string[];
  orgID?: string;
  removeRole: (ids: string[]) => void;
}

export const resolveCombinatedRoles = (roles: string[]) => {
  let selectedRoles: (DescriptiveRole | DescriptiveCombinatedRole)[] =
    ALL_ROLES.filter((r) => roles.includes(r.id));
  const combinatedRoles = COMBINATED_ROLES.filter(
    (r) =>
      selectedRoles.filter((role) => r.roles.indexOf(role.id) > -1).length ===
      r.roles.length
  );

  if (combinatedRoles.length > 0) {
    selectedRoles = selectedRoles.filter(
      (r) =>
        combinatedRoles.filter((role) => role.roles.indexOf(r.id) > -1)
          .length === 0
    ); // if role is in any combinated role filter it out
    selectedRoles = selectedRoles.concat(combinatedRoles);
  }
  return selectedRoles;
};

export const unpackCombinatedRoles = (role: string): Role[] => {
  const combinatedRole = COMBINATED_ROLES.find((r) => r.id === role);

  return combinatedRole ? combinatedRole.roles : [role as Role];
};

const RolesTable: FunctionComponent<RolesTableProps> = memo(
  ({ roles, removeRole, maxTableHeight = 320, orgID }) => {
    const maxRowHeight = 80;
    const tableHeight =
      roles.length * maxRowHeight < maxTableHeight
        ? roles.length * maxRowHeight
        : maxTableHeight;
    const tableMarginBottom =
      maxTableHeight - tableHeight > 0 ? maxTableHeight - tableHeight : 0;

    const authUser = useAuthUser();

    // disable remove org manger if you are not org manger in specific Organization or have global role
    const isDisabled = (role: DescriptiveRole): boolean => {
      if (
        authUser.orgs &&
        orgID &&
        ((authUser.globalRoles &&
          authUser.globalRoles.indexOf(Role.OrganizationManager) === -1) ||
          !authUser.globalRoles)
      ) {
        const org = authUser.orgs.filter((org) => org.id === orgID);
        if (org.length > 0) {
          if (org[0].roles.indexOf(Role.OrganizationManager) === -1) {
            return role.id === Role.OrganizationManager;
          }
        }
      }

      return false;
    };

    const selectedRoles = resolveCombinatedRoles(roles);

    return !!roles && roles.length > 0 ? (
      <Table style={{ marginBottom: tableMarginBottom }}>
        <TableBody style={{ height: tableHeight, display: 'block' }}>
          {roles
            ? selectedRoles.map((x) => (
                <TableRow
                  key={x.label}
                  style={{
                    height: maxRowHeight,
                    width: '100%',
                    display: 'inline-table',
                  }}
                >
                  <TableCell align="left">{x.label}</TableCell>
                  <TableCell className="actions" align="right">
                    {isDisabled(x) ? null : (
                      <Tooltip title="Remove">
                        <IconButton
                          className="remove"
                          aria-label="Remove"
                          onClick={() =>
                            'roles' in x
                              ? removeRole(x.roles)
                              : removeRole([x.id])
                          }
                        >
                          <RemoveCircleOutlineIcon color="primary" />
                        </IconButton>
                      </Tooltip>
                    )}
                  </TableCell>
                </TableRow>
              ))
            : null}
        </TableBody>
      </Table>
    ) : (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: maxTableHeight,
        }}
      >
        No selected roles
      </div>
    );
  }
);

export default RolesTable;
