import Typography from '@material-ui/core/Typography';
import React, { FunctionComponent, memo, useState, useEffect } from 'react';
import { Link, LinkProps, useHistory, useLocation } from 'react-router-dom';
import { useConfig } from '../../eliot-components/src/ConfigProvider/hooks';
import Layout from '../../Layout/Layout';
import CreateOrgButton from './components/CreateOrgButton/CreateOrgButton';
import Page from '../../Layout/components/Page/Page';
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  makeStyles,
  Theme,
  Toolbar,
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import MobileLayout from './components/MobileLayout/MobileLayout';
import { Org, OrgsSortBy } from './types';
import { useAuthUser } from '../../contexts/AuthProvider/hooks';
import DesktopLayout from './components/DesktopLayout/DesktopLayout';
import { useSortState, SortState, Direction } from '../../utils/sort';
import { orgsSortingReducer, sortOrgs, mobileSortOptions } from './config';
import { MediaDown } from '../../components/MediaQueries/MediaQueries';
import {
  hasOneOfGlobalRoles,
  hasOneOfRoles,
  Role,
} from '../../contexts/AuthProvider/types';
import Visible from '../../components/Visible/Visible';
import { useGetOrgsQuery } from '../../store/api-slice';
import { useSnackbar } from 'notistack';
import { parseErrorMessage } from '../../eliot-components/src/utils/helpers';

// required for react-router-dom < 6.0.0
// see https://github.com/ReactTraining/react-router/issues/6056#issuecomment-435524678
export const AdapterLink = React.forwardRef<HTMLAnchorElement, LinkProps>(
  (props, ref) => <Link innerRef={ref as any} {...props} />
);

const useStyles = makeStyles((theme: Theme) => ({
  mobile: {
    marginTop: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  desktop: {
    marginTop: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  tableRow: {
    height: '69px',
  },
  totals: {
    margin: '1px -16px',
    display: 'flex',
    flexBasis: 'calc(100% + 32px)',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: theme.palette.action.hover,
    position: 'relative',
  },
  mobileCount: {
    marginTop: theme.spacing(2),
    fontSize: '1rem',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      right: 0,
      top: 0,
      marginTop: theme.spacing(0.25),
    },
  },
  toolbar: {
    flexShrink: 0,
    padding: 0,
  },
  noOrgs: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 10,
    right: 10,
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',

    [theme.breakpoints.up('lg')]: {
      top: '64px',
      bottom: 0,
      right: 0,
      left: '230px',
    },
  },
}));
const Orgs: FunctionComponent = memo(() => {
  const { apiBaseURL } = useConfig();
  const classes = useStyles();
  const authUser = useAuthUser();
  const showGlobalRoles = hasOneOfGlobalRoles(authUser, [
    Role.OrganizationManager,
    Role.UserManager,
    Role.UserViewer,
  ]);

  const history = useHistory();
  const query = new URLSearchParams(useLocation().search);
  const [restrictedFilter, setRestrictedFilter] = useState<boolean>(
    query.get('restricted') === 'true'
  );

  const [deactivatedFilter, setDeactivatedFilter] = useState<boolean>(
    query.get('deactivated') === 'true'
  );

  const {
    data: initialOrgs = [],
    isFetching: isLoading,
    error,
  } = useGetOrgsQuery({ apiBaseURL });

  const [orgs, setOrgs] = useState<Org[]>([]);
  const { enqueueSnackbar } = useSnackbar();

  if (error) {
    enqueueSnackbar(parseErrorMessage(error), { variant: 'error' });
  }

  const handleSortStateChange = React.useCallback(
    (sortState) => {
      const sortedOrgs = sortOrgs(
        [...orgs],
        sortState.name ? sortState : { name: Direction.ASC }
      );
      setOrgs(sortedOrgs);
    },
    [orgs, setOrgs]
  );

  const { sortState, updateSortState, currentOptionId } = useSortState<
    SortState<Org>,
    OrgsSortBy
  >(orgsSortingReducer, {}, handleSortStateChange, mobileSortOptions);

  useEffect(() => {
    if (restrictedFilter || deactivatedFilter) {
      let filteredOrgs = initialOrgs;
      if (restrictedFilter) {
        filteredOrgs = filteredOrgs.filter((org) => org.restricted);
      }
      if (deactivatedFilter) {
        filteredOrgs = filteredOrgs.filter((org) => org.deactivated === true);
      }
      setOrgs(filteredOrgs);
      return;
    }

    if (initialOrgs && initialOrgs.length > 0) {
      setOrgs([...initialOrgs]);
    }
  }, [
    initialOrgs,
    updateSortState,
    restrictedFilter,
    deactivatedFilter,
    history,
  ]);

  // TODO: revert this logic back when response will be actual organization objects
  const onOrgCreated = async (id: string) => {
    // if (id.length > 0) {
    //   history.push(`/orgs/${encodeURIComponent(id)}`
    //   return;
    // }
    enqueueSnackbar('Organization created');
  };

  return (
    <Page name="organisations">
      <Layout isLoading={isLoading}>
        <Grid
          container
          justifyContent="flex-start"
          direction="row"
          style={{ marginTop: '-16px' }}
        >
          <MediaDown query="sm">
            <Grid container justifyContent="flex-end" wrap="wrap">
              <Grid item className={classes.totals}>
                <Typography
                  variant="subtitle1"
                  color="textSecondary"
                  className={classes.mobileCount}
                >
                  Total organization count: {orgs.length}
                </Typography>
              </Grid>
            </Grid>
          </MediaDown>
        </Grid>
        <Visible when={showGlobalRoles}>
          <Toolbar className={classes.toolbar}>
            <Grid container>
              <Grid item>
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={restrictedFilter}
                        onClick={() => {
                          setRestrictedFilter(!restrictedFilter);
                        }}
                        name="restricted"
                      />
                    }
                    label={
                      <Typography variant="body2">Only Restricted</Typography>
                    }
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={deactivatedFilter}
                        onClick={() => {
                          setDeactivatedFilter(!deactivatedFilter);
                        }}
                        name="deactivated"
                      />
                    }
                    label={
                      <Typography variant="body2">Only Deactivated</Typography>
                    }
                  />
                </FormGroup>
              </Grid>
            </Grid>
          </Toolbar>
        </Visible>
        {orgs.length === 0 ? (
          <div className={classes.noOrgs}>No orgs found...</div>
        ) : (
          <>
            <div className={classes.mobile} data-testid="mobile">
              <MobileLayout
                sortOptionId={currentOptionId}
                updateSortState={updateSortState}
                orgs={orgs}
                authUser={authUser}
              />
            </div>
            <div className={classes.desktop} data-testid="desktop">
              <DesktopLayout
                sortState={sortState}
                updateSortState={updateSortState}
                orgs={orgs}
              />
            </div>
          </>
        )}
        <Visible when={hasOneOfRoles(authUser, [Role.OrganizationManager])}>
          <CreateOrgButton
            onCreated={onOrgCreated}
            hasGlobalOrgAdminRole={hasOneOfGlobalRoles(authUser, [
              Role.OrganizationManager,
            ])}
          />
        </Visible>
      </Layout>
    </Page>
  );
});

export default Orgs;
