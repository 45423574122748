import React, { useMemo } from 'react';
import { User, UsersSortBy } from './types';
import { SortOption, SortState } from '../../utils/sort';
import { TableColumn } from '../../eliot-components/src/InfiniteTable/types';
import { DetailCell } from './components/UsersTable/TableComponents';
import VerificationStatus from './components/VerificationStatus/VerificationStatus';
import { getUserName } from './config';
//import { resolveCombinatedRoles } from '../../components/RolesTable/RolesTable';

export const useUsersTableColumns = (
  sortState: SortState<User>,
  updateSortState: React.Dispatch<SortOption<UsersSortBy>>,
  setSelectedUserID: Function,
  setIsConfirmationDialogOpen: Function,
  showGlobalRoles: boolean
): TableColumn<User>[] => {
  const columns = useMemo(() => {
    const tableColumns: TableColumn<User>[] = [];

    tableColumns.push({
      header: 'Name',
      name: 'name',
      width: '20%',
      sortState: sortState.name,
      cell: (u: User) => <>{getUserName(u)}</>,
      onHeaderClick: () => updateSortState({ sortBy: UsersSortBy.NAME }),
    });
    tableColumns.push({
      header: 'Email',
      name: 'email',
      width: '25%',
      sortState: sortState.email,
      onHeaderClick: () => updateSortState({ sortBy: UsersSortBy.EMAIL }),
    });

    tableColumns.push({
      header: 'Orgs',
      name: 'orgs',
      width: 120,
      className: 'orgs',
      hide: !showGlobalRoles,
      sortState: sortState.orgs,
      cell: (u: User) => (
        <>{u.orgs && u.orgs.length > 0 ? u.orgs.length : '0'}</>
      ),
      onHeaderClick: () => updateSortState({ sortBy: UsersSortBy.ORGS }),
    });

    tableColumns.push({
      header: 'Global Roles',
      name: 'global-roles',
      width: 150,
      className: 'roles',
      hide: !showGlobalRoles,
      sortState: sortState.globalRoles,
      cell: (u: User) => <>{u.globalRoles ? u.globalRoles.length : '0'}</>,
      onHeaderClick: () =>
        updateSortState({ sortBy: UsersSortBy.GLOBAL_ROLES }),
    });

    tableColumns.push({
      name: 'status',
      header: 'Status',
      sortState: sortState.isConfirmed,
      onHeaderClick: () => updateSortState({ sortBy: UsersSortBy.STATUS }),
      className: 'status',
      cell: (user: User) => (
        <VerificationStatus
          user={user}
          setSelectedUserID={setSelectedUserID}
          setIsConfirmationDialogOpen={setIsConfirmationDialogOpen}
        />
      ),
      width: 'auto',
    });

    tableColumns.push({
      name: 'details',
      header: '',
      className: 'details',
      cell: (user: User) => <DetailCell user={user} />,
      width: 'auto',
    });

    return tableColumns;
  }, [
    sortState,
    updateSortState,
    showGlobalRoles,
    setIsConfirmationDialogOpen,
    setSelectedUserID,
  ]);

  return columns;
};
