import Button from '@material-ui/core/Button';
import MedSelect from '../../eliot-components/src/Common/MedSelect';
import React, {
  FunctionComponent,
  memo,
  useState,
  useEffect,
  Fragment,
} from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useAuthUser } from '../../contexts/AuthProvider/hooks';
import {
  hasOneOfGlobalRoles,
  hasRoleInAnyUsersOrg,
  Role,
  ALL_ROLES,
} from '../../contexts/AuthProvider/types';
import { useConfig } from '../../eliot-components/src/ConfigProvider/hooks';
import Layout from '../../Layout/Layout';
import CreateUserButton from './components/CreateUserButton/CreateUserButton';
import { useUsersTableColumns } from './hooks';
import {
  FormControl,
  TextField,
  Grid,
  InputAdornment,
  makeStyles,
  Theme,
  IconButton,
  MenuItem,
  Checkbox,
} from '@material-ui/core';
import lunr from 'lunr';
import { User, UsersSortBy } from './types';
import {
  usersSortingReducer,
  getUserName,
  sortUsers,
  mobileSortOptions,
} from './config';
import SearchIcon from '@material-ui/icons/Search';
import Page from '../../Layout/components/Page/Page';
import Visible from '../../components/Visible/Visible';
import DownloadIcon from '@material-ui/icons/SaveAlt';
import { Base64 } from 'js-base64';
import ConfirmationDialog from '../../eliot-components/src/ConfirmationDialog/ConfirmationDialog';
import {
  useSortState,
  SortState,
  SortOptionSelectItem,
} from '../../utils/sort';
import { MediaDown } from '../../components/MediaQueries/MediaQueries';
import MedTooltip from '../../components/MedTooltip/MedTooltip';
import Toolbar from '@material-ui/core/Toolbar';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import Menu from '@material-ui/core/Menu';
import Typography from '@material-ui/core/Typography';
import UsersTable from './components/UsersTable/UsersTable';
import { useInfinitePaginationWithRef } from '../../eliot-components/src/InfiniteTable/hooks';
import {
  useGetUsersQuery,
  useResendActivationEmailMutation,
} from '../../store/api-slice';
import { parseErrorMessage } from '../../eliot-components/src/utils/helpers';
import { useSnackbar } from 'notistack';
import { FileCopy } from '@material-ui/icons';

lunr.tokenizer.separator = /\s+/;

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    alignItems: 'center',
    '& .menu': {
      marginTop: theme.spacing(2),
      textAlign: 'right',
    },
  },
  sortBy: {
    marginTop: theme.spacing(2),
  },
  filter: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      marginBottom: theme.spacing(2),
      backgroundColor: theme.palette.action.hover,
    },
  },
  menu: {
    '& ul': {
      minWidth: '185px',
    },
  },
  model: {
    display: 'inline-flex',
    textAlign: 'left',
    alignItems: 'center',
    '& .label': {
      flexGrow: 1,
      paddingRight: theme.spacing(1),
    },
  },
  marginBottom: {
    marginBottom: theme.spacing(2),
  },
  toolbar: {
    flexShrink: 0,
    padding: 0,
  },
  exportIcon: {
    marginRight: theme.spacing(1),
  },
  sortableCell: {
    display: 'flex',
    flexDirection: 'row',
  },
  table: {
    '& table': {
      '& .details': {
        textAlign: 'right',
      },
      '& .status': {
        whiteSpace: 'nowrap',
        padding: '14px 10px 14px 0px',
        // [theme.breakpoints.down('xs')]: {
        //   padding: theme.spacing(1, 1, 1, 1) + ' !important',
        //   marginBottom: 0 + ' !important',
        // },
      },
      [theme.breakpoints.up('md')]: {
        tableLayout: 'fixed',
        '& td': {
          wordWrap: 'break-word',
          paddingLeft: '10px',
          paddingRight: '10px',
        },
      },

      [theme.breakpoints.down('sm')]: {
        display: 'block',
        emptyCells: 'hide',
        '& colgroup': {
          display: 'none',
        },
        '& thead': {
          display: 'none',
        },
        '& tbody': {
          display: 'block',
          '& tr': {
            display: 'block',
            marginBottom: theme.spacing(1),
            boxShadow: theme.shadows[1],
            borderRadius: theme.shape.borderRadius,
            backgroundColor: theme.palette.background.paper,
            '&:nth-of-type(even)': {
              backgroundColor: theme.palette.background.paper,
            },
            '& td': {
              display: 'block',
              border: 'none',
              padding: theme.spacing(1, 2, 0, 2),
              textAlign: 'left',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              marginBottom: theme.spacing(2),
              '&:before': {
                ...theme.typography.caption,
                display: 'block',
                color: theme.palette.grey[500],
              },
              '&:after': {
                content: '"\\00a0"',
              },
              '&:nth-child(1)': {
                paddingTop: theme.spacing(2),
                ...theme.typography.h6,
              },
              '&:nth-child(2)': {
                padding: theme.spacing(0, 2, 1, 2),
              },
              '&.orgs': {
                display: 'inline-block',
                padding: theme.spacing(1, 2, 1, 2),
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.action.hover,
                width: `calc(50% - ${theme.spacing(1)}px)`,
                textAlign: 'center',
                marginLeft: theme.spacing(1),
                borderTopLeftRadius: theme.shape.borderRadius,
                borderBottomLeftRadius: theme.shape.borderRadius,
                '&:before': {
                  color: 'inherit',
                  content: '"Orgs"',
                },
                '&.no-global-roles:before': {
                  content: '"\\a0"',
                },
                '&.no-global-roles': {
                  display: 'none!important',
                },
              },
              '&.roles': {
                display: 'inline-block',
                padding: theme.spacing(1, 2, 1, 2),
                backgroundColor: theme.palette.action.hover,
                color: theme.palette.text.primary,
                width: `calc(50% - ${theme.spacing(1)}px)`,
                textAlign: 'center',
                borderTopRightRadius: theme.shape.borderRadius,
                borderBottomRightRadius: theme.shape.borderRadius,
                '&:before': {
                  color: 'inherit',
                  content: '"Global roles"',
                },
                '&.no-global-roles:before': {
                  content: '"\\a0"',
                },
                '&.no-global-roles': {
                  display: 'none!important',
                },
              },
              '&.status': {
                display: 'inline-block',
                padding: theme.spacing(1, 2, 1, 2),
                width: `calc(60% - ${theme.spacing(1)}px)`,
              },
              '&.details': {
                display: 'inline-block',
                padding: theme.spacing(1, 2, 1, 2),
                width: `calc(40% - ${theme.spacing(1)}px)`,
              },
            },
          },
        },
      },
    },
  },
  totals: {
    margin: '1px -16px',
    display: 'flex',
    flexBasis: 'calc(100% + 32px)',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: theme.palette.action.hover,
    position: 'relative',
  },
  mobileCount: {
    marginTop: theme.spacing(2),
    fontSize: '1rem',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      right: 0,
      top: 0,
      marginTop: theme.spacing(0.25),
    },
  },
}));

const createIndexForUsers = (users: User[]) => {
  return lunr(function (this: lunr.Builder) {
    this.ref('id');
    this.field('name');
    this.field('email');

    this.pipeline.remove(lunr.stemmer);
    this.pipeline.remove(lunr.stopWordFilter);

    users.forEach((item) =>
      this.add({
        id: item.id,
        email: item.email,
        name: getUserName(item),
        fullUsr: item,
      })
    );
  });
};

const Users: FunctionComponent = memo(() => {
  const classes = useStyles();
  const { apiBaseURL } = useConfig();
  const history = useHistory();
  const authUser = useAuthUser();
  const query = new URLSearchParams(useLocation().search);
  const { enqueueSnackbar } = useSnackbar();
  const [globalRolesFilter, setGlobalRoleFilter] = useState<string[]>(
    query.getAll('globalRoles')
  );
  const [rolesFilter, setRolesFilter] = useState<string[]>(
    query.getAll('roles')
  );
  const [verifiedFilter, setVerifiedFilter] = useState<string>(
    query.get('verified') || ''
  );

  const [resendActivationEmail] = useResendActivationEmailMutation();

  const {
    data: allUsers = [],
    isFetching: isLoading,
    error,
  } = useGetUsersQuery({
    apiBaseURL,
    roleFilter: rolesFilter,
    globalFilter: globalRolesFilter,
    verifiedFilter: verifiedFilter
      ? verifiedFilter
        ? verifiedFilter === 'true'
        : false
      : undefined,
  });
  if (error) {
    enqueueSnackbar(parseErrorMessage(error), { variant: 'error' });
  }
  const [selectedUserID, setSelectedUserID] = useState('');
  const [search, setSearch] = useState('');
  const [users, setUsers] = useState<User[]>([]);

  const handleSortStateChange = React.useCallback(
    (sortState) => {
      const sortedUsers = sortUsers(users, sortState);
      setUsers(sortedUsers);
    },
    [users, setUsers]
  );

  const { sortState, updateSortState, currentOptionId } = useSortState<
    SortState<User>,
    UsersSortBy
  >(usersSortingReducer, {}, handleSortStateChange, mobileSortOptions);

  useEffect(() => {
    const queryString = new URLSearchParams({});
    globalRolesFilter.forEach((f) => queryString.append('globalRoles', f));
    rolesFilter.forEach((f) => queryString.append('roles', f));
    if (verifiedFilter && verifiedFilter !== '') {
      queryString.append('verified', verifiedFilter);
    } else {
      queryString.delete('verified');
    }
    history.push({ search: queryString.toString() });
  }, [globalRolesFilter, rolesFilter, verifiedFilter, history]);

  useEffect(() => {
    if (allUsers && allUsers.length > 0) {
      const index = createIndexForUsers(allUsers);
      let newUsers = [...allUsers];

      if (index !== null && search !== '') {
        const matches = index.query((q) => {
          const parts = search.trim().toLowerCase().split(/\s/);

          parts.forEach((item) => {
            q.term(item.replace('-', '-'), {
              wildcard:
                lunr.Query.wildcard.LEADING | lunr.Query.wildcard.TRAILING,
              presence: lunr.Query.presence.REQUIRED,
            });
          });
        });

        newUsers = matches.map<User>((match: lunr.Index.Result) => {
          return allUsers.find((u) => match && u.id === match.ref) as User;
        });
      }

      setUsers(sortUsers(newUsers, sortState));
    } else {
      setUsers([]);
    }
  }, [allUsers, search, sortState, globalRolesFilter]);

  const showGlobalRoles = hasOneOfGlobalRoles(authUser, [
    Role.OrganizationManager,
    Role.UserManager,
    Role.UserViewer,
  ]);

  useEffect(() => {
    if (query.get('user-removed')) {
      enqueueSnackbar('User removed', { variant: 'success' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState(false);

  const [, setAnchor] = useState<HTMLElement | null>(null);
  const [csv, setCsv] = useState('');
  const [anchorEls, setAnchorEls] = useState<{
    [key: string]: HTMLElement;
  }>({});

  const handleMenuClick = (event: any) => {
    let data = 'data:text/csv;base64,';
    let items =
      'Name,Email,Verification,Deactivated,Total orgs, Total global roles\n';

    users.forEach((item) => {
      items += `${
        item.givenName || item.familyName
          ? item.givenName + ' ' + item.familyName
          : item.name
      },${item.email},${item.emailVerified ? 'Verified' : 'Not verified'},${
        item.deactivated ? 'yes' : 'no'
      },${item.orgs.length},${item.globalRoles?.length ?? 0}\n`;
    });

    setCsv(data + Base64.encode(items));

    setAnchor(event.currentTarget);
  };

  const tableColumns = useUsersTableColumns(
    sortState,
    updateSortState,
    setSelectedUserID,
    setIsConfirmationDialogOpen,
    showGlobalRoles
  );
  const [data, nextPage, hasMore, container] = useInfinitePaginationWithRef<
    User,
    HTMLDivElement
  >(users);

  return (
    <Page name="users">
      <Layout isLoading={isLoading}>
        <Grid
          container
          justifyContent="flex-start"
          direction="row"
          style={{ marginTop: '-8px' }}
        >
          <MediaDown query="sm">
            <Grid container justifyContent="flex-end" wrap="wrap">
              <Grid item className={classes.totals}>
                <Typography
                  variant="subtitle1"
                  color="textSecondary"
                  className={classes.mobileCount}
                >
                  Total users count: {users ? users.length : 0}
                </Typography>
              </Grid>
            </Grid>
          </MediaDown>
        </Grid>
        <Visible when={showGlobalRoles}>
          <Toolbar className={classes.toolbar}>
            <Grid item sm={12} md={10} className={classes.marginBottom}>
              <Grid item>
                <Fragment>
                  <Button
                    size="small"
                    className={classes.filter}
                    aria-owns={anchorEls['alerts'] ? 'alerts' : undefined}
                    aria-haspopup="true"
                    onClick={(e) => {
                      setAnchorEls({
                        ...anchorEls,
                        alerts: e.currentTarget,
                      });
                    }}
                  >
                    <span className={classes.model}>
                      <span className="label">Global Role</span>
                      <KeyboardArrowDownIcon />
                    </span>
                  </Button>

                  <Menu
                    id="alerts"
                    classes={{ paper: classes.menu }}
                    anchorEl={anchorEls['alerts']}
                    open={Boolean(anchorEls['alerts'])}
                    onClose={() => {
                      const { alerts: _, ...rest } = anchorEls;
                      setAnchorEls(rest);
                    }}
                  >
                    {Object.values(ALL_ROLES)
                      .filter((r) => r.id !== Role.Caregiver)
                      .map((c) => (
                        <MenuItem
                          key={c.label}
                          onClick={() => {
                            if (globalRolesFilter.indexOf(c.id) === -1) {
                              setGlobalRoleFilter(
                                globalRolesFilter.concat([c.id])
                              );
                            } else {
                              setGlobalRoleFilter(
                                globalRolesFilter.filter((r) => r !== c.id)
                              );
                            }

                            const { alerts: _, ...rest } = anchorEls;
                            setAnchorEls(rest);
                          }}
                        >
                          <Checkbox
                            edge="start"
                            checked={globalRolesFilter.indexOf(c.id) !== -1}
                          />
                          <Typography variant="body2">{c.label}</Typography>
                        </MenuItem>
                      ))}
                    <MenuItem
                      key="none"
                      onClick={() => {
                        if (globalRolesFilter.indexOf('none') === -1) {
                          setGlobalRoleFilter(
                            globalRolesFilter.concat(['none'])
                          );
                        } else {
                          setGlobalRoleFilter(
                            globalRolesFilter.filter((r) => r !== 'none')
                          );
                        }

                        const { alerts: _, ...rest } = anchorEls;
                        setAnchorEls(rest);
                      }}
                    >
                      <Checkbox
                        edge="start"
                        checked={globalRolesFilter.indexOf('none') !== -1}
                      />
                      <Typography variant="body2">None</Typography>
                    </MenuItem>
                  </Menu>
                </Fragment>

                <Fragment>
                  <Button
                    size="small"
                    className={classes.filter}
                    aria-owns={
                      anchorEls['alertsrole'] ? 'alertsrole' : undefined
                    }
                    aria-haspopup="true"
                    onClick={(e) => {
                      setAnchorEls({
                        ...anchorEls,
                        alertsrole: e.currentTarget,
                      });
                    }}
                  >
                    <span className={classes.model}>
                      <span className="label">Role</span>
                      <KeyboardArrowDownIcon />
                    </span>
                  </Button>

                  <Menu
                    id="alertsrole"
                    classes={{ paper: classes.menu }}
                    anchorEl={anchorEls['alertsrole']}
                    open={Boolean(anchorEls['alertsrole'])}
                    onClose={() => {
                      const { alertsrole: _, ...rest } = anchorEls;
                      setAnchorEls(rest);
                    }}
                  >
                    {Object.values(ALL_ROLES)
                      .filter((r) => r.id !== Role.Caregiver)
                      .map((c) => (
                        <MenuItem
                          key={c.label}
                          onClick={() => {
                            if (rolesFilter.indexOf(c.id) === -1) {
                              setRolesFilter(rolesFilter.concat([c.id]));
                            } else {
                              setRolesFilter(
                                rolesFilter.filter((r) => r !== c.id)
                              );
                            }

                            const { alertsrole: _, ...rest } = anchorEls;
                            setAnchorEls(rest);
                          }}
                        >
                          <Checkbox
                            edge="start"
                            checked={rolesFilter.indexOf(c.id) !== -1}
                          />
                          <Typography variant="body2">{c.label}</Typography>
                        </MenuItem>
                      ))}
                    <MenuItem
                      key={'none'}
                      onClick={() => {
                        if (rolesFilter.indexOf('none') === -1) {
                          setRolesFilter(rolesFilter.concat(['none']));
                        } else {
                          setRolesFilter(
                            rolesFilter.filter((r) => r !== 'none')
                          );
                        }

                        const { alertsrole: _, ...rest } = anchorEls;
                        setAnchorEls(rest);
                      }}
                    >
                      <Checkbox
                        edge="start"
                        checked={rolesFilter.indexOf('none') !== -1}
                      />
                      <Typography variant="body2">None</Typography>
                    </MenuItem>
                  </Menu>
                </Fragment>
                <Fragment>
                  <Button
                    size="small"
                    className={classes.filter}
                    aria-owns={
                      anchorEls['alertsverified'] ? 'alertsverified' : undefined
                    }
                    aria-haspopup="true"
                    onClick={(e) => {
                      setAnchorEls({
                        ...anchorEls,
                        alertsverified: e.currentTarget,
                      });
                    }}
                  >
                    <span className={classes.model}>
                      <span className="label">Verification</span>
                      <KeyboardArrowDownIcon />
                    </span>
                  </Button>
                  <Menu
                    id="alertsverified"
                    classes={{ paper: classes.menu }}
                    anchorEl={anchorEls['alertsverified']}
                    open={Boolean(anchorEls['alertsverified'])}
                    onClose={() => {
                      const { alertsverified: _, ...rest } = anchorEls;
                      setAnchorEls(rest);
                    }}
                  >
                    <MenuItem
                      key="verified"
                      onClick={() => {
                        if (verifiedFilter === 'true') {
                          setVerifiedFilter('');
                        } else {
                          setVerifiedFilter('true');
                        }
                        const { alertsverified: _, ...rest } = anchorEls;
                        setAnchorEls(rest);
                      }}
                    >
                      <Checkbox
                        edge="start"
                        checked={verifiedFilter === 'true'}
                      />
                      <Typography variant="body2">Verified</Typography>
                    </MenuItem>
                    <MenuItem
                      key="unverified"
                      onClick={() => {
                        if (verifiedFilter === 'false') {
                          setVerifiedFilter('');
                        } else {
                          setVerifiedFilter('false');
                        }

                        const { alertsverified: _, ...rest } = anchorEls;
                        setAnchorEls(rest);
                      }}
                    >
                      <Checkbox
                        edge="start"
                        checked={verifiedFilter === 'false'}
                      />
                      <Typography variant="body2">Not verified</Typography>
                    </MenuItem>
                  </Menu>
                </Fragment>
              </Grid>
            </Grid>
          </Toolbar>
        </Visible>
        <Grid container className={classes.header}>
          <Grid item xs={8} sm={4}>
            <FormControl margin="normal" fullWidth>
              <TextField
                data-testid="search-text-field"
                variant="outlined"
                value={search}
                onChange={(e: any) => setSearch(e.target.value)}
                label="Search"
                margin="dense"
                type="search"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={4} sm={8} className="menu">
            <MedTooltip title="Copy to clipboard">
              <IconButton
                onClick={() => {
                  const clip = users
                    .map(
                      (item) =>
                        `Name: ${
                          item.givenName || item.familyName
                            ? item.givenName + ' ' + item.familyName
                            : item.name
                        }\nEmail: ${item.email}\nVerified: ${
                          item.emailVerified ? 'Verified' : 'Not verified'
                        }\nDeactivated: ${
                          item.deactivated ? 'Yes' : 'No'
                        }\nTotal Orgs: ${
                          item.orgs.length
                        }\nTotal Global Roles: ${
                          item.globalRoles?.length ?? 0
                        }\n`
                    )
                    .join('\n');
                  navigator.clipboard.writeText(clip);
                  enqueueSnackbar('Users copied to clipboard', {
                    variant: 'info',
                  });
                }}
                color="inherit"
              >
                <FileCopy />
              </IconButton>
            </MedTooltip>
            <MedTooltip title="Export to .CSV">
              <IconButton
                onClick={handleMenuClick}
                color="inherit"
                component="a"
                download="User(s).csv"
                href={csv}
              >
                <DownloadIcon />
              </IconButton>
            </MedTooltip>
          </Grid>
        </Grid>

        <MediaDown query="xs">
          <Grid item>
            <MedSelect
              name="sort-option"
              placeholder="Sort by"
              variant="outlined"
              fullWidth
              options={mobileSortOptions}
              valueGetter={(o: SortOptionSelectItem<UsersSortBy>) => o.id}
              optionTextGetter={(o: SortOptionSelectItem<UsersSortBy>) =>
                o.label
              }
              value={currentOptionId}
              onChange={(e) => {
                const option = mobileSortOptions.find(
                  (o) => o.id === e.target.value
                );
                option && updateSortState(option.value);
              }}
            />
          </Grid>
        </MediaDown>
        <Grid item>
          <UsersTable
            dataToCount={users}
            dataName="user"
            sortState={sortState}
            data={data}
            nextPage={nextPage}
            hasMore={hasMore}
            tableColumns={tableColumns}
            getKeyValue={(u) => u.id}
            container={container}
            tableClass={classes.table}
          />
        </Grid>
        <Visible
          when={
            hasOneOfGlobalRoles(authUser, [Role.OrganizationManager]) ||
            hasRoleInAnyUsersOrg(
              authUser,
              [Role.OrganizationManager],
              authUser.orgs ? authUser.orgs : []
            )
          }
        >
          <CreateUserButton
            onCreated={() => {
              enqueueSnackbar('User created', { variant: 'success' });
              setSearch('');
            }}
          />
        </Visible>
        <ConfirmationDialog
          questionText="Are you sure you want to resend a verification email?"
          confirmationText="Resend"
          cancelText="Cancel"
          title="Resend Verification Email"
          onConfirm={() => {
            resendActivationEmail({ userID: selectedUserID, apiBaseURL }).then(
              (resp: any) => {
                if (!resp.error) {
                  enqueueSnackbar('Email sent', { variant: 'success' });
                }
              }
            );
          }}
          onClose={() => {
            setIsConfirmationDialogOpen(false);
          }}
          isOpen={isConfirmationDialogOpen}
        />
      </Layout>
    </Page>
  );
});

export default Users;
