import React, { useEffect } from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import { useAppDispatch, useAppState } from '../../../common';

const useStyles = makeStyles((theme: Theme) => ({
  version: {
    fontSize: 12,
    margin: theme.spacing(1, 2, 0, 2),
    display: 'list-item',
  },
}));

const Version = () => {
  const classes = useStyles();

  const state = useAppState();
  const dispatch = useAppDispatch();

  const { version } = state;

  useEffect(() => {
    (async function () {
      if (version === null) {
        try {
          const response = await fetch('/version.txt');

          if (response.ok) {
            response.text().then((version) => {
              dispatch('version-changed', { version });
            });
          }
        } catch (error) {
          // ignore
        }
      }
    })();
  }, [dispatch, version]);

  if (version) {
    return <span className={classes.version}>Version: {version}</span>;
  }

  return null;
};

export default Version;
