import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Theme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import React, { FunctionComponent, memo } from 'react';

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    margin: theme.spacing(1),
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

interface Props {
  readonly isLoading?: boolean;
  readonly disabled?: boolean;
  readonly [k: string]: any;
}

const ButtonProgress: FunctionComponent<Props> = memo(
  ({
    isLoading = false,
    disabled = false,
    children,
    classes: overideClasses,
    ...rest
  }) => {
    const classes = useStyles({ classes: overideClasses });
    return (
      <div className={classes.wrapper}>
        <Button disabled={disabled || isLoading} {...rest}>
          {children}
        </Button>
        {isLoading && (
          <CircularProgress size={24} className={classes.buttonProgress} />
        )}
      </div>
    );
  }
);

export default ButtonProgress;
