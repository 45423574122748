import { useAppContext } from './AppContext';
import React, { useEffect } from 'react';

const useAppState = (): any => {
  const { state } = useAppContext();
  return state;
};

const useAppDispatch = (): ((name: string, args?: any) => void) => {
  const { dispatch } = useAppContext();
  return dispatch;
};

export { useAppState, useAppDispatch };

export const usePrevious = (value: any) => {
  const ref = React.useRef();

  useEffect(() => {
    ref.current = value;
  });

  return ref.current;
};
