import { memo, FunctionComponent, useState } from 'react';
import { useConfig } from '../../../../eliot-components/src/ConfigProvider/hooks';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
} from '@material-ui/core';
import { Org } from '../../../Orgs/types';
import ButtonProgress from '../../../../eliot-components/src/ButtonProgress/ButtonProgress';
import { useUpdateOrgMutation } from '../../../../store/api-slice';
import { useSnackbar } from 'notistack';
import { parseErrorMessage } from '../../../../eliot-components/src/utils/helpers';

interface Props {
  isOpen: boolean;
  onClose(): void;
  onUpdated(): void;
  org: Org;
}

const RestrictedDialog: FunctionComponent<Props> = memo(
  ({ isOpen, org, onClose }) => {
    const { apiBaseURL } = useConfig();
    const [updateOrg, { isLoading }] = useUpdateOrgMutation();
    const [restricted, setRestricted] = useState(org.restricted || false);
    const [maxUsers, setMaxUsers] = useState(
      org.restrictedOpts ? org.restrictedOpts.maxUsers : 0
    );
    const [maxWebhooks, setMaxWebhooks] = useState(
      org.restrictedOpts ? org.restrictedOpts.maxWebhooks : 1
    );
    const { enqueueSnackbar } = useSnackbar();
    const [disableBtn, setDisableBtn] = useState(true);

    const handleSubmit = async () => {
      const orgObj = {
        id: org.id,
        name: org.name,
        restricted: restricted,
        restrictedOpts: { maxUsers: maxUsers, maxWebhooks: maxWebhooks },
      };
      updateOrg({ org: orgObj, apiBaseURL }).then((resp: any) => {
        if (!resp.error) {
          enqueueSnackbar('Organization updated');
          onClose();
          return;
        }
        enqueueSnackbar(parseErrorMessage(resp.error), { variant: 'error' });
      });
    };
    return (
      <>
        <Dialog aria-labelledby="dialog-title" open={isOpen} onClose={onClose}>
          <DialogTitle id="dialog-title">Restrictions</DialogTitle>

          <DialogContent>
            <FormControlLabel
              control={
                <Switch
                  checked={restricted}
                  onChange={(e) => {
                    setDisableBtn(false);
                    setRestricted(e.target.checked);
                  }}
                  name="checkedB"
                  color="primary"
                />
              }
              label="Restrictions"
            />
            {restricted && (
              <>
                <br />
                <br />
                <Grid container spacing={2}>
                  <Grid item sm={6} xs={12}>
                    <TextField
                      margin="dense"
                      variant="outlined"
                      id="max_users"
                      label="Max Users"
                      value={maxUsers}
                      onChange={(e) => {
                        let val = parseInt(e.target.value);
                        if (!val) {
                          val = 0;
                        }
                        setDisableBtn(false);
                        setMaxUsers(val);
                      }}
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <TextField
                      margin="dense"
                      variant="outlined"
                      id="max_webhooks"
                      label="Max Webhooks"
                      value={maxWebhooks}
                      onChange={(e) => {
                        let val = parseInt(e.target.value);
                        if (!val) {
                          val = 0;
                        }
                        setDisableBtn(false);
                        setMaxWebhooks(val);
                      }}
                    />
                  </Grid>
                </Grid>
              </>
            )}
          </DialogContent>

          <DialogActions>
            <Button onClick={onClose}>Cancel</Button>
            <ButtonProgress
              type="button"
              onClick={handleSubmit}
              variant="contained"
              color="primary"
              isLoading={isLoading}
              data-testid="submit-update"
              disabled={isLoading || disableBtn}
            >
              Submit
            </ButtonProgress>
          </DialogActions>
        </Dialog>
      </>
    );
  }
);

export default RestrictedDialog;
