import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {Theme} from '@material-ui/core/styles';
import {withStyles} from '@material-ui/styles';
import React, {FunctionComponent, ReactNode, ReactElement, useState, useMemo} from 'react';
import ButtonProgress from "../ButtonProgress/ButtonProgress";
import {Warning} from "@material-ui/icons";
import {Alert, Color} from "@material-ui/lab";
import {
  FormControl,
  FormHelperText,
  Input
} from '@material-ui/core';

interface Props {
  questionText?: string;
  confirmationText?: string;
  cancelText?: string;
  title?: string;
  warningText?: string | ReactElement;
  warningColor?: Color
  isOpen?: boolean;
  isLoading?: boolean;
  disabled?: boolean;
  noCloseOnConfirm?: boolean;

  onClose?(isConfirmed: boolean): void;

  onConfirm?(): void;

  children?: ReactNode;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
  inputCheckName?: string;
}

const StyledActions = withStyles((theme: Theme) => ({
  root: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
}))(DialogActions);

const ConfirmationAction: FunctionComponent<Props> = ({
                                                        onConfirm,
                                                        isOpen = true,
                                                        onClose,
                                                        title,
                                                        questionText,
                                                        warningText,
                                                        confirmationText,
                                                        cancelText,
                                                        children,
                                                        maxWidth = "sm",
                                                        warningColor = 'warning',
                                                        isLoading = false,
                                                        disabled = false,
                                                        inputCheckName,
                                                        noCloseOnConfirm = false
                                                      }) => {

  const [dbcheck, setDbcheck] = useState(false);
  const handleClose = (isConfirmed: boolean = false) => {
    if (onClose) {
      onClose(isConfirmed);
    }
  };

  const handleConfirm = () => {
    if (onConfirm) {
      onConfirm();
      if(noCloseOnConfirm) {
        return;
      }
    }
    handleClose(true);
  };

  let view = null;

  if (children) {
    view = children;
  } else if (questionText) {
    view = <DialogContentText>{questionText}</DialogContentText>;
  }

  useMemo(() => {
    if (!!inputCheckName) {
      setDbcheck(true);
    }
  }, [inputCheckName])

  return (
    <>
      <Dialog
        aria-labelledby="dialog-title"
        open={isOpen}
        maxWidth={maxWidth}
        onClose={() => {
          handleClose();
        }}
      >
        <DialogTitle id="dialog-title">{title}</DialogTitle>
        <DialogContent>
          {warningText ? (
            <Alert icon={<Warning/>} color={warningColor}>{warningText}</Alert>
          ) : null}
          {view}
          {!!inputCheckName && (
            <FormControl fullWidth={true}>
              <Input id="my-input" aria-describedby="my-helper-text" placeholder={inputCheckName} onChange={(e) => {
                if (e.currentTarget.value === inputCheckName) {
                  setDbcheck(false);
                } else {
                  setDbcheck(true);
                }
              }}/>
              <FormHelperText id="my-helper-text">Please type <strong>{inputCheckName}</strong> to confirm
                delete.</FormHelperText>
            </FormControl>
          )}
        </DialogContent>
        <StyledActions>
          <Button
            disabled={isLoading}
            onClick={() => {
              handleClose();
            }}
          >
            {cancelText}
          </Button>
          <ButtonProgress
            variant="contained"
            color="primary"
            isLoading={isLoading}
            disabled={disabled || dbcheck}
            onClick={() => {
              handleConfirm();
            }}
          >
            {confirmationText}
          </ButtonProgress>
        </StyledActions>
      </Dialog>
    </>
  );
};

export default ConfirmationAction;
